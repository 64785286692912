import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CustomButton, PrimaryCta } from '../common/core_component';
import Icon from '../assets/icons';
import svgs from '../assets/icons/svgs';

function ThankYouScreen() {

  const navigate = useNavigate(); 

  
  const returnlocation = useLocation();
  const {returnUrl = "/" } = returnlocation.state || {};

  
  const navigateToHomeScreen = ()=>{
    navigate(-3);
  };

  return(
    <div className=" relative container flex align-center justify-center  h-90">
    <div>
      <div className="m-b-15 flex align-center justify-center">
       <Icon icon = {svgs.round_check_mark} width = {100}
       height = {100}/>
      </div>

      <div className='flex align-center justify-center flex-column '>
        <h2 className="m-t-8 flex-start">Thank You For </h2>
        <h2 className="m-t-4">Creating Your Profile </h2>
        <div className="centered-text-container m-t-15">
  <p className="text-color-p">
    Next Step - Connect your Instagram, That  
    
    helps  Brands to understand your potential
  </p>
</div>
        
      </div>
      <div className="flex justify-center align-center">
          <PrimaryCta
            className={"custom-button-bottom btn-bottom"}
            onClick={navigateToHomeScreen}
            text={"Start Reelaxing"}
          />
        </div>
      </div>
    </div>
  )
}

export default ThankYouScreen
