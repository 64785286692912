import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sdk from "../../common/sdk/influencerSdk";
import * as Action from "../../redux/actions";
import { setLocalUserData } from "../../common/storage/storage";
import { useSnackbar } from "notistack";
import {
  CustomInputBox,
  CustomText,
  FallBack,
  PrimaryCta,
} from "../../common/core_component";
import { Loader } from "../Loader";
import { useLocation, useNavigate } from "react-router-dom";
import Svgs from "../../assets/icons/svgs";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import Utils from "../../common/utils";
import { Switch } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";

function ConnectMedia() {
  const [loading, setLoading] = useState(false);
  const [isAppLoaded, setAppLoaded] = useState(false);
  const [btnLoader, setBtnLoader] = useState({});
  const [modalVisible, setModalVisible] = useState(true);
  const [selectedPlatform, setSelectedPlatform] = useState({});
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [socialUsername, setSocialUsername] = useState();
  const [platformDataPublic, setPlatformDataPublic] = useState();
  const [platformDataInfluencer, setPlatformDataInfluencer] = useState();
  const [platformData, setPlatformData] = useState();

  const [selectedPlatformId, setSelectedPlatformId] = useState();

  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const [selectedPlatformRemoveId, setSelectedPlatformRemoveId] =
    useState(null);

  const [VerifyModalOpen, setVerifyModalOpen] = useState(false);

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });
  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  console.log("userData", userData);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();



  function validateSocialMedia() {
    if (!socialUsername) {
      // showError('Please enter user name');
      enqueueSnackbar("Please enter user name");
      return false;
    }
    return true;
  }

  function updateProgress(status){
    setLoading(status);
  }

  const navigate = useNavigate();
  
  useEffect(() => {
    getPlatformListPublic();
  }, []);

  useEffect(() => {
    mergePlatformDataPublicAndInfluencer();
  }, [platformDataPublic, platformDataInfluencer]);

  // -------- PlatFormList -----------

  function getPlatformListPublic() {
    var params = {};
    Sdk.getPlatformListPublic(
      getPlatformListPublicSuccess,
      getPlatformListPublicFailed,
      updateProgress,
      params
    );
  }

  function getPlatformListPublicSuccess(response) {
    getPlatformListInfluencer();
    setPlatformDataPublic(response.platform_list);
  }

  console.log("setPlat ", platformDataPublic);

  function getPlatformListPublicFailed(errorList) {
    enqueueSnackbar(errorList.map((err) => err.m).join(", "), {
      variant: "error",
    });

    console.error("err", errorList);
  }

  // -------- Influencer Platform ---------

  function getPlatformListInfluencer() {
    var params = {};
    Sdk.getPlatformListInfluencer(
      getPlatformListInfluencerSuccess,
      getPlatformListInfluencerFailed,
      updateProgress,
      params
    );
  }

  function getPlatformListInfluencerSuccess(response) {
    // setAppLoaded(true);
    setPlatformDataInfluencer(response.platform_list);
    refreshUserData();
  }

  console.log("plat influencer", platformDataInfluencer);

  function getPlatformListInfluencerFailed(errorList) {
    // showError(errorList.map(item => item.m).join(', '));
    enqueueSnackbar(errorList.map((err) => err.m).join(", "), {
      variant: "error",
    });
    console.error("err ");
  }

  //  ---------- Update data -----------
  function refreshUserData() {
    const params = {};

    Sdk.getInfluencerDataSelf(
      refreshUserDataSuccess,
      refreshUserDataFailed,
      () => {},
      params
      // reduxData.UserData.authToken,
    );
  }

  function refreshUserDataSuccess(userData, authToken) {
    setLocalUserData(userData);

    if (userData) {
      const { influencer_deep_data } = userData;
      dispatch({
        type: Action.UPDATE_USER_DATA,
        payload: influencer_deep_data,
      });
    }
  }

  function refreshUserDataFailed(error_list) {
    console.error("user err", error_list);
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
    // showError(error_list.map(err => err['m']).join(', '));
  }

  function postAddPlatform(id, item) {
    if (!validateSocialMedia(socialUsername)) {
      return false;
    }
    var params = {
      platform_id: id,
      identifier_type: item.identifier_type,
      identifier_id: socialUsername,
    };
    Sdk.postAddPlatform(
      postAddPlatformSuccess,
      postAddPlatformfailed,
      (status) => setBtnLoader({ [id]: status }),
      params
    );
    setSocialUsername("");
  }
  function postAddPlatformSuccess(response) {
    if (response.success) {
      getPlatformListInfluencer();
    }
  }
  function postAddPlatformfailed(errorList) {
    enqueueSnackbar(errorList.map((err) => err.m).join(", "), {
      variant: "error",
    });
    // showError(errorList.map(item => item.m).join(', '));
    console.error("add ", errorList);
  }

  // start Remove social platform
  function postRemovePlatform(id) {
    var params = {
      platform_id: id,
    };
    Sdk.postRemovePlatform(
      postRemovePlatformSuccess,
      postRemovePlatformfailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }
  function postRemovePlatformSuccess(response) {
    if (response.success) {
      getPlatformListInfluencer();
      setOpenRemoveModal(false);
      enqueueSnackbar("Account Removed Successfully", {variant : 'success'})
    }
  }
  function postRemovePlatformfailed(errorList) {
    console.error("remove", errorList);
    enqueueSnackbar(errorList.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  // Verify social channel
  function postVerifyPlatform(id) {
    var params = {
      platform_id: id,
    };
    Sdk.postVerifyPlatform(
      postVerifyPlatformSuccess,
      postVerifyPlatformfailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  const fromState = location.state?.from;
  console.log("from state", fromState);
  
  
  function postVerifyPlatformSuccess(response) {
    if (response.success) {
      getPlatformListInfluencer();
      setSelectedPlatformId("");
      enqueueSnackbar("Account Added Successfully", {variant : 'success'})
      if (fromState){
        navigate(-1);
      }
    }
  }

  function postVerifyPlatformfailed(errorList) {
    enqueueSnackbar(errorList.map((err) => err.m).join(", "), {
      variant: "error",
    });
    console.error("post verify", errorList);
  }
  //  ------------ end of Api Calls ------------

  //  ------ Bottom sheets ------

  const openModal = (id) => {
    setSelectedPlatformRemoveId(id);
    setOpenRemoveModal(true);
  };

  const closeModal = () => {
    setOpenRemoveModal(false);
  };

  function renderRemovePlatformSheet(id) {
    return (
      <>
        <BottomSheet
          open={openRemoveModal}
          onDismiss={closeModal}
          snapPoints={({ maxHeight }) => [0.35 * maxHeight, maxHeight]}
          style={{ overflowY: "auto", zIndex: "1000", position: "absolute" }}
        >
          {" "}
          <div className="m-t-20"
      style={{
        width: "90%", 
        maxWidth: "786px", 
        margin: "0 auto", 
        padding: "20px",
      }}
    >
            <div className="text-align">
              <CustomText
                p
                text={"Are you sure want to remove this platform?"}
              />
            </div>
            <div className="flex gap-10 m-t-20">
              <PrimaryCta
                className={"custom-button"}
                onClick={() => postRemovePlatform(selectedPlatformRemoveId)}
                isLoading={effectiveLoad}
                text={"Yes"}
              />
              <PrimaryCta
                className={"custom-button-bg-white"}
                onClick={closeModal}
                text={"No"}
                textColor={"white"}
              />
            </div>
          </div>
        </BottomSheet>
      </>
    );
  }

  const closeVerifyModal = () => {
    setVerifyModalOpen(false);
  };

  function renderVerifybottomSheet() {
    var item = getPlatformDataByPlatformId(selectedPlatformId);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(item.verification_code);
      enqueueSnackbar("Verification code copied!", { variant: "info" });
    };

    if (item) {
      return (
        <>
          <BottomSheet
            open={VerifyModalOpen}
            onDismiss={closeVerifyModal}
            snapPoints={({ maxHeight }) => [0.7 * maxHeight, maxHeight]}
            style={{ overflowY: "auto", zIndex: "1000", position: "absolute" }}
          >
            {" "}
            <div
              className="m-t-20 flex container flex-column align-center"
        
              style={{
                width: "100%", 
                maxWidth: "786px", 
                margin: "0 auto", 
                padding: "20px",
              }}
            >
            
              <CustomText
                h3
                text={`Verify It is Your ${item.name} `}
                className=""
              />
              <CustomText
                p
                text={` Go to ${item.name} app and place the following code Temporarily
               in anywhere in your bio and tap verify`}
                className=" m-t-12"
              />

              <div
                className=""
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginBottom: "30px",
                  marginTop: "30px",
                }}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 20px",
                    textShadow: "2px 2px 2px rgba(112, 112, 112, 0.7)",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  <CustomText
                    p
                    text={item.verification_code}
                    className="text-16"
                  />
                </div>

                <div
                  onClick={() => copyToClipboard(item.verification_code)}
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px 10px",
                    textShadow: "2px 2px 2px rgba(112, 112, 112, 0.7)",
                    fontWeight: "bold",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                >
                  <CustomText p text={"Copy"} />
                </div>
              </div>

              <div>
                <CustomText
                  p
                  text={`Come back here and Tap for verify, Don't forget to delete OTP
                  from bio after verification.`}
                  className="text-14 "
                />
              </div>
            </div>
            <div className="flex flex-column gap-10 m-t-20 align-center">
              <div style={{ fontSize: "14px" }}>
                <PrimaryCta
                  text="VERIFY"
                  className="verify-otp-btn"
                  onClick={() => postVerifyPlatform(item.platform_id)}
                  isLoading={effectiveLoad}
                />
              </div>
              <div
                onClick={closeVerifyModal}
                className="text-align"
                style={{ fontSize: "14px", color: "red", cursor: "pointer" }}
              >
                <CustomText p text={"CANCEL"} className="" />
              </div>
            </div>
          </BottomSheet>
        </>
      );
    }
  }

  function mergePlatformDataPublicAndInfluencer() {
    const newArray = [];
    const newSelectedPlatform = {};
    if (platformDataPublic && platformDataInfluencer) {
      platformDataPublic.forEach((item) => {
        let newItem = item;
        platformDataInfluencer.forEach((selectedItem) => {
          if (selectedItem["platform_id"] === item["platform_id"]) {
            newItem = { ...item, ...selectedItem };
            newSelectedPlatform[item["platform_id"]] = [];
          }
        });
        newArray.push(newItem);
      });
    }

    setPlatformData(newArray);
    setSelectedPlatform(newSelectedPlatform);
  }

  function getPlatformDataByPlatformId(selectedPlatformId) {
    var platformDataItem = [];
    if (platformData) {
      platformDataItem = platformData.filter(
        (item) => item.platform_id === selectedPlatformId
      );
    }
    return platformDataItem[0];
  }

  function updateInstagramData(toggle, key) {
    let newSelectedPlateform = { ...selectedPlatform };
    if (toggle == true) {
      newSelectedPlateform[key] = [];
    } else {
      delete newSelectedPlateform[key];
    }
    setSelectedPlatform(newSelectedPlateform);
  }

  function displaySocialChannelData(id, item) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            flex: 1,
          }}
        >
          {/* <img
            src={item.profile_pic}
            height={50}
            width={50}
            style={{
              borderRadius: "50%",
              border: "2px solid #eee",
              objectFit: "cover",
            }}
            alt={${item.full_name}'s profile pic}
          /> */}
          <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                margin: 0,
                color: "#333",
              }}
            >
              {item.full_name}
            </p>
            <p
              style={{
                fontSize: "12px",
                color: "#666",
                margin: "4px 0 0",
              }}
            >
              @{item.identifier_id}
            </p>
          </div>
        </div>

        <ul
          style={{
            listStyleType: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span style={{ fontSize: "12px", color: "#666" }}>FOLLOWERS</span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#333",
                whiteSpace: "nowrap",
              }}
            >
              {item.followers}
            </span>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span style={{ fontSize: "12px", color: "#666" }}>FOLLOWING</span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#333",
                whiteSpace: "nowrap",
              }}
            >
              {item.following}
            </span>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span style={{ fontSize: "12px", color: "#666" }}>TOTAL POSTS</span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#333",
                whiteSpace: "nowrap",
              }}
            >
              {item.content_count}
            </span>
          </li>
        </ul>
      </div>
    );
  }

  function renderSocialChannelConnect(id, item) {
    console.log("itm", item);
    console.log("Item is verified value:", item.is_verified);
    if (id === item.platform_id) {
      return (
        <div
          className="flex flex-column justify-center align-center "
          style={{
            padding: "10px",
          }}
        >
          <div
            style={{
              fontFamily: 'Inter-Thin' ,
              padding: "0 14px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "40px",
              borderColor: "#cbcbcb",
              borderWidth: "1px",
              borderStyle: "solid",
              borderRadius: "4px",
            }}
          >
            <div>
         <CustomText p text={item.base_url +"/"  } className="text-14"  />
            </div>

            <div className="">
              <input
                type="text"
                style={{
                  color: "#212529",
                  width: "100%",
                  border: "none",
                  outline: "none",
                  fontSize: "14px",
                  paddingLeft : "3px", 
                  
                }}
                value={item.identifier_id}
                placeholder="username"
                onChange={(e) => setSocialUsername(e.target.value)}
              />
            </div>
          </div>
          <div style={{ marginTop: "12px" }}>
    <CustomText
        p
        text={
            item.identifier_id
                ? "You are all set, click on verify for verification"
                : item.note
        }
        className="text-14 "
    />
</div>
<div style={{ width: "100%", marginTop: "12px" }}>
    {Utils.isNullOrUndefined(item.is_verified) ? (
      <div style={{border: "0.2vw solid #212529" , color : "#212529", borderRadius : "4px"}}>

        <PrimaryCta
        sixth
            text="Generate OTP"
            className="custom-button"
            onClick={() => postAddPlatform(item.platform_id, item)}
            isLoading={btnLoader[item.platform_id]}
        />
      </div>
    ) : (
        item.is_verified == 0 && ( 
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <PrimaryCta
                    text="Verify OTP"
                    className="bid-btn-counter"
                    onClick={() => {
                        setSelectedPlatformId(item.platform_id);
                        setVerifyModalOpen(true);
                    }}
                />
                <div style={{border: "0.2vw solid #212529" , color : "#212529", borderRadius : "4px"}}>
                <PrimaryCta
                    sixth
                    text="Remove"
                    className="custom-button"
                    onClick={() => postRemovePlatform(item.platform_id)}
                    isLoading={effectiveLoad}
                />
            </div>
            </div>
        )
    )}
</div>

        </div>
      );
    }
  }

  function renderInstagramSection(item) {
    return (
      <div
        style={{
          borderRadius: "4px",
          border: "1px solid #cbcbcb ",
          marginBottom: "5px",
        }}
      >
        {/* Header Section */}
        <div
  style={{
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 14px",
    alignItems: "center",
  }}
>
  <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
  <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
    <Icon
      icon={Utils.socialMediaIcons(item.platform_id)}
      height={18} 
      width={18}
      color="#919191"
      style={{ display: "flex", alignItems: "center" }}
    />
  </div>
    <div style={{ fontFamily: 'Inter-Thin' }}>
      <CustomText
        p
        text={Utils.socialMediaName(item.platform_id)}
        style={{ fontSize: "13px", color: "#212529", display: "inline" }}
      />
    </div>
  </div>

  <div style={{ display: "flex", alignItems: "center" }}>
    {!Array.isArray(selectedPlatform[item.platform_id]) && (
      <Switch
        checked={Array.isArray(selectedPlatform[item.platform_id])}
        onChange={(event) =>
          updateInstagramData(event.target.checked, item.platform_id)
        }
      />
    )}

    {item.is_verified === 1 && (
      <button
        onClick={() => openModal(item.platform_id)}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          marginLeft: "10px",
        }}
      >
        <Icon icon={svgs.delete_icon} height={20} width={20} />
      </button>
    )}
  </div>
</div>


        {/* Body Section */}
        {selectedPlatform[item.platform_id] && !item.is_verified && (
          <div
            style={{
              padding: "10px 14px",
              borderBottom: "1px solid #cbcbcb",
              borderTop: "1px solid #cbcbcb",
            }}
          >
            {renderSocialChannelConnect(item.platform_id, item)}
          
          </div>
        )}

        {selectedPlatform[item.platform_id] && item.is_verified  ? (
          <div
            style={{
              padding: "10px 14px",
              borderBottom: "1px solid #cbcbcb",
              borderTop: "1px solid #cbcbcb",
            }}
          >
            {displaySocialChannelData(item.platform_id, item)}
          </div>
        ) : ""}
      </div>
    );
  }

  function renderSocialChannel() {
    if (platformData) {
      return platformData.map((item) => {
        return <div className="m-t-10">{renderInstagramSection(item)}</div>;
      });
    } else {
      console.log("platform data not available", platformData);
    }
  }

  function renderInstruction() {
    return (
      <div className>
        <CustomText
          p
          text="Reelax is uses most secure OTP based verification to check that you are owner of the social account, If you are unable to  add your account, don't panic check Help and Support for more details."
          className="text-color-p text-align"
        />
        <button
          className="m-t-12 btn-help"
          onClick={() => {
            navigate("/help-support");
          }}
        >
          Help And Support
        </button>
      </div>
    );
  }

  return (
    <div className="">
      <div className="header-fixed flex justiify-center gap-10">
        <div
          className="cursor-pointer p-t-3"
          style={{paddingLeft : "10px"}}
          onClick={() => navigate("/profile")}
        >
          <Icon icon={Svgs.cross_icon} width={13} height={13} />
        </div>
        <CustomText p text={"Social Channels "} className="" />
      </div>
      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div className="container">
          <div className="p-t-60 flex flex-column" style={{ gap: "5px" , marginTop : "10px" }}>
            {renderSocialChannel()}
            {renderInstruction()}
          </div>

          {openRemoveModal && renderRemovePlatformSheet()}
          {renderVerifybottomSheet()}
        </div>
      )}
    </div>
  );
}

export default ConnectMedia;
