import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Utils from "../../common/utils";

const ImageVideoFullscreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [brandId, setBrandId] = useState(null);
  const [sampleContentId, setSampleContentId] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state to prevent redirect

  // Step 1: Parse parameters and validate
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const parsedBrandId = queryParams.get("brandId");
    const parsedSampleContentId = queryParams.get("sampleContentId");

    console.log("Parsed Parameters:", { parsedBrandId, parsedSampleContentId }); // Debugging

    if (parsedBrandId && parsedSampleContentId) {
      setBrandId(parsedBrandId);
      setSampleContentId(parsedSampleContentId);
      setLoading(false); // Valid parameters, no redirect
    } else {
      console.error("Invalid parameters, redirecting...");
      navigate("/"); // Redirect if parameters are invalid
    }
  }, [location.search, navigate]);

  // Step 2: Handle media URL generation and type detection
  useEffect(() => {
    if (brandId && sampleContentId) {
      const fileExtension = sampleContentId.split(".").pop();
      setIsVideo(fileExtension === "mp4");

      const url = Utils.generateCampaignSampleThumbUrl(brandId, sampleContentId);
      console.log("Generated Media URL:", url); // Debugging
      setMediaUrl(url);
    }
  }, [brandId, sampleContentId]);

  // Prevent rendering while loading
  if (loading) {
    return null; // Do not render anything until validation is complete
  }

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      {mediaUrl ? (
        <div>
          {isVideo ? (
            <video
              controls
              style={{ maxWidth: "100%", maxHeight: "80vh", borderRadius: "8px" }}
            >
              <source src={mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={mediaUrl}
              alt="Fullscreen"
              style={{ maxWidth: "100%", maxHeight: "80vh", borderRadius: "8px" }}
            />
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ImageVideoFullscreen;
