import React, { useState } from 'react'
import Sdk from '../../common/sdk/influencerSdk';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { CustomInputBox, CustomText, PrimaryCta } from '../../common/core_component';
import svgs from '../../assets/icons/svgs';
import Icon from '../../assets/icons';
import Select from 'react-dropdown-select';
import { Loader } from '../Loader';
import { BANKS } from "../../common/data/influencer";

function AddBankDetails() {
  const [ifscCode, setIfscCode] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [reAccountNumber, setReAccountNumber] = useState();
  const [accountHoldername, setAccountHolderName] = useState('');
  const [error, setError] = useState({});
  const [bankName, setBankName] = useState('');
  const [loading, setCtaLoading] = useState(false);
  const [locationDropDown, setLocationDropDown] = useState(false);
  const [effectiveLoad, setEffectiveLoad] = useState(false);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  function addBankDetails() {
    if (!validateForm()) {
      return false;
    }
    var params = {
      account_holder_name: accountHoldername,
      bank_name: bankName,
      account_number: accountNumber,
      ifsc_code: ifscCode,
    };

    Sdk.addBankDetails(
      addBankDetailsSuccess,
      addBankDetailsFailed,
      (status) => setEffectiveLoad(status),
      params,
    );
  }

  function addBankDetailsSuccess(response) {
    
    enqueueSnackbar("Bank Added Successfully", {variant : "success"})
    navigate('/profile')
    console.log("bank add",response);
    
  }

  function addBankDetailsFailed(errorList) {
    enqueueSnackbar(errorList.map(item => item.m).join(', '));
  }

  function updateProgress(status) {
    setCtaLoading(status);
  }

  
  function validateForm() {
    let isValid = true;
    let newErrors = {
      accountHoldername: "",
      bankName: "",
      ifscCode: "",
      accountNumber: "",
      reAccountNumber: "",
    };

    if (!accountHoldername || !accountHoldername.trim()) {
      newErrors.accountHoldername = "Account Holder Name is required";
      isValid = false;
    }

    if (!bankName || !bankName.trim()) {
      newErrors.bankName = "Bank name is required ";
      isValid = false;
    }

    if (!ifscCode || !ifscCode.trim()) {
      newErrors.ifscCode = "ISSC is required";
      isValid = false;
    }
    if (!accountNumber) {
      newErrors.accountNumber = "Account No. is required";
      isValid = false;
    }
    if (!reAccountNumber) {
      newErrors.reAccountNumber = " Re-enter Account number";
      isValid = false;
    }
    if (reAccountNumber !== accountNumber) { newErrors.reAccountNumber = "Account numbers don't match"; isValid = false; 

    }



    setError(newErrors);
    return isValid;
  }


  const handleStateSelection = (selectedOption) => {
    if (selectedOption && selectedOption.length > 0) {
      const selectStateId = selectedOption[0]?.label;
      setBankName(selectStateId);
    }
  };

  function renderStateSelection() {
    const bankArray = Object.values(BANKS);
    return (
      <>
        <div className="m-t-12">
          <CustomText p text={"Bank Name"} className="m-b-5" />
          <div className="m-t-4">
            <Select
              isMulti={false}
              options={bankArray.map((bankName) => ({
                value: bankName?.value,
                label: bankName?.label,
              }))}
              value={
                bankName
                  ? {
                      value: bankName,
                      label: bankArray.find((c) => c.id === bankName)?.label,
                    }
                  : null
              }
              onChange={(selectedOption) =>
                handleStateSelection(selectedOption)
              }
              placeholder={"Enter State"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #A6A5BB",
                  backgroundColor: "white",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  fontFamily: "Inter-Medium",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }),
              }}
            />
          </div>
          {error.bankName && <p className="error">{error.bankName}</p>}
        </div>
      </>
    );
  }

  function renderInputBoxes() {
    return (
      <>
      
        <div className="m-t-12">
          <div className='m-b-8' style={{textAlign : "center"}}>
            {/* <CustomText h4 
            text = {"Add Account Details"}
             /> */}
          </div>
          <CustomText p text="Account Holder Name" className="m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={accountHoldername}
              onChange={(e) => setAccountHolderName(e.target.value)}
              placeholder={"Enter Your Name"}
            />
          </div>
          {error.accountHoldername && <p className="error">{error.accountHoldername}</p>}
        </div>

        {/* render Selection box---n */}
        {renderStateSelection()}


        <div className="m-t-12">
          <CustomText p text="IFSC" className="m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
              placeholder={"Enter Your IFSC Code"}
            />
          </div>
          {error.ifscCode && <p className="error">{error.ifscCode}</p>}
        </div>
      

        <div className="m-t-12">
          <CustomText p text="Account Number" className="m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              placeholder={"Enter Your Account No"}
            />
          </div>
          {error.accountNumber && <p className="error">{error.accountNumber}</p>}
        </div>
        <div className="m-t-12">
          <CustomText p text=" Re-enter Account Number" className="m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={reAccountNumber}
              onChange={(e) => setReAccountNumber(e.target.value)}
              placeholder={"Re-enter your Account No"}
            />
          </div>
          {error.reAccountNumber && <p className="error">{error.reAccountNumber}</p>}
        </div>
      </>
    );
  }


  return (
    <>
     <div className="header-fixed flex justiify-center gap-10">
        <div
          className="cursor-pointer p-t-3" style={{paddingLeft : "10px"}}
          onClick={() => navigate("/bank-detail")}
        >
          <Icon icon={svgs.back_black_icon} width={13} height={13} />
        </div>
        <CustomText p text={" Add Bank Details"} />
      </div>

      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div
          className="container"
          style={{
            paddingTop: "54px",
            marginBottom: "120px",
          }}
        >
          {/*-- input boxes -- */}
          {renderInputBoxes()}

          <div className="btn-container-details-page">
            <PrimaryCta
              className={"btn-bottom-detail-page"}
              onClick = {addBankDetails}
              isLoading={effectiveLoad}
              text={"Submit"}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AddBankDetails
