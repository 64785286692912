import Config from '../config';

import Request from '../request';
const categories = require('../data/categories.json');
const platforms = require('../data/platforms.json');
const credit_list = require('../data/credit_list.json');

/////////////////////////// Public Functions START /////////////////////////////
const Sdk = {
  // Api for signup as Influencer
  sendVerificationCode: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_VERIFY_CODE,
      ),
    );
  },

  signInPublic: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_SIGNIN,
      ),
    );
  },

  createInfluencer: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_CREATE_INFLUENCER,
      ),
    );
  },

  getInfluencerDataSelf: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_INFLUENCER_DATA_SELF,
      ),
      authToken,
    );
  },

  getCategory: function (succes_cb, failed_cb, progress_cb, params) {
    succes_cb(categories);
  },

  updateInfluencer: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UPDATE_INFLUENCER,
      ),
    );
  },

  updateInfluencerDisplayPicture: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    let metaData = {
      image_id: params.image_id,
    };
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UPDATE_INFLUENCER_PP,
      ),
      null,
      metaData,
    );
  },

  updateInfluencerData: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UPDATE_INFLUENCER_DATA,
      ),
    );
  },
  submitContentUrl: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_SUBMIT_CONTENT_URL_INFLUENCER,
      ),
    );
  },

  updateInfluencerCategory: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UPDATE_INFLUENCER_CATEGORY,
      ),
    );
  },

  onboardInfluencer: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ONBOARD_INFLUENCER,
      ),
    );
  },
  getAppData: function (succes_cb, failed_cb, progress_cb, params, authToken) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_APP_DATA),
      authToken,
    );
  },

  getCampaignList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CAMPAIGN_LIST,
      ),
      authToken,
    );
  },

  getInvitedCampaignList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_INVITED_CAMPAIGN_LIST,
      ),
      authToken,
    );
  },
  getBrandList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_BRAND_LIST),
      authToken,
    );
  },
  getCategoryList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CATEGORY_LIST,
      ),
      authToken,
    );
  },

  saveCampaignBidData: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_POST_BID_SUBMIT),
    );
  },

  getAssignmentList: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_ASSIGNMENT_LIST_INFLUENCER,
      ),
    );
  },
  getAssignmentData: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_ASSIGNMENT_INFLUENCER,
      ),
    );
  },

  startAssignment: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_START_ASSIGNMENT,
      ),
    );
  },

  getActiveSubscriptionInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_ACTIVE_SUBSCRIPTION_INFLUENCER,
      ),
    );
  },
  getSubscriptionInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_SUBSCRIPTION_INFLUENCER,
      ),
    );
  },
  upgradeSubscription: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_CREATE_SUBSCRIPTION_INFLUENCER,
      ),
    );
  },
  deleteUser: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_DELETE_ACCOUNT,
      ),
    );
  },

  logoutUser: function (succes_cb, failed_cb, progress_cb, params){
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_LOGOUT_ACCOUNT, 
      ),
    );
  },

  getBidList: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_BIDS_LIST_INFLUENCER,
      ),
    );
  },
  getPastBidList: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_PAST_BIDS_LIST_INFLUENCER,
      ),
    );
  },
  getCampaignContentListUser: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CAMPAIGN_CONTENT_USER,
      ),
    );
  },
  seenCampaign: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_CAMPAIGN_SEEN_INFLUENCER,
      ),
    );
  },
  postSubmitContent: function (succes_cb, failed_cb, progress_cb, params) {
    let metaData = {
      content_id: params.content_id,
      media_id: params.content_media_id,
    };
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_SUBMIT_CONTENT_INFLUENCER,
      ),
      null,
      metaData,
    );
  },
  generateUrlInfluencer: function (succes_cb, failed_cb, progress_cb, params) {
    let metaData = {
      content_id: params.content_id,
      content_type_id: params.content_type_id,
    };
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_GENERATE_URL_INFLUENCER,
      ),
      null,
      metaData,
    );
  },

  getBrandDataPublic: function (succes_cb, failed_cb, progress_cb, params) {
    let metaData = {
      brand_id: params.brand_id,
    };
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_BRAND_DATA_PUBLIC,
      ),
      null,
      metaData,
    );
  },
  getPlatformListPublic: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    succes_cb(platforms);
  },
  getPlatformListInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_PLATFORMS_INFLUENCER,
      ),
    );
  },

  getPlatformBasicData: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_SYNC_PLATFORM_AUTO,
      ),
    );
  },

  addSocialAccountAuto: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ADD_PLATFORM_AUTO,
      ),
    );
  },

  postAddPlatform: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_ADD_PLATFORM,
      ),
    );
  },
  postVerifyPlatform: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_VERIFY_PLATFORM,
      ),
    );
  },
  addBankDetails: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_ADD_BANK_DETAILS,
      ),
    );
  },

  getKYCDetails: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_KYC_DETAILS),
    );
  },

  getBankDetails: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_BANK_DETAILS),
    );
  },
  postOrderSubscription: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_CREATE_ORDER_SUBSCRIPTION_INFLUENCER,
      ),
    );
  },
  getOrderDetails: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_ORDER_DETAILS,
      ),
      authToken,
    );
  },

  postRegisterDeviceInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_REGISTER_DEVICE_INFLUENCER,
      ),
      authToken,
    );
  },
  getCampaignDataInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CAMPAIGN_DATA_INFLUENCER,
      ),
    );
  },
  getPayoutsList: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_PAYOUTS_LIST_INFLUENCER,
      ),
    );
  },
  getBalance: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_BALANCE),
    );
  },
  getTransationList: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_TRANSACTION_LIST_INFLUENCER,
      ),
    );
  },
  getNotifications: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_NOTIFICATION_LIST_INFLUENCER,
      ),
    );
  },
  generateUrlKycInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    let metaData = {
      kycItem: params.kycItem,
    };
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_GENERATE_URL_INFLUENCER,
      ),
      null,
      metaData,
    );
  },

  postAddKycDetails: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_ADD_KYC_DETAILS,
      ),
    );
  },
  postRemovePlatform: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_REMOVE_PLATFORM,
      ),
    );
  },
  postSyncPlatform: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_SYNC_PLATFORM,
      ),
    );
  },

  getCreditList: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_CREDT_LIST),
    );
  },

  getCreditDetails: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CREDIT_DETAILS_INFLUENCER,
      ),
    );
  },

  postCreditOrder: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_CREATE_ORDER_INFLUENCER,
      ),
    );
  },

  postSendMessage: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_SEND_MESSAGE,
      ),
    );
  },
  getMessageList: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_MESSAGE_LIST),
    );
  },
  postDeleteMessage: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_DELETE_MESSAGE,
      ),
    );
  },

  getAddressData: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_ADDRESS_DATA),
    );
  },
  postAddAddressData: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_ADD_ADDRESS_DATA,
      ),
    );
  },
  postUpdateAddressData: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UPDATE_ADDRESS_DATA,
      ),
    );
  },
  getBidSummaryAmount: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_BID_SUMMARY),
    );
  },

  postOrgRating: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_POST_RATE_ORG),
    );
  },

  postBidBoost: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_BID_BOOST),
    );
  },
  postApproveCounter: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_ACCEPT_OFFER),
    );
  },

  postRejectCounter: function (succes_cb, failed_cb, progress_cb, params) {
    // Api Call
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_REJECT_OFFER),
    );
  },

  getCreditInfluencerList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_CREDIT_INFLUENCER_LIST,
      ),
    );
  },

  createPortfolio: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_CREATE_PORTFOLIO),
    );
  },

  getPortfolioListInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_LIST_PORTFOLIO),
    );
  },

  deletePortfolioInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
  ) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_DELETE_PORTFOLIO),
    );
  },

  sponsorMeInfluencer: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.POST_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_SPONSOR_ME),
    );
  },

  getFaqListPublic: function (succes_cb, failed_cb, progress_cb, params) {
    Request.handleAPI(
      succes_cb,
      failed_cb,
      progress_cb,
      params,
      Config.GET_METHOD,
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_FAQ_LIST),
    );
  },
};

export default Sdk;

//////////////////////////// Public Functions END //////////////////////////////

/////////////////////////// Private Functions START ////////////////////////////
const _api_function = {
  constructUrn: function (end_point) {
    return Config.BASE_URL + end_point;
  },
};

//////////////////////////// Private Functions END /////////////////////////////
