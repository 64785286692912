import React, { useCallback, useEffect, useState } from "react";
import { CustomText, FallBack } from "../../common/core_component";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import { useNavigate } from "react-router-dom";
import Sdk from "../../common/sdk/influencerSdk";
import { Loader } from "../Loader";
import moment from "moment";
import ReactPullToRefresh from "react-pull-to-refresh";

function PayoutHistory() {
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);

  // const handlePullToRefresh = useCallback(async () => {
  //   setPullToRefreshLoading(true);
  //   setLoading(true)
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setPullToRefreshLoading(false); 
  //   setLoading(false)
  // }, []);

  useEffect(() => {
    getPayoutsList();
  }, []);

  function getPayoutsList() {
    var params = {
      status: "COMPLETED",
    };
    Sdk.getPayoutsList(
      getPayoutsListSuccess,
      getPayoutsListFailed,
      updateProgress,
      params
    );
  }

  function getPayoutsListSuccess(response) {
    setHistoryList(response?.payout_data);
  }

  function getPayoutsListFailed(error) {
    console.error("get Payout List Failed", error);
  }

  function updateProgress(status) {
    setLoading(status);
  }

  const navigate = useNavigate();

//  ------- UI REnder ------ 
function renderPayoutDetails() {
  if (!historyList || historyList.length === 0) {
    return (
      <div className="fallback p-t-12">
        <FallBack
          icon={svgs.folder}
          heading="No Payout"
          title="No payouts found"
        />
      </div>
    );
  }
  return (
    <>

      <div className="container white-card-bg m-t-8 flex flex-column justify-center">
        {historyList.map((payout, index) => (
          <React.Fragment key={index}>
            <div className="flex justify-between m-t-8 m-b-8">
              <div className="flex flex-column">
                <CustomText
                  p
                  text={`Task Payment scheduled at ${
                    moment.unix(payout?.date_scheduled).format("DD MMM") ||
                    "N/A"
                  }`}
                  className="text-14"
                />
                <CustomText
                  p
                  text={`Contract Id: #${
                    payout?.assignment_id?.toUpperCase() || "N/A"
                  }`}
                  className="text-14"
                />
              </div>
              <div>
                <CustomText
                  p
                  text={`Rs. ${payout?.payout_amount || "-"}`}
                  className="green text-align m-t-4"
                />
              </div>
            </div>
            {index !== historyList.length - 1 && <hr className="dashed-line" />}
          </React.Fragment>
        ))}
      </div>
     
    </>
  );
}



  return (
    <div className="bg-app-gray">
      <div className="header-fixed flex gap-10">
        <div
          className="cursor-pointer p-t-3"
          style={{paddingLeft : "10px"}}
          onClick={() => navigate("/earning")}
        >
          <Icon icon={svgs.back_black_icon} width={13} height={13} />
        </div>
        <CustomText p text={"Payout History"} />
      </div>

      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div
          className=""
          style={{  paddingTop: "58px" }}
        >
          <div className="">
            {renderPayoutDetails()}</div>
        </div>
      )}
    </div>
  );
}

export default PayoutHistory;
