// const Icons = {
//   more_icon: {
//     name: 'icon_more',
//     height: 21,
//     width: 21,
//   },
//   back_white_icon: {
//     name: 'back_white_icon',
//     height: 15,
//     width: 15,
//     color: '#FFFFFF',
//   },
// };
//

const Icon = ({ icon , width, height }) => {
  return (
      <svg viewBox={icon.viewBox} width={width} height= {height} >
          {icon.svg}
      </svg>
  );
};

export default Icon;
