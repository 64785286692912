import React, { useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import Sdk from "../../common/sdk/influencerSdk";
import { CustomText, FallBack, PrimaryCta } from "../../common/core_component";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Loader } from "../Loader";
import Utils from "../../common/utils";
import { useSelector } from "react-redux";
import svgs from "../../assets/icons/svgs";

function CreditBottom({ isModalOpen, setModalOpen }) {
  const [creditId, setCreditId] = useState("003");
  const [creditDataList, setCreditdataList] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [credit, setCredit] = useState(null);
  const navigate = useNavigate();
  const [effectiveLoad, setEffectiveLoad] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const closeSendProposalModal = () => setModalOpen(false);

  const handleSelectCredit = (id) => {
    setCreditId(id);
  };

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  console.log("userData", userData);

  console.log("influn", influencer_data);

  useEffect(() => {
    if (Utils.influencerLogined_Or_Not(influencer_data.influencer_id)) {
      getCreditList();
      getCreditDetails();
    }
  }, []);

  function getCreditList() {
    const params = {};
    Sdk.getCreditList(
      getCreditListSuccess,
      getCreditListFailed,
      updateProgress,
      params
    );
  }

  function getCreditListSuccess(response) {
    setCreditdataList(response.credit_list.basic);
  }
  console.log("cred", creditDataList);

  function getCreditListFailed(errorList) {
    console.error("Error fetching credit list:", errorList);
  }

  // Update loading state
  function updateProgress(status) {
    setLoading(status);
  }

  function getCreditDetails() {
    var params = {};
    Sdk.getCreditDetails(
      getCreditDetailsSuccess,
      getCreditDetailsFailed,
      updateProgress,
      params
    );
  }

  function getCreditDetailsSuccess(response) {
    setCredit(response);
  }

  function getCreditDetailsFailed(errorList) {
    console.error("Error fetching credit details:", errorList);
  }


  //  ------ Credit Buys -------

  const onCreditPurchaseSuccess = () => {
    getCreditDetails();
    enqueueSnackbar("Credits added to you account successfully", {
      variant: "success",
    });
  };

  async function createCreditOrder(c_id) {
    const params = {
      c_id: c_id,
      redirect_url: "getreelax://payment/success",
      // platform: Platform.OS,
    };

    Sdk.postCreditOrder(
      createCreditOrderSuccess,
      createCreditOrderFailed,
      (status) => setEffectiveLoad(status),
      params
      // authToken,
    );
  }

  const downloadAppNavigatorBTN = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    if(/android/i.test(userAgent)) {
      // Redirect to Play Store
      window.location.href = "https://play.google.com/store/apps/details?id=com.reelax.influencer";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to App Store
      window.location.href = "https://apps.apple.com/in/app/reelax-influencer-app/id6464481879";
    }
     else {
     enqueueSnackbar ("Unsupported platform. Please visit on iOS or Android.");
    }
  };

  async function createCreditOrderSuccess(response) {
    console.log("pay ", response);
    if (response) {
      try {
        await createOrder(response?.credit_order_data);
        onCreditPurchaseSuccess();
      } catch (error) {
        setLoading(false);
        enqueueSnackbar("Payment failed", { variant: "error" });
        setModalOpen(false);
      }
    }
  }

  const createOrder = (order_data) => {
    return new Promise((resolve, reject) => {
      const options = {
        key: order_data.payment_gateway_id,
        order_id: order_data?.pg_order_id,
        amount: order_data?.amount,
        currency: "INR",
        handler: function (response) {
          // console.log("Payment Success", response);
          enqueueSnackbar("Payment Successfully", { variant: "success" });

          resolve(response); // Payment success
        },
        modal: {
          ondismiss: function () {
            reject(new Error("Payment was dismissed by the user"));
          },
        },

        theme: {
          color: "#F37254",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

      rzp.on("payment.failed", function (response) {
        // Razorpay failure callback
        enqueueSnackbar("Payment Failed", { variant: "error" });
        reject(new Error(response.error.description));
      });
    });
  };

  function createCreditOrderFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "), {
      variant: "error",
    });
  }

  function renderCreditScreen() {
    return (
      <>
        
     {creditDataList && 
        <div>
            <div className="fallback">
          <FallBack
            icon={svgs.reelaxLogo}
            heading="Download Reelax App"
            title="For Buying more Credits Download our App"
          />
 <div className='container page-center m-t-12 m-b-20'>
    <PrimaryCta
    className = {"custom-button"}
    onClick = {()=> downloadAppNavigatorBTN()} 
    text = {"Download Reelax App"}
      />
    </div>
        </div>
            </div>
      
     }  
      </>
    );
  }

  return (
    <div>
      <BottomSheet
        open={isModalOpen}
        onDismiss={closeSendProposalModal}
        snapPoints={({ maxHeight }) => [0.84 * maxHeight, maxHeight]}
        style={{ overflowY: "auto", zIndex: "1000", position: "absolute" }}
      >
        <div
      style={{
        width: "100%", 
        maxWidth: "786px", 
        margin: "0 auto", 
        paddingTop: "0px",
      }}
    >
          {loading ?
           <div className="loader-mid">
            <Loader/>
           </div> 
           :
           renderCreditScreen()
            }

        </div>
      </BottomSheet>
    </div>
  );
}

export default CreditBottom;
