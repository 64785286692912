import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomText, FallBack, PrimaryCta } from "../common/core_component";
import reelaxLogo from "../assets/images/reelax-logo-dark-full.png";
import Sdk from "../common/sdk/influencerSdk";
import Utils from "../common/utils";
import Ourlogo from "../assets/images/small-logo.png";
import { Loader } from "./Loader";

import * as Action from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../assets/icons";
import svgs from "../assets/icons/svgs";
import Footer from "./common/Footer";
import CreditBottom from "./common/CreditBottom";
import { useSnackbar } from "notistack";
import ReactPullToRefresh from "react-pull-to-refresh";

function HomeScreen() {
  const [freeCampaignList, setFreeCampaignList] = useState(null);
  const [campaignList, setCampaignList] = useState(null);
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [assignment_list, setAssignmentList] = useState();
  const [boostedCampaignList, setBoostedCampaignList] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);

  // const handlePullToRefresh = useCallback(async () => {
  //   setPullToRefreshLoading(true);
  //   setLoading(true);
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setPullToRefreshLoading(false);
  //   setLoading(false);
  // }, []);

  const navigate = useNavigate();

  useEffect(() => {
    getFreeCampaignList();
    getCampaignList();
    getBrandList();
    getCategoryList();
    getBoostedCampaignList();
    getAssignmentList();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  // ------------- redux ---------
  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  console.log("userData", userData);

  console.log("influn", influencer_data);

  // console.log("id",  Utils.influencerLogined_Or_Not(influencer_data?.influencer_id));

  const openCreditBottom = () => setModalOpen(true);

  // ----------- Free CampaignList ---------------

  function getFreeCampaignList() {
    const params = {
      influencer_id: influencer_data?.influencer_id,
      is_free: true,
    };

    Sdk.getCampaignList(
      getFreeCampaignListSuccess,
      getFreeCampaignListFailed,
      updateProgress,
      params
      // authToken,
    );
  }

  async function getFreeCampaignListSuccess(api_response) {
    setFreeCampaignList(api_response.campaign_data);
  }
  console.log("free", freeCampaignList);

  function getFreeCampaignListFailed(error_list) {
    console.log("response error", error_list);
  }

  //  -------- Hot Campaign ---------
  function getBoostedCampaignList() {
    const params = {
      influencer_id: influencer_data?.influencer_id,
      is_boosted: true,
    };

    Sdk.getCampaignList(
      getBoostedCampaignListSuccess,
      getBoostedCampaignListFailed,
      updateProgress,
      params
      // authToken,
    );
  }

  function getBoostedCampaignListSuccess(api_response) {
    setBoostedCampaignList(api_response.campaign_data || []);
  }
  console.log("boost", boostedCampaignList);

  function getBoostedCampaignListFailed(error_list) {
    console.log("response error", error_list);
  }

  // ----------- Campaign List ----------
  async function getCampaignList() {
    const params = {
      influencer_id: influencer_data?.influencer_id,
    };

    Sdk.getCampaignList(
      getCampaignListSuccess,
      getCampaignListFailed,
      updateProgress,
      params
      // authToken,
    );
  }

  async function getCampaignListSuccess(api_response) {
    // await AsyncStorage.setItem('CAMPAIGNS_LOCAL', JSON.stringify(api_response));

    setCampaignList(api_response.campaign_data);

    // localNotificationInstance.current.checkPermission(handlePermission);
  }

  console.log("campagin ", campaignList);

  function getCampaignListFailed(error_list) {
    console.log("response error", error_list);
  }
  // ------------ Brand List ---------------

  async function getBrandList() {
    const params = {
      start: 0,
    };

    Sdk.getBrandList(
      getBrandListSuccess,
      getBrandListFailed,
      updateProgress,
      params
      // authToken,
    );
  }

  async function getBrandListSuccess(brandList) {
    // const {brand_list} = brandList;
    // await AsyncStorage.setItem('BRANDS_LOCAL', JSON.stringify(brand_list));
    setBrandList(brandList.brand_list);
  }
  console.log("brand", brandList);

  function getBrandListFailed(error_list) {
    console.log("response error", error_list);
  }

  //  ------------- Category List ------------
  async function getCategoryList() {
    const params = {};

    Sdk.getCategoryList(
      getCategoryListSuccess,
      getCategoryListFailed,
      updateProgress,
      params
      // authToken,
    );
  }

  async function getCategoryListSuccess(categoryList) {
    // const {category_list} = categoryList;
    // await AsyncStorage.setItem(
    //   'CATEGORIES_LOCAL',
    //   JSON.stringify(Object.values(category_list)),
    // );
    // setCategoryList(Object.values(category_list));
    setCategoryList(categoryList.category_list);
  }
  console.log("cat", categoryList);

  function getCategoryListFailed(error_list) {
    console.log("response error", error_list);
  }

  // ------ Assignment List -------
  async function getAssignmentList() {
    if (!Utils.influencerLogined_Or_Not(influencer_data.influencer_id)) {
      return;
    }
    var params = {
      status: "IN_PROGRESS",
    };

    Sdk.getAssignmentList(
      getAssignmentListSuccess,
      getAssignmentListFailed,
      updateProgress,
      params
    );
  }

  async function getAssignmentListSuccess(api_response) {
    setAssignmentList(api_response.assignment_list.slice(0, 3));
  }
  console.log("assignment", assignment_list);

  function getAssignmentListFailed(error_list) {
    console.error("response error Assignment list", error_list);
  }
  // ------------ UI Render --------------------

  function renderSocialMediaIcon(content_type = []) {
    const platforms = [];
    return content_type.map((item, index) => {
      if (
        item.platform_data &&
        item.platform_data.platform_id &&
        !platforms.includes(item.platform_data.platform_id)
      ) {
        platforms.push(item.platform_data.platform_id);

        var icon = Utils.socialMediaIcons(item.platform_data.platform_id);
        return (
          <div key={index} className="card-icon-campaign">
            <Icon icon={icon} height={15} width={15} />
          </div>
        );
      }

      return null;
    });
  }

  function renderAssignmentCount(contents_deep_data = {}) {
    let totalContent = 0;
    let completeWork = 0;

    Object.keys(contents_deep_data).forEach((item) => {
      const contentData = contents_deep_data[item]?.content_data;
      if (contentData) {
        totalContent += 1;
        if (contentData.status === "COMPLETED") {
          completeWork += 1;
        }
      }
    });

    return `${completeWork}/${totalContent || 1}`;
  }

  function renderAssignmentSocialMediaIcon(contents_deep_data = []) {
    const platforms = [];
    return Object.keys(contents_deep_data).map((item) => {
      if (
        !platforms.includes(contents_deep_data[item].platform_data.platform_id)
      ) {
        platforms.push(contents_deep_data[item].platform_data.platform_id);
        return (
          <div className="" style={{ marginRight: "6px" }}>
            <Icon
              icon={Utils.socialMediaIcons(
                contents_deep_data[item].platform_data.platform_id
              )}
              height={17}
              width={17}
              fill={"#919191"}
            />
          </div>
        );
      }
    });
  }

  function renderActiveAssignmentList() {
    const navigateTo = (id) => {
      navigate(`/contract-details/${id}`);
    };
    const viewAll = () => {
      navigate("/contracts", { state: { SendTab: "in-review" } });
    };
    const renderBudget = (reward_type, amount) => {
      if (reward_type == "cash") {
        return Utils.formatMoney(amount);
      } else {
        return amount + " Credits";
      }
    };

    return assignment_list && assignment_list.length > 0 ? (
      <>
        <div className="flex justify-between">
          <CustomText
            p
            text={"Active Contract"}
            className="text-13 text-dark font-600"
          />
          <div onClick={viewAll} style={{ cursor: "pointer" }}>
            <CustomText
              p
              text={"View All"}
              className="text-13 text-dark font-600"
            />
          </div>
        </div>
        {assignment_list && assignment_list.length > 0 ? (
          <div className="m-t-6">
            <div className="m-t-8">
              {assignment_list.map((assignment, index) => (
                <div
                  key={index}
                  onClick={() =>
                    navigateTo(assignment?.assignment_data?.assignment_id)
                  }
                  className="card-bid-contract m-t-12"
                >
                  <div className="card-icon-bid-contract">
                    <img
                      src={
                        assignment.brand_data?.image_id
                          ? Utils.generateBrandLogoUrl(
                              assignment.brand_data?.org_id,
                              assignment.brand_data?.image_id
                            )
                          : Ourlogo
                      }
                      alt="Brand Logo"
                    />
                  </div>
                  <div className="card-content-campaign">
                    <div className="card-title-campaign flex justify-between">
                      <CustomText
                        h5
                        text={assignment?.campaign_data?.name}
                        className="text-13"
                      />
                      <div className="p-r-8">
                        <CustomText
                          p
                          text={renderAssignmentCount(
                            assignment_list?.contents_deep_data
                          )}
                          className="text-12"
                        />
                      </div>
                    </div>
                    <div className="card-timing-campaign">
                      <CustomText
                        p
                        text={`Signed ${Utils.convertUnixToHumanDate(
                          assignment?.assignment_data?.date_added
                        )}`}
                        className="text-12 error"
                      />
                    </div>
                    <div className="card-detals-campaign">
                      <CustomText
                        p
                        text={renderBudget(
                          assignment?.reward_data?.reward_type,
                          assignment?.reward_data?.value?.amount
                        )}
                        className="text-12 m-t-4"
                      />
                      <CustomText
                        p
                        text={Utils.getCampaignTypeText(
                          assignment?.campaign_data.type
                        )}
                        className="text-12 m-t-4"
                      />
                      <div>
                        {renderAssignmentSocialMediaIcon(
                          assignment_list?.content_type_deep_data
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    ) : (
      ""
    );
  }

  function renderConnectSocialMedia() {
    const navigateTo = () => navigate("/social-channel");
    if (
      userData &&
      userData.platforms_data &&
      userData.platforms_data.length > 0
    ) {
      return null;
    } else {
      return (
        influencer_data?.influencer_id &&
        userData && (
          <>
            <div className="socailMediaConnect">
              <CustomText
                p
                text={`Welcome ${influencer_data.name || "n/a"} `}
                className="text-13 font-600 opacity"
              />
              <CustomText
                p
                text={`Connect your social media accounts to begin sending proposals.`}
                className="small-txt"
              />
              <div className="flex justify-center m-b-8 m-t-12">
                <PrimaryCta
                  text={"Connect Account"}
                  className="custom-button-inside-card"
                  onClick={navigateTo}
                />
              </div>
            </div>
          </>
        )
      );
    }
  }

  function renderTopBrands() {
    const navigateTo = (id) => {
      navigate(`/campaign-brand/${id}`);
    };

    return (
      <>
        {brandList && brandList.length > 0 ? (
          <div className="m-t-6">
            <div className="flex p-b-6 justify-between">
              <CustomText
                p
                text={"Top Brands"}
                className="text-13 text-dark font-600"
              />
            </div>
            <div className="m-t-6 flex justify-between">
              {brandList.slice(0, 5).map((brand) => (
                <div
                  key={brand.org_id}
                  className="cursor-pointer"
                  onClick={() => navigateTo(brand?.brand_id)}
                >
                  <img
                    src={Utils.generateBrandLogoUrl(
                      brand.org_id,
                      brand.image_id
                    )}
                    alt="logo"
                    className="brand-logo"
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderCategories() {
    const navigateTo = (id) => {
      navigate(`/campaign-category/${id}`);
    };
    return (
      <>
        {categoryList && Object.keys(categoryList).length > 0 ? (
          <div className="m-t-6">
            <div>
              <CustomText
                p
                text="Top Categories"
                className="text-13 font-600"
              />
            </div>
            <div className="category-container m-t-12">
              {Object.values(categoryList).map((item, index) => (
                <div
                  key={item.id}
                  className="category-box cursor-pointer"
                  onClick={() => {
                    navigateTo(item?.id);
                  }}
                >
                  <CustomText p text={item.name} className="text-14" />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderCampaignList() {
    const navigateTo = (campaign_id) => {
      navigate(`/campaign/${campaign_id}`);
    };
    const veiwAll = () => {
      navigate("/campaigns");
    };
    const renderCamapaignAmount = (max_budget, collab_type, product_price) => {
      if (collab_type == "BARTER") {
        return Utils.formatMoney(product_price);
      }
      return "UPTO " + Utils.formatMoney(max_budget);
    };
    return (
      <>
        {campaignList && campaignList.length > 0 && (
          <div className="m-t-6">
            <div className="flex justify-between">
              <CustomText
                p
                text={"New Campaigns"}
                className="text-13 text-dark font-600"
              />
              <div onClick={veiwAll} style={{ cursor: "pointer" }}>
                <CustomText
                  p
                  text={"View All"}
                  className="text-13 text-dark font-600"
                />
              </div>
            </div>
            <div className="m-t-12">
              {campaignList.slice(0, 4).map((campagin, index) => (
                <div
                  key={index}
                  onClick={() =>
                    navigateTo(
                      campagin.campaign_data.campaign_id
                    )
                  }
                  className="card-campaign m-t-12"
                >
                  <div className="card-icon-campaign">
                    <img
                      src={
                        campagin.brand_data?.image_id
                          ? Utils.generateBrandLogoUrl(
                              campagin.brand_data?.org_id,
                              campagin.brand_data?.image_id
                            )
                          : Ourlogo
                      }
                      alt="Brand Logo"
                    />
                  </div>
                  <div className="card-content-campaign">
                    <div className="card-title-campaign ">
                      <CustomText
                        h5
                        text={Utils.truncateText(campagin.campaign_data.name)}
                        className="text-14"
                      />
                    </div>
                    <div className="card-timing-campaign">
                      <CustomText
                        p
                        text={`Ending ${Utils.convertUnixToHumanDate(
                          campagin.campaign_data.end_date
                        )}`}
                      />
                    </div>
                    <div className="card-detals-campaign">
                      <CustomText
                        p
                        text={Utils.getCampaignTypeText(
                          campagin.campaign_data.type
                        )}
                        className="small-txt"
                      />
                      <CustomText
                        p
                        text={
                          campagin.campaign_data.collab_type === "BARTER"
                            ? "CASHBACK"
                            : `${campagin.reward_data.type.toUpperCase()} UPTO  ${Utils.formatMoney(
                                campagin.campaign_data.max_budget.toFixed(0)
                              )}`
                        }
                        className="small-txt"
                      />
                    </div>
                    <div className="card-footer-campaign">
                      <div className="credits-campaign">
                        <span>
                          {renderSocialMediaIcon(
                            campagin?.content_type_deep_data
                          )}
                        </span>

                        <span>
                          {!campagin.campaign_data.campaign_credits
                            ? "FREE"
                            : `${campagin.campaign_data.campaign_credits} CREDITS`}
                        </span>
                      </div>
                      <div className="proposals-campaign">
                        {`${campagin.campaign_data.bid_count || 0} PROPOSALS`}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }

  function renderHotCampaign() {
    const navigateTo = (campaign_id) => {
      navigate(`/campaign/${campaign_id}`);
    };
    const veiwAll = () => {
      navigate("campaigns");
    };

    return (
      <>
        {boostedCampaignList && boostedCampaignList.length > 0 && (
          <div className="m-t-6">
            <div className="flex justify-between">
              <CustomText
                p
                text={"Hot Campaigns"}
                className="text-13 font-600"
              />
              <div onClick={veiwAll} style={{ cursor: "pointer" }}>
                <CustomText p text={"View All"} className="text-13 font-600" />
              </div>
            </div>
            <div className="m-t-12">
              {boostedCampaignList.slice(0, 3).map((campagin, index) => (
                <div
                  key={index}
                  onClick={() =>
                    navigateTo(
                      campagin.campaign_data.campaign_id
                    )
                  }
                  className="card-campaign m-t-12"
                >
                  <div className="card-icon-campaign">
                    <img
                      src={
                        campagin.brand_data?.image_id
                          ? Utils.generateBrandLogoUrl(
                              campagin.brand_data?.org_id,
                              campagin.brand_data?.image_id
                            )
                          : Ourlogo
                      }
                      alt="Brand Logo"
                    />
                  </div>
                  <div className="card-content-campaign">
                    <div className="card-title-campaign">
                      <CustomText
                        h5
                        text={Utils.truncateText(campagin.campaign_data.name)}
                        className="text-14"
                      />
                    </div>
                    <div className="card-timing-campaign">
                      <CustomText
                        p
                        text={`Ending ${Utils.convertUnixToHumanDate(
                          campagin.campaign_data.end_date
                        )}`}
                      />
                    </div>
                    <div className="card-detals-campaign">
                      <CustomText
                        p
                        text={Utils.getCampaignTypeText(
                          campagin.campaign_data.type
                        )}
                        className="small-txt"
                      />
                      <CustomText
                        p
                        text={
                          campagin.campaign_data.collab_type === "BARTER"
                            ? "CASHBACK"
                            : `${campagin.reward_data.type.toUpperCase()} UPTO ${Utils.formatMoney(
                                campagin.campaign_data.max_budget.toFixed(0)
                              )}`
                        }
                        className="small-txt"
                      />
                    </div>
                    <div className="card-footer-campaign">
                      <div className="credits-campaign">
                        <span>
                          {renderSocialMediaIcon(
                            campagin?.content_type_deep_data
                          )}
                        </span>

                        <span>
                          {!campagin.campaign_data.campaign_credits
                            ? "FREE"
                            : `${campagin.campaign_data.campaign_credits} CREDITS`}
                        </span>
                      </div>
                      <div className="proposals-campaign">
                        {`${campagin.campaign_data.bid_count || 0} PROPOSALS`}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }

  function renderFreeCampaignList() {
    const navigateTo = (campaign_id) => {
      navigate(`/campaign/${campaign_id}`);
    };
    const veiwAll = () => {
      navigate("/campaigns");
    };

    return (
      <>
        {freeCampaignList && freeCampaignList.length > 0 && (
          <div className="m-t-6">
            <div className="flex justify-between">
              <CustomText
                p
                text={"Free Campaigns"}
                className="text-13 text-dark font-600"
              />
              <div onClick={veiwAll} style={{ cursor: "pointer" }}>
                <CustomText
                  p
                  text={"View All"}
                  className="text-13 text-dark font-600"
                />
              </div>
            </div>
            <div className="m-t-12">
              {freeCampaignList.slice(0, 3).map((campagin, index) => (
                <div
                  key={index}
                  onClick={() =>
                    navigateTo(
                      campagin.campaign_data.campaign_id
                    )
                  }
                  className="card-campaign m-t-12"
                >
                  <div className="card-icon-campaign">
                    <img
                      src={
                        campagin.brand_data?.image_id
                          ? Utils.generateBrandLogoUrl(
                              campagin.brand_data?.org_id,
                              campagin.brand_data?.image_id
                            )
                          : Ourlogo
                      }
                      alt="Brand Logo"
                    />
                  </div>
                  <div className="card-content-campaign">
                    <div className="card-title-campaign">
                      <CustomText
                        h5
                        text={Utils.truncateText(campagin.campaign_data.name)}
                        className="text-14"
                      />
                    </div>
                    <div className="card-timing-campaign">
                      <CustomText
                        p
                        text={`Ending ${Utils.convertUnixToHumanDate(
                          campagin.campaign_data.end_date
                        )}`}
                      />
                    </div>
                    <div className="card-detals-campaign">
                      <CustomText
                        p
                        text={Utils.getCampaignTypeText(
                          campagin.campaign_data.type
                        )}
                        className="small-txt"
                      />
                      <CustomText
                        p
                        text={
                          campagin.campaign_data.collab_type === "BARTER"
                            ? "CASHBACK"
                            : `${campagin.reward_data.type.toUpperCase()} UPTO Rs ${Utils.formatMoney(
                                campagin.campaign_data.max_budget.toFixed(0)
                              )}`
                        }
                        className="small-txt"
                      />
                    </div>
                    <div className="card-footer-campaign">
                      <div className="credits-campaign">
                        <span>
                          {renderSocialMediaIcon(
                            campagin?.content_type_deep_data
                          )}
                        </span>

                        <span>
                          {!campagin.campaign_data.campaign_credits
                            ? "FREE"
                            : `${campagin.campaign_data.campaign_credits} CREDITS`}
                        </span>
                      </div>
                      <div className="proposals-campaign">
                        {`${campagin.campaign_data.bid_count || 0} PROPOSALS`}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }

  function renderWhatsAppChannel() {
    return (
      <div
        className="whatsapp-container"
        onClick={() =>
          window.open(
            "https://www.whatsapp.com/channel/0029Va9oDCT9WtBuJt6itV1M",
            "_blank"
          )
        }
      >
        <div className="m-t-8 m-b-8">
          <CustomText h5 text={"WhatsApp channel"} />
        </div>
        <div className="m-b-8">
          <CustomText
            p
            text={
              "Join the Reelax WhatsApp channel to get instant updates about the campaigns and offers"
            }
            className="small-txt"
          />
        </div>
        {/* <div
          style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
          // onClick={() => setAsyncWhatsAppJoined(true)} ---- Not used 
        >
          <img
            src="" 
            alt="Close Icon"
            height="10"
            width="10"
          />
        </div> */}
      </div>
    );
  }

  return (
    <>
      <div className="bg-app-gray">
        <div className="home-header-fixed">
          <div className="header-section">
            <img src={reelaxLogo} alt="logo" className="reelax-logo" />

            <div className="flex gap-5 page-center">
              {influencer_data?.influencer_id ? (
                <div
                  className="bg-credits cursor-pointer"
                  onClick={openCreditBottom}
                >
                  <Icon icon={svgs.primeIcon} width={27} height={27} />
                </div>
              ) : (
                ""
              )}
              {/* Render CreditBottom component and pass isModalOpen & setModalOpen */}

              <CreditBottom
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <div className="" style={{ paddingTop: "61px" }}>
            {/* ------- padding Top because it is hiding under the header section */}
            <div className="container" style={{ marginBottom: "60px" }}>
              <div className="m-t-12"> {renderConnectSocialMedia()}</div>
              {/* <div className="m-t-15">{renderWhatsAppChannel()}</div> */}
              <div className="m-t-15">{renderTopBrands()}</div>
              <div className="m-t-20">{renderCategories()}</div>
              <div className="m-t-15">{renderActiveAssignmentList()}</div>
              <div className="m-t-20">{renderCampaignList()}</div>
              <div className="m-t-20">{renderHotCampaign()}</div>
              <div className="m-t-20">{renderFreeCampaignList()}</div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default HomeScreen;
