import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../Loader';
import { useSnackbar } from 'notistack';
import svgs from '../../assets/icons/svgs';
import Icon from '../../assets/icons';
import { CustomText, FallBack, PrimaryCta } from '../../common/core_component';
import { useSelector } from 'react-redux';
import Sdk from '../../common/sdk/influencerSdk';
import Utils from '../../common/utils';

function KycDetails() {
  
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState({});
  const [uploadingStatus, setUploadingStatus] = useState({});
  const [kycValue, setKycValue] = useState({});
  const [kycList, setKycList] = useState([]);

  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate()

  const reduxData = useSelector(state => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  console.log("data", userData);

  const INFLUENCER_KYC_TYPES = ['ADDRESS_PROOF', 'TAX_PROOF', 'BANK_PROOF'];

  const kyc_types = [ 'Aadhaar','PAN', 'Bank Account']
  
  useEffect(() => {
    // getKYCDetails();
  }, []);

  const downloadAppNavigatorBTN = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    if(/android/i.test(userAgent)) {
      // Redirect to Play Store
      window.location.href = "https://play.google.com/store/apps/details?id=com.reelax.influencer";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to App Store
      window.location.href = "https://apps.apple.com/in/app/reelax-influencer-app/id6464481879";
    }
     else {
     enqueueSnackbar("Unsupported platform. Please visit on iOS or Android.");
    }
  };

  function getKYCDetails() {
    Sdk.getKYCDetails(
      getKYCDetailsSuccess,
      getKYCDetailsFailed,
      updateProgress,
      {},
    );
  }

  function getKYCDetailsSuccess(response) {
    setKycList(response.kyc_data);
  }
  console.log("list", kycList);
  
 
  function getKYCDetailsFailed(errorList) {
    enqueueSnackbar(errorList.map(item => item.m).join(', ') , );
  }

  function updateProgress(status) {
    setLoading(status);
  }


  // ----- Not in Use now 
  function renderSubText(kycData){
    let subText = "s";
    if (kycData) {
      if (kycData.status === "IN_REVIEW") {
        subText = () => (
          <CustomText
          p
           text = {"Under Review (Review takes 2-3 working days"}
           className= "text-color-p" />
        );
      } else if (kycData.status === "COMPLETED") {
        subText = () => <CustomText
        p 
        text = {"Approved"}
        className = "success"
        />;
      } else if (kycData.status === "REJECTED") {
        subText = () =>  <CustomText
        p
       text = { "Rejected"}
       className = "error "
        />;
      }
    } else {
      subText = () =>  <CustomText
      p
       text =  {"Pending"}
       className = "error"
     />;
    }
    return subText();
  }

  function renderKYCItem(kyc_type) {
    const kycData = kycList.find(kycItem => kycItem.kyc_type === kyc_type);

    return (
      <div className='flex justify-between '
        style={
          {
            paddingTop : "20px",
            paddingBottom : "20px",
          }}
        // onClick={() =>
        //   props.navigation.navigate('KycDetails', {
        //     kycType: kyc_type,
        //     refreshUserData: getKYCDetails,
        //   })
        // }
        >
        <div>
            {/* <CustomText p text={Utils.getKYCTypeString(kyc_type).n} /> */}
            <CustomText p text={Utils.getKYCTypeString(kyc_type)["n"]} />
        
         
          {renderSubText(kycData)()}
        </div>

        <div className=''>
          <Icon icon={svgs.icon_dropdownRight} height={14} width={14} />
        </div>
      </div>
    );
  }

  function renderKyctypes (){
    return (
      <div className='white-card-bg'>
        <div>

        </div>

      </div>
    )
  }

  return (
    <>
    <div className="header-fixed flex justiify-center gap-10">
        <div
          className="cursor-pointer p-t-3"
          style={{paddingLeft : "10px"}}
          onClick={() => navigate("/profile")}
        >
          <Icon icon={svgs.back_black_icon} width={13} height={13} />

        </div>
        <CustomText p text={"My KYC"} />
      </div>
      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div className="">
          <div
            className="bg-app-gray "
            style={{ paddingTop: "47px", minHeight: "90.5vh" }}
          >
          <div className="fallback" style={{marginTop: "18px"}}>
          <FallBack
            icon={svgs.reelaxLogo}
            heading="Download Reelax App"
            title="For KYC Details download our app"
          />
    <div className='container page-center' style={{
  marginTop : '15px', marginBottom : "23px"
 }}>
    <PrimaryCta
    className = {"custom-button"}
    onClick = {()=> downloadAppNavigatorBTN()} 
    text = {"Download Get Reelax App"}
      />

    </div>
        </div>
      
     
    </div>
        
            </div>
    
      )}
    </>
  )
}

export default KycDetails
