import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from '../Loader'
import { CustomText, FallBack } from '../../common/core_component'
import Icon from '../../assets/icons'
import svgs from '../../assets/icons/svgs'
import { useNavigate, useParams } from 'react-router-dom'
import Sdk from '../../common/sdk/influencerSdk'
import Utils from '../../common/utils'
import Ourlogo from "../../assets/images/small-logo.png";
import ReactPullToRefresh from 'react-pull-to-refresh'

function CampaignByCategory() {

  const category_id = useParams().id;
  console.log("id", category_id);

  const [loading, set_loading] = useState(false);
  const [campaign_list, set_campaign_list] = useState([]); 
  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);
  // const handlePullToRefresh = useCallback(async () => {
  //   setPullToRefreshLoading(true);
  //   set_loading(true)
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setPullToRefreshLoading(false); 
  //   set_loading(false)
  // }, []);


  const navigate = useNavigate();

  useEffect(() => {
    getCampaignList();
  }, []);  // Empty dependency array to call on mount.

  function updateProgress(status) {
    set_loading(status);
  }

  function getCampaignList() {
    var params = {
      category_id: category_id,
    };
    Sdk.getCampaignList(
      getCampaignListSuccess,
      getCampaignListFailed,
      updateProgress,
      params,
    );
  }

  function getCampaignListSuccess(api_response) {
    console.log("cat", api_response);
    // Set campaign_list to the correct data from API response.
    set_campaign_list(api_response.campaign_data || []); // Ensure this is an array.
  }

  function getCampaignListFailed(error_list) {
    console.error('response error', error_list);
  }

  // Function to render social media icons
  function renderSocialMediaIcon(content_type = []) {
    const platforms = [];
    return content_type.map((item, index) => {
      if (
        item.platform_data &&
        item.platform_data.platform_id &&
        !platforms.includes(item.platform_data.platform_id)
      ) {
        platforms.push(item.platform_data.platform_id);
        const icon = Utils.socialMediaIcons(item.platform_data.platform_id);
        return (
          <div key={index} className="card-icon-campaign">
            <Icon
              icon={Utils.socialMediaIcons(item.platform_data.platform_id)}
              height={15}
              width={15}
            />
          </div>
        );
      }
      return null;
    });
  }

  // Function to render the campaign list
  function renderCampaignList() {
    const navigateTo = (campaign_id) => {
      navigate(`/campaign/${campaign_id}`);
    };
    return (
      <>
        {campaign_list && campaign_list.length > 0 ? (
          <div className="m-t-6 container">
            <div className="m-t-12">
              {campaign_list.map((campaign, index) => (
                <div
                  key={index}
                  onClick={() =>
                    navigateTo(
                      campaign.campaign_data.campaign_id
                    )
                  }
                  className="card-campaign m-t-12"
                >
                  <div className="card-icon-campaign">
                    <img
                      src={
                        campaign.brand_data?.image_id
                          ? Utils.generateBrandLogoUrl(
                              campaign.brand_data?.org_id,
                              campaign.brand_data?.image_id
                            )
                          : Ourlogo
                      }
                      alt="Brand Logo"
                    />
                  </div>
                  <div className="card-content-campaign">
                    <div className="card-title-campaign">
                      <CustomText
                        h5
                        text={Utils.truncateText(campaign?.campaign_data?.name)}
                        className="text-14"
                      />
                    </div>
                    <div className="card-timing-campaign">
                      <CustomText
                        p
                        text={`Ending ${Utils.convertUnixToHumanDate(
                          campaign.campaign_data.end_date
                        )}`}
                      />
                    </div>
                    <div className="card-detals-campaign">
                      <CustomText
                        p
                        text={Utils.getCampaignTypeText(
                          campaign.campaign_data.type
                        )}
                        className="small-txt"
                      />
                      <CustomText
                        p
                        text={
                          campaign.campaign_data.collab_type === "BARTER"
                            ? "CASHBACK"
                            : `${campaign.reward_data.type.toUpperCase()} UPTO Rs ${campaign.campaign_data.max_budget.toFixed(0)}`
                        }
                        className="small-txt"
                      />
                    </div>
                    <div className="card-footer-campaign">
                      <div className="credits-campaign">
                        <span>
                          {renderSocialMediaIcon(campaign.content_type_deep_data)}
                        </span>
                        <span>
                          {!campaign.campaign_data.campaign_credits
                            ? "FREE"
                            : `${campaign.campaign_data.campaign_credits} CREDITS`}
                        </span>
                      </div>
                      <div className="proposals-campaign">
                        {`${campaign.campaign_data.bid_count || 0} PROPOSALS`}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="fallback p-t-12">
            <FallBack
              icon={svgs.folder}
              heading="No Campaign Found"
              title="No campaigns found for this category"
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
  
    <div className='bg-app-gray'>

    <div className="header-fixed flex align-center gap-10" style={{padding : "14px 0"}} >
    <div
      className="cursor-pointer p-t-3" style={{paddingLeft : "10px"}}
      onClick={() => navigate("/")}
    >
      <Icon icon={svgs.back_black_icon} width={13} height={13} />
    </div>
    <div className="m-l-8">
      <CustomText p text={"Campaigns"} />
      <CustomText p text={"Campaigns by Category"} className="text-12 m-t-2" />
    </div>
  </div>
  {loading ? (
    <div className="loader-mid">
      <Loader />
    </div>
  ) : (
    <div className="">
      <div className=" " style={{ paddingTop: "65px" }}>
        {renderCampaignList()}
      </div>
    </div>
  )}
</div>
    </>
  );
}

export default CampaignByCategory;
