import Axios from 'axios';

const HttpRequest = {
  handleAPI: function (
    successCallback,
    errorCallback,
    progressCallback,
    apiParams,
    apiMethod = 'GET',  // Default method is GET
    apiUrl,
    // metaData = {},
    // requestContentType = "application/x-www-form-urlencoded",
    auth = null
  ) 
  
  {
    progressCallback(true);
  
    // Request configuration
    const config = {
      method: apiMethod,
      url: apiUrl,
      data: apiMethod === 'POST' ? apiParams : undefined,
      params: apiMethod === 'GET' ? apiParams : undefined,
      withCredentials: true,  // Ensures cookies are sent with the request
      headers: {
        'Content-Type': "application/x-www-form-urlencoded",
      },
    };
  
    // Add authorization header if provided
    if (auth) {
      config.headers['Authorization'] = `Bearer ${auth}`;
    }
  
    console.log('apiRequest', { apiParams, apiMethod, apiUrl });
  
    Axios(config)
      .then((response) => {
        const { data = {} } = response;
        console.log('Response', data);
  
        progressCallback(false);
  
        // Check if response data indicates success
        if (data.success) {
          successCallback(data);
        } else {
          console.error('API Error:', data.err_l);
          errorCallback(data.err_l || [{ m: 'Unknown error occurred' }]);
        }
      })
      .catch((error) => {
        // Improved error handling for CORS and network errors
        const statusCode = error.response ? error.response.status : 0;
        const errorMessage = error.message || 'Network error or CORS issue';
  
        console.error({
          error: true,
          apiUrl,
          apiParams,
          status: statusCode,
          message: errorMessage,
        });
  
        progressCallback(false);
  
        // Return error message with status code in the errorCallback
        errorCallback([{ m: errorMessage, status: statusCode }]);
      });
  },
  };

export default HttpRequest;