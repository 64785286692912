import React from 'react';

function BtnLoader({ loaderColor }) {
  return (
    <div className="small-loader">
      <div
        style={{
          borderColor: `${loaderColor} transparent transparent transparent`, // Use the loaderColor prop
        }}
      ></div>
      <div
        style={{
          borderColor: `${loaderColor} transparent transparent transparent`, // Ensure all parts are colored
        }}
      ></div>
      <div
        style={{
          borderColor: `${loaderColor} transparent transparent transparent`,
        }}
      ></div>
      <div
        style={{
          borderColor: `${loaderColor} transparent transparent transparent`,
        }}
      ></div>
    </div>
  );
}

export default BtnLoader;
