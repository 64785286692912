// Campaign Type
module.exports = {
  // Constraints on Brand ID
  CAMPAIGN_ID_MIN_LENGTH: 4, // Fixed Length
  CAMPAIGN_ID_MAX_LENGTH: 14, // Fixed Length
  CAMPAIGN_ID_CHARSET: `0123456789abcdefghijklmnopqrstuvwxyz`, // Valid charset. Digits, Lowercase Alphabets
  CAMPAIGN_ID_SANATIZE_REGX: /[^0-9a-z]/g, // Regular expression for valid Characters. Digits. Alphabets. Case sensitive
  CAMPAIGN_ID_CHARSET_REGX: /^[0-9a-z]*$/, // Regular expression for valid Characters. Digits. Alphabets. Case sensitive

  // Constraints on Brand Name
  CAMPAIGN_NAME_MAX_LENGTH: 255, // Maximum length

  CAMPAIGN_LIST_LIMIT_EDITOR: 10,

  USER_TYPE: {
    brand_manager: {
      n: 'Brand',
    },
    agency_manager: {
      n: 'An Agency',
    },
  },

  CAMPAIGN_TYPE: {
    content: {
      n: 'Content',
    },
    social_media: {
      n: 'Social Media',
    },
  },

  // Campaign Type
  CAMPAIGN_STATUS: {
    save: 'sv',
    active: 'at',
    expired: 'ex',
    complete: 'ct',
  },

  // Platforms
  PLATFORM: {
    instagram: {
      n: 'Instagram',
      content_types: {
        posts: {
          n: 'Instagram Posts',
        },
        carousels: {
          n: 'Instagram Carousels',
        },
        stories: {
          n: 'Instagram Stories',
        },
      },
    },
    youtube: {
      n: 'Youtube',
      content_types: {
        video: {
          n: 'Youtube Video',
        },
        shorts: {
          n: 'Youtube Shorts',
        },
      },
    },
    twitter: {
      n: 'Twitter',
      content_types: {
        post: {
          n: 'Twitter Posts',
        },
      },
    },
    facebook: {
      n: 'Facebook',
      content_types: {
        post: {
          n: 'Facebook Posts',
        },
      },
    },
  },

  AGE_RANGE: {
    '18_25': {
      n: '18 - 25',
      id: '18_25',
    },
    '26_35': {
      n: '26 - 35',
      id: '26_35',
    },
    '36_45': {
      n: '36 - 45',
      id: '36_45',
    },
    '46_55': {
      n: '46 - 55',
      id: '46_55',
    },
    '56_65+': {
      n: '56 - 65+',
      id: '56_65+',
    },
  },

  // GENDER
  GENDER: {
    all: {
      label: 'Other',
      value: 'all',
    },
    male: {
      label: 'Male',
      value: 'male',
    },
    female: {
      label: 'Female',
      value: 'female',
    },
  },

  // GENDER
  GENDERS: [
    {
      label: 'Male',
      value: 'male',
    },
    {
      label: 'Female',
      value: 'female',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],

  // GENDER
  LOCATIONS: {
    an: {
      n: 'Andaman and Nicobar Islands',
      id: 'an',
    },
    ap: {
      n: 'Andhra Pradesh',
      id: 'ap',
    },
    ar: {
      n: 'Arunachal Pradesh',
      id: 'ar',
    },
    as: {
      n: 'Assam',
      id: 'as',
    },
    br: {
      n: 'Bihar',
      id: 'br',
    },
    ch: {
      n: 'Chandigarh',
      id: 'ch',
    },
    ct: {
      n: 'Chhattisgarh',
      id: 'ct',
    },
    dn: {
      n: 'Dadra and Nagar Haveli',
      id: 'dn',
    },
    dd: {
      n: 'Daman and Diu',
      id: 'dd',
    },
    dl: {
      n: 'Delhi',
      id: 'dl',
    },
    ga: {
      n: 'Goa',
      id: 'ga',
    },
    gj: {
      n: 'Gujarat',
      id: 'gj',
    },
    hr: {
      n: 'Haryana',
      id: 'hr',
    },
    hp: {
      n: 'Himachal Pradesh',
      id: 'hp',
    },
    jk: {
      n: 'Jammu and Kashmir',
      id: 'jk',
    },
    jh: {
      n: 'Jharkhand',
      id: 'jh',
    },
    ka: {
      n: 'Karnataka',
      id: 'ka',
    },
    kl: {
      n: 'Kerala',
      id: 'kl',
    },
    ld: {
      n: 'Lakshadweep',
      id: 'ld',
    },
    mp: {
      n: 'Madhya Pradesh',
      id: 'mp',
    },
    mh: {
      n: 'Maharashtra',
      id: 'mh',
    },
    mn: {
      n: 'Manipur',
      id: 'mn',
    },
    ml: {
      n: 'Meghalaya',
      id: 'ml',
    },
    mz: {
      n: 'Mizoram',
      id: 'mz',
    },
    nl: {
      n: 'Nagaland',
      id: 'nl',
    },
    or: {
      n: 'Odisha',
      id: 'or',
    },
    py: {
      n: 'Puducherry',
      id: 'py',
    },
    pb: {
      n: 'Punjab',
      id: 'pb',
    },
    rj: {
      n: 'Rajasthan',
      id: 'rj',
    },
    sk: {
      n: 'Sikkim',
      id: 'sk',
    },
    tn: {
      n: 'Tamil Nadu',
      id: 'tn',
    },
    tg: {
      n: 'Telangana',
      id: 'tg',
    },
    tr: {
      n: 'Tripura',
      id: 'tr',
    },
    up: {
      n: 'Uttar Pradesh',
      id: 'up',
    },
    ut: {
      n: 'Uttarakhand',
      id: 'ut',
    },
    wb: {
      n: 'West Bengal',
      id: 'wb',
    },
  },

  LOCATION: {
    an: {
      label: 'Andaman and Nicobar Islands',
      value: 'an',
    },
    ap: {
      label: 'Andhra Pradesh',
      value: 'ap',
    },
    ar: {
      label: 'Arunachal Pradesh',
      value: 'ar',
    },
    as: {
      label: 'Assam',
      value: 'as',
    },
    br: {
      label: 'Bihar',
      value: 'br',
    },
    ch: {
      label: 'Chandigarh',
      value: 'ch',
    },
    ct: {
      label: 'Chhattisgarh',
      value: 'ct',
    },
    dn: {
      label: 'Dadra and Nagar Haveli',
      value: 'dn',
    },
    dd: {
      label: 'Daman and Diu',
      value: 'dd',
    },
    dl: {
      label: 'Delhi',
      value: 'dl',
    },
    ga: {
      n: 'Goa',
      value: 'Goa',
    },
    gj: {
      label: 'Gujarat',
      value: 'gj',
    },
    hr: {
      label: 'Haryana',
      value: 'hr',
    },
    hp: {
      label: 'Himachal Pradesh',
      value: 'hp',
    },
    jk: {
      label: 'Jammu and Kashmir',
      value: 'jk',
    },
    jh: {
      label: 'Jharkhand',
      value: 'jh',
    },
    ka: {
      label: 'Karnataka',
      value: 'ka',
    },
    kl: {
      label: 'Kerala',
      value: 'kl',
    },
    ld: {
      label: 'Lakshadweep',
      value: 'ld',
    },
    mp: {
      label: 'Madhya Pradesh',
      value: 'mp',
    },
    mh: {
      label: 'Maharashtra',
      value: 'mh',
    },
    mn: {
      label: 'Manipur',
      value: 'mn',
    },
    ml: {
      label: 'Meghalaya',
      value: 'ml',
    },
    mz: {
      label: 'Mizoram',
      value: 'mz',
    },
    nl: {
      label: 'Nagaland',
      value: 'nl',
    },
    or: {
      label: 'Odisha',
      value: 'or',
    },
    py: {
      label: 'Puducherry',
      value: 'py',
    },
    pb: {
      label: 'Punjab',
      value: 'pb',
    },
    rj: {
      label: 'Rajasthan',
      value: 'rj',
    },
    sk: {
      label: 'Sikkim',
      value: 'sk',
    },
    tn: {
      label: 'Tamil Nadu',
      value: 'tn',
    },
    tg: {
      label: 'Telangana',
      value: 'tg',
    },
    tr: {
      label: 'Tripura',
      value: 'tr',
    },
    up: {
      label: 'Uttar Pradesh',
      value: 'up',
    },
    ut: {
      label: 'Uttarakhand',
      value: 'ut',
    },
    wb: {
      label: 'West Bengal',
      value: 'wb',
    },
  },
  CITIES: [
    {
      type: 'state',
      label: 'Maharashtra',
      id: 10002,
    },
    {
      type: 'state',
      label: 'Delhi',
      id: 10003,
    },
    {
      type: 'state',
      label: 'Karnataka',
      id: 10004,
    },
    {
      type: 'state',
      label: 'Gujarat',
      id: 10005,
    },
    {
      type: 'state',
      label: 'Telangana',
      id: 10006,
    },
    {
      type: 'state',
      label: 'Tamil Nadu',
      id: 10007,
    },
    {
      type: 'state',
      label: 'West Bengal',
      id: 10008,
    },
    {
      type: 'state',
      label: 'Rajasthan',
      id: 10009,
    },
    {
      type: 'state',
      label: 'Uttar Pradesh',
      id: 10010,
    },
    {
      type: 'state',
      label: 'Bihar',
      id: 10011,
    },
    {
      type: 'state',
      label: 'Madhya Pradesh',
      id: 10012,
    },
    {
      type: 'state',
      label: 'Andhra Pradesh',
      id: 10013,
    },
    {
      type: 'state',
      label: 'Punjab',
      id: 10014,
    },
    {
      type: 'state',
      label: 'Haryana',
      id: 10015,
    },
    {
      type: 'state',
      label: 'Jammu and Kashmir',
      id: 10016,
    },
    {
      type: 'state',
      label: 'Jharkhand',
      id: 10017,
    },
    {
      type: 'state',
      label: 'Chhattisgarh',
      id: 10018,
    },
    {
      type: 'state',
      label: 'Assam',
      id: 10019,
    },
    {
      type: 'state',
      label: 'Chandigarh',
      id: 10020,
    },
    {
      type: 'state',
      label: 'Odisha',
      id: 10021,
    },
    {
      type: 'state',
      label: 'Kerala',
      id: 10022,
    },
    {
      type: 'state',
      label: 'Uttarakhand',
      id: 10023,
    },
    {
      type: 'state',
      label: 'Puducherry',
      id: 10024,
    },
    {
      type: 'state',
      label: 'Tripura',
      id: 10025,
    },
    {
      type: 'state',
      label: 'Mizoram',
      id: 10026,
    },
    {
      type: 'state',
      label: 'Meghalaya',
      id: 10027,
    },
    {
      type: 'state',
      label: 'Manipur',
      id: 10028,
    },
    {
      type: 'state',
      label: 'Himachal Pradesh',
      id: 10029,
    },
    {
      type: 'state',
      label: 'Nagaland',
      id: 10030,
    },
    {
      type: 'state',
      label: 'Goa',
      id: 10031,
    },
    {
      type: 'state',
      label: 'Andaman and Nicobar Islands',
      id: 10032,
    },
    {
      type: 'state',
      label: 'Arunachal Pradesh',
      id: 10033,
    },
    {
      type: 'state',
      label: 'Dadra and Nagar Haveli',
      id: 10034,
    },
    {
      type: 'state',
      label: 'Himachal Praddesh',
      id: 10035,
    },
    {
      type: 'city',
      label: 'Mumbai',
      id: 10036,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Delhi',
      id: 10037,
      state_id: 10003,
    },
    {
      type: 'city',
      label: 'Bengaluru',
      id: 10038,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Ahmedabad',
      id: 10039,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Hyderabad',
      id: 10040,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Chennai',
      id: 10041,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Kolkata',
      id: 10042,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Pune',
      id: 10043,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Jaipur',
      id: 10044,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Surat',
      id: 10045,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Lucknow',
      id: 10046,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Kanpur',
      id: 10047,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nagpur',
      id: 10048,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Patna',
      id: 10049,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Indore',
      id: 10050,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Thane',
      id: 10051,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Bhopal',
      id: 10052,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Visakhapatnam',
      id: 10053,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Vadodara',
      id: 10054,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Firozabad',
      id: 10055,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Ludhiana',
      id: 10056,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Rajkot',
      id: 10057,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Agra',
      id: 10058,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Siliguri',
      id: 10059,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Nashik',
      id: 10060,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Faridabad',
      id: 10061,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Patiala',
      id: 10062,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Meerut',
      id: 10063,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Kalyan-Dombivali',
      id: 10064,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Vasai-Virar',
      id: 10065,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Varanasi',
      id: 10066,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Srinagar',
      id: 10067,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Dhanbad',
      id: 10068,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Jodhpur',
      id: 10069,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Amritsar',
      id: 10070,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Raipur',
      id: 10071,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Allahabad',
      id: 10072,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Coimbatore',
      id: 10073,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Jabalpur',
      id: 10074,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Gwalior',
      id: 10075,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Vijayawada',
      id: 10076,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Madurai',
      id: 10077,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Guwahati',
      id: 10078,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Chandigarh',
      id: 10079,
      state_id: 10020,
    },
    {
      type: 'city',
      label: 'Hubli-Dharwad',
      id: 10080,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Amroha',
      id: 10081,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Moradabad',
      id: 10082,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Gurgaon',
      id: 10083,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Aligarh',
      id: 10084,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Solapur',
      id: 10085,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Ranchi',
      id: 10086,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Jalandhar',
      id: 10087,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Tiruchirappalli',
      id: 10088,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Bhubaneswar',
      id: 10089,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Salem',
      id: 10090,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Warangal',
      id: 10091,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Mira-Bhayandar',
      id: 10092,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Thiruvananthapuram',
      id: 10093,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Bhiwandi',
      id: 10094,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Saharanpur',
      id: 10095,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Guntur',
      id: 10096,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Amravati',
      id: 10097,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Bikaner',
      id: 10098,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Noida',
      id: 10099,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Jamshedpur',
      id: 10100,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Bhilai Nagar',
      id: 10101,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Cuttack',
      id: 10102,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Kochi',
      id: 10103,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Udaipur',
      id: 10104,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Bhavnagar',
      id: 10105,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Dehradun',
      id: 10106,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Asansol',
      id: 10107,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Nanded-Waghala',
      id: 10108,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Ajmer',
      id: 10109,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Jamnagar',
      id: 10110,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Ujjain',
      id: 10111,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Sangli',
      id: 10112,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Loni',
      id: 10113,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Jhansi',
      id: 10114,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Pondicherry',
      id: 10115,
      state_id: 10024,
    },
    {
      type: 'city',
      label: 'Nellore',
      id: 10116,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Jammu',
      id: 10117,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Belagavi',
      id: 10118,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Raurkela',
      id: 10119,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Mangaluru',
      id: 10120,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Tirunelveli',
      id: 10121,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Malegaon',
      id: 10122,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Gaya',
      id: 10123,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Tiruppur',
      id: 10124,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Davanagere',
      id: 10125,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Kozhikode',
      id: 10126,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Akola',
      id: 10127,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Kurnool',
      id: 10128,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Bokaro Steel City',
      id: 10129,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Rajahmundry',
      id: 10130,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Ballari',
      id: 10131,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Agartala',
      id: 10132,
      state_id: 10025,
    },
    {
      type: 'city',
      label: 'Bhagalpur',
      id: 10133,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Latur',
      id: 10134,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Dhule',
      id: 10135,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Korba',
      id: 10136,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Bhilwara',
      id: 10137,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Brahmapur',
      id: 10138,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Mysore',
      id: 10139,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Muzaffarpur',
      id: 10140,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Ahmednagar',
      id: 10141,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Kollam',
      id: 10142,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Raghunathganj',
      id: 10143,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Bilaspur',
      id: 10144,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Shahjahanpur',
      id: 10145,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Thrissur',
      id: 10146,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Alwar',
      id: 10147,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Kakinada',
      id: 10148,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nizamabad',
      id: 10149,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Sagar',
      id: 10150,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Tumkur',
      id: 10151,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Hisar',
      id: 10152,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Rohtak',
      id: 10153,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Panipat',
      id: 10154,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Darbhanga',
      id: 10155,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Kharagpur',
      id: 10156,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Aizawl',
      id: 10157,
      state_id: 10026,
    },
    {
      type: 'city',
      label: 'Ichalkaranji',
      id: 10158,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Tirupati',
      id: 10159,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Karnal',
      id: 10160,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Bathinda',
      id: 10161,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Rampur',
      id: 10162,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Shivamogga',
      id: 10163,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Ratlam',
      id: 10164,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Modinagar',
      id: 10165,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Durg',
      id: 10166,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Shillong',
      id: 10167,
      state_id: 10027,
    },
    {
      type: 'city',
      label: 'Imphal',
      id: 10168,
      state_id: 10028,
    },
    {
      type: 'city',
      label: 'Hapur',
      id: 10169,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Ranipet',
      id: 10170,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Anantapur',
      id: 10171,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Arrah',
      id: 10172,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Karimnagar',
      id: 10173,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Parbhani',
      id: 10174,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Etawah',
      id: 10175,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Bharatpur',
      id: 10176,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Begusarai',
      id: 10177,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'New Delhi',
      id: 10178,
      state_id: 10003,
    },
    {
      type: 'city',
      label: 'Chhapra',
      id: 10179,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Kadapa',
      id: 10180,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Ramagundam',
      id: 10181,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Pali',
      id: 10182,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Satna',
      id: 10183,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Vizianagaram',
      id: 10184,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Katihar',
      id: 10185,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Hardwar',
      id: 10186,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Sonipat',
      id: 10187,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Nagercoil',
      id: 10188,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Thanjavur',
      id: 10189,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Murwara (Katni)',
      id: 10190,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Naihati',
      id: 10191,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Sambhal',
      id: 10192,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nadiad',
      id: 10193,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Yamunanagar',
      id: 10194,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'English Bazar',
      id: 10195,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Eluru',
      id: 10196,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Munger',
      id: 10197,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Panchkula',
      id: 10198,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Raayachuru',
      id: 10199,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Panvel',
      id: 10200,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Deoghar',
      id: 10201,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Ongole',
      id: 10202,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nandyal',
      id: 10203,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Morena',
      id: 10204,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Bhiwani',
      id: 10205,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Porbandar',
      id: 10206,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Palakkad',
      id: 10207,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Anand',
      id: 10208,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Purnia',
      id: 10209,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Baharampur',
      id: 10210,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Barmer',
      id: 10211,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Morvi',
      id: 10212,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Orai',
      id: 10213,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Bahraich',
      id: 10214,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sikar',
      id: 10215,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Vellore',
      id: 10216,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Singrauli',
      id: 10217,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Khammam',
      id: 10218,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Mahesana',
      id: 10219,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Silchar',
      id: 10220,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Sambalpur',
      id: 10221,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Rewa',
      id: 10222,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Unnao',
      id: 10223,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Hugli-Chinsurah',
      id: 10224,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Raiganj',
      id: 10225,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Phusro',
      id: 10226,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Adityapur',
      id: 10227,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Alappuzha',
      id: 10228,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Bahadurgarh',
      id: 10229,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Machilipatnam',
      id: 10230,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Rae Bareli',
      id: 10231,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Jalpaiguri',
      id: 10232,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Bharuch',
      id: 10233,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Pathankot',
      id: 10234,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Hoshiarpur',
      id: 10235,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Baramula',
      id: 10236,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Adoni',
      id: 10237,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Jind',
      id: 10238,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Tonk',
      id: 10239,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Tenali',
      id: 10240,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Kancheepuram',
      id: 10241,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Vapi',
      id: 10242,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Sirsa',
      id: 10243,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Navsari',
      id: 10244,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Mahbubnagar',
      id: 10245,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Puri',
      id: 10246,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Robertson Pet',
      id: 10247,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Erode',
      id: 10248,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Batala',
      id: 10249,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Haldwani-cum-Kathgodam',
      id: 10250,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Vidisha',
      id: 10251,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Saharsa',
      id: 10252,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Thanesar',
      id: 10253,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Chittoor',
      id: 10254,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Veraval',
      id: 10255,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Lakhimpur',
      id: 10256,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sitapur',
      id: 10257,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Hindupur',
      id: 10258,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Santipur',
      id: 10259,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Balurghat',
      id: 10260,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Ganjbasoda',
      id: 10261,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Moga',
      id: 10262,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Proddatur',
      id: 10263,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Srinagar',
      id: 10264,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Medinipur',
      id: 10265,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Habra',
      id: 10266,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Sasaram',
      id: 10267,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Hajipur',
      id: 10268,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Bhuj',
      id: 10269,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Shivpuri',
      id: 10270,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Ranaghat',
      id: 10271,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Shimla',
      id: 10272,
      state_id: 10029,
    },
    {
      type: 'city',
      label: 'Tiruvannamalai',
      id: 10273,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Kaithal',
      id: 10274,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Rajnandgaon',
      id: 10275,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Godhra',
      id: 10276,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Hazaribag',
      id: 10277,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Bhimavaram',
      id: 10278,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Mandsaur',
      id: 10279,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Dibrugarh',
      id: 10280,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Kolar',
      id: 10281,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Bankura',
      id: 10282,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Mandya',
      id: 10283,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Dehri-on-Sone',
      id: 10284,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Madanapalle',
      id: 10285,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Malerkotla',
      id: 10286,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Lalitpur',
      id: 10287,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Bettiah',
      id: 10288,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Pollachi',
      id: 10289,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Khanna',
      id: 10290,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Neemuch',
      id: 10291,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Palwal',
      id: 10292,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Palanpur',
      id: 10293,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Guntakal',
      id: 10294,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nabadwip',
      id: 10295,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Udupi',
      id: 10296,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Jagdalpur',
      id: 10297,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Motihari',
      id: 10298,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Pilibhit',
      id: 10299,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Dimapur',
      id: 10300,
      state_id: 10030,
    },
    {
      type: 'city',
      label: 'Mohali',
      id: 10301,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Sadulpur',
      id: 10302,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Rajapalayam',
      id: 10303,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Dharmavaram',
      id: 10304,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Kashipur',
      id: 10305,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Sivakasi',
      id: 10306,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Darjiling',
      id: 10307,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Chikkamagaluru',
      id: 10308,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Gudivada',
      id: 10309,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Baleshwar Town',
      id: 10310,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Mancherial',
      id: 10311,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Srikakulam',
      id: 10312,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Adilabad',
      id: 10313,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Yavatmal',
      id: 10314,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Barnala',
      id: 10315,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Nagaon',
      id: 10316,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Narasaraopet',
      id: 10317,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Raigarh',
      id: 10318,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Roorkee',
      id: 10319,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Valsad',
      id: 10320,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Ambikapur',
      id: 10321,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Giridih',
      id: 10322,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Chandausi',
      id: 10323,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Purulia',
      id: 10324,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Patan',
      id: 10325,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Bagaha',
      id: 10326,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Hardoi ',
      id: 10327,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Achalpur',
      id: 10328,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Osmanabad',
      id: 10329,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Deesa',
      id: 10330,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Nandurbar',
      id: 10331,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Azamgarh',
      id: 10332,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Ramgarh',
      id: 10333,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Firozpur',
      id: 10334,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Baripada Town',
      id: 10335,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Karwar',
      id: 10336,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Siwan',
      id: 10337,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Rajampet',
      id: 10338,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Pudukkottai',
      id: 10339,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Anantnag',
      id: 10340,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Tadpatri',
      id: 10341,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Satara',
      id: 10342,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Bhadrak',
      id: 10343,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Kishanganj',
      id: 10344,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Suryapet',
      id: 10345,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Wardha',
      id: 10346,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Ranebennuru',
      id: 10347,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Amreli',
      id: 10348,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Neyveli (TS)',
      id: 10349,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Jamalpur',
      id: 10350,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Marmagao',
      id: 10351,
      state_id: 10031,
    },
    {
      type: 'city',
      label: 'Udgir',
      id: 10352,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Tadepalligudem',
      id: 10353,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nagapattinam',
      id: 10354,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Buxar',
      id: 10355,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Aurangabad',
      id: 10356,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Jehanabad',
      id: 10357,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Phagwara',
      id: 10358,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Khair',
      id: 10359,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sawai Madhopur',
      id: 10360,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Kapurthala',
      id: 10361,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Chilakaluripet',
      id: 10362,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Aurangabad',
      id: 10363,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Malappuram',
      id: 10364,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Rewari',
      id: 10365,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Nagaur',
      id: 10366,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sultanpur',
      id: 10367,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nagda',
      id: 10368,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Port Blair',
      id: 10369,
      state_id: 10032,
    },
    {
      type: 'city',
      label: 'Lakhisarai',
      id: 10370,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Panaji',
      id: 10371,
      state_id: 10031,
    },
    {
      type: 'city',
      label: 'Tinsukia',
      id: 10372,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Itarsi',
      id: 10373,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Kohima',
      id: 10374,
      state_id: 10030,
    },
    {
      type: 'city',
      label: 'Balangir',
      id: 10375,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Nawada',
      id: 10376,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Jharsuguda',
      id: 10377,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Jagtial',
      id: 10378,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Viluppuram',
      id: 10379,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Amalner',
      id: 10380,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Zirakpur',
      id: 10381,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Tanda',
      id: 10382,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Tiruchengode',
      id: 10383,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Nagina',
      id: 10384,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Yemmiganur',
      id: 10385,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Vaniyambadi',
      id: 10386,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sarni',
      id: 10387,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Theni Allinagaram',
      id: 10388,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Margao',
      id: 10389,
      state_id: 10031,
    },
    {
      type: 'city',
      label: 'Akot',
      id: 10390,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sehore',
      id: 10391,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Mhow Cantonment',
      id: 10392,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Kot Kapura',
      id: 10393,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Makrana',
      id: 10394,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Pandharpur',
      id: 10395,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Miryalaguda',
      id: 10396,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Shamli',
      id: 10397,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Seoni',
      id: 10398,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Ranibennur',
      id: 10399,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Kadiri',
      id: 10400,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Shrirampur',
      id: 10401,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Rudrapur',
      id: 10402,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Parli',
      id: 10403,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Najibabad',
      id: 10404,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nirmal',
      id: 10405,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Udhagamandalam',
      id: 10406,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Shikohabad',
      id: 10407,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Jhumri Tilaiya',
      id: 10408,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Aruppukkottai',
      id: 10409,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Ponnani',
      id: 10410,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Jamui',
      id: 10411,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sitamarhi',
      id: 10412,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Chirala',
      id: 10413,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Anjar',
      id: 10414,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Karaikal',
      id: 10415,
      state_id: 10024,
    },
    {
      type: 'city',
      label: 'Hansi',
      id: 10416,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Anakapalle',
      id: 10417,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Mahasamund',
      id: 10418,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Faridkot',
      id: 10419,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Saunda',
      id: 10420,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Dhoraji',
      id: 10421,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Paramakudi',
      id: 10422,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Balaghat',
      id: 10423,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Sujangarh',
      id: 10424,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Khambhat',
      id: 10425,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Muktsar',
      id: 10426,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Rajpura',
      id: 10427,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Kavali',
      id: 10428,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Dhamtari',
      id: 10429,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Ashok Nagar',
      id: 10430,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Sardarshahar',
      id: 10431,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Mahuva',
      id: 10432,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Bargarh',
      id: 10433,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Kamareddy',
      id: 10434,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Sahibganj',
      id: 10435,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Kothagudem',
      id: 10436,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Ramanagaram',
      id: 10437,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Gokak',
      id: 10438,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Tikamgarh',
      id: 10439,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Araria',
      id: 10440,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Rishikesh',
      id: 10441,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Shahdol',
      id: 10442,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Medininagar (Daltonganj)',
      id: 10443,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Arakkonam',
      id: 10444,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Washim',
      id: 10445,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sangrur',
      id: 10446,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Bodhan',
      id: 10447,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Fazilka',
      id: 10448,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Palacole',
      id: 10449,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Keshod',
      id: 10450,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Sullurpeta',
      id: 10451,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Wadhwan',
      id: 10452,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Gurdaspur',
      id: 10453,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Vatakara',
      id: 10454,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Tura',
      id: 10455,
      state_id: 10027,
    },
    {
      type: 'city',
      label: 'Narnaul',
      id: 10456,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Kharar',
      id: 10457,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Yadgir',
      id: 10458,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Ambejogai',
      id: 10459,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Ankleshwar',
      id: 10460,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Savarkundla',
      id: 10461,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Paradip',
      id: 10462,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Virudhachalam',
      id: 10463,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Kanhangad',
      id: 10464,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Kadi',
      id: 10465,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Srivilliputhur',
      id: 10466,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Gobindgarh',
      id: 10467,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Tindivanam',
      id: 10468,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mansa',
      id: 10469,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Taliparamba',
      id: 10470,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Manmad',
      id: 10471,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Tanuku',
      id: 10472,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Rayachoti',
      id: 10473,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Virudhunagar',
      id: 10474,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Koyilandy',
      id: 10475,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Jorhat',
      id: 10476,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Karur',
      id: 10477,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Valparai',
      id: 10478,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Srikalahasti',
      id: 10479,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Neyyattinkara',
      id: 10480,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Bapatla',
      id: 10481,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Fatehabad',
      id: 10482,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Malout',
      id: 10483,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Sankarankovil',
      id: 10484,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Tenkasi',
      id: 10485,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Ratnagiri',
      id: 10486,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Rabkavi Banhatti',
      id: 10487,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sikandrabad',
      id: 10488,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Chaibasa',
      id: 10489,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Chirmiri',
      id: 10490,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Palwancha',
      id: 10491,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Bhawanipatna',
      id: 10492,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Kayamkulam',
      id: 10493,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Pithampur',
      id: 10494,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Nabha',
      id: 10495,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Shahabad, Hardoi',
      id: 10496,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Dhenkanal',
      id: 10497,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Uran Islampur',
      id: 10498,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Gopalganj',
      id: 10499,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Bongaigaon City',
      id: 10500,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Palani',
      id: 10501,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Pusad',
      id: 10502,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sopore',
      id: 10503,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Pilkhuwa',
      id: 10504,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Tarn Taran',
      id: 10505,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Renukoot',
      id: 10506,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Mandamarri',
      id: 10507,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Shahabad',
      id: 10508,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Barbil',
      id: 10509,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Koratla',
      id: 10510,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Madhubani',
      id: 10511,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Arambagh',
      id: 10512,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Gohana',
      id: 10513,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Ladnu',
      id: 10514,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Pattukkottai',
      id: 10515,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sirsi',
      id: 10516,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sircilla',
      id: 10517,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Tamluk',
      id: 10518,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Jagraon',
      id: 10519,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'AlipurdUrban Agglomerationr',
      id: 10520,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Alirajpur',
      id: 10521,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Tandur',
      id: 10522,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Naidupet',
      id: 10523,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Tirupathur',
      id: 10524,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Tohana',
      id: 10525,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Ratangarh',
      id: 10526,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Dhubri',
      id: 10527,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Masaurhi',
      id: 10528,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Visnagar',
      id: 10529,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Vrindavan',
      id: 10530,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nokha',
      id: 10531,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Nagari',
      id: 10532,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Narwana',
      id: 10533,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Ramanathapuram',
      id: 10534,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Ujhani',
      id: 10535,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Samastipur',
      id: 10536,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Laharpur',
      id: 10537,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sangamner',
      id: 10538,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Nimbahera',
      id: 10539,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Siddipet',
      id: 10540,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Suri',
      id: 10541,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Diphu',
      id: 10542,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Jhargram',
      id: 10543,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Shirpur-Warwade',
      id: 10544,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Tilhar',
      id: 10545,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sindhnur',
      id: 10546,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Udumalaipettai',
      id: 10547,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Malkapur',
      id: 10548,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Wanaparthy',
      id: 10549,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Gudur',
      id: 10550,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Kendujhar',
      id: 10551,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Mandla',
      id: 10552,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Mandi',
      id: 10553,
      state_id: 10029,
    },
    {
      type: 'city',
      label: 'Nedumangad',
      id: 10554,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'North Lakhimpur',
      id: 10555,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Vinukonda',
      id: 10556,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Tiptur',
      id: 10557,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Gobichettipalayam',
      id: 10558,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sunabeda',
      id: 10559,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Wani',
      id: 10560,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Upleta',
      id: 10561,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Narasapuram',
      id: 10562,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nuzvid',
      id: 10563,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Tezpur',
      id: 10564,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Una',
      id: 10565,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Markapur',
      id: 10566,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Sheopur',
      id: 10567,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Thiruvarur',
      id: 10568,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sidhpur',
      id: 10569,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Sahaswan',
      id: 10570,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Suratgarh',
      id: 10571,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Shajapur',
      id: 10572,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Rayagada',
      id: 10573,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Lonavla',
      id: 10574,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Ponnur',
      id: 10575,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Kagaznagar',
      id: 10576,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Gadwal',
      id: 10577,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Bhatapara',
      id: 10578,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Kandukur',
      id: 10579,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Sangareddy',
      id: 10580,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Unjha',
      id: 10581,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Lunglei',
      id: 10582,
      state_id: 10026,
    },
    {
      type: 'city',
      label: 'Karimganj',
      id: 10583,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Kannur',
      id: 10584,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Bobbili',
      id: 10585,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Mokameh',
      id: 10586,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Talegaon Dabhade',
      id: 10587,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Anjangaon',
      id: 10588,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Mangrol',
      id: 10589,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Sunam',
      id: 10590,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Gangarampur',
      id: 10591,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Thiruvallur',
      id: 10592,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Tirur',
      id: 10593,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Rath',
      id: 10594,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Jatani',
      id: 10595,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Viramgam',
      id: 10596,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Rajsamand',
      id: 10597,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Yanam',
      id: 10598,
      state_id: 10024,
    },
    {
      type: 'city',
      label: 'Kottayam',
      id: 10599,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Panruti',
      id: 10600,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Dhuri',
      id: 10601,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Namakkal',
      id: 10602,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Kasaragod',
      id: 10603,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Modasa',
      id: 10604,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Rayadurg',
      id: 10605,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Supaul',
      id: 10606,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Kunnamkulam',
      id: 10607,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Umred',
      id: 10608,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Bellampalle',
      id: 10609,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Sibsagar',
      id: 10610,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Mandi Dabwali',
      id: 10611,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Ottappalam',
      id: 10612,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Dumraon',
      id: 10613,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Samalkot',
      id: 10614,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Jaggaiahpet',
      id: 10615,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Goalpara',
      id: 10616,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Tuni',
      id: 10617,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Lachhmangarh',
      id: 10618,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Bhongir',
      id: 10619,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Amalapuram',
      id: 10620,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Firozpur Cantt.',
      id: 10621,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Vikarabad',
      id: 10622,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Thiruvalla',
      id: 10623,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Sherkot',
      id: 10624,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Palghar',
      id: 10625,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Shegaon',
      id: 10626,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Jangaon',
      id: 10627,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Bheemunipatnam',
      id: 10628,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Panna',
      id: 10629,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Thodupuzha',
      id: 10630,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'KathUrban Agglomeration',
      id: 10631,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Palitana',
      id: 10632,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Arwal',
      id: 10633,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Venkatagiri',
      id: 10634,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Kalpi',
      id: 10635,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Rajgarh (Churu)',
      id: 10636,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sattenapalle',
      id: 10637,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Arsikere',
      id: 10638,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Ozar',
      id: 10639,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Thirumangalam',
      id: 10640,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Petlad',
      id: 10641,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Nasirabad',
      id: 10642,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Phaltan',
      id: 10643,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Rampurhat',
      id: 10644,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Nanjangud',
      id: 10645,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Forbesganj',
      id: 10646,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Tundla',
      id: 10647,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'BhabUrban Agglomeration',
      id: 10648,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sagara',
      id: 10649,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Pithapuram',
      id: 10650,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Sira',
      id: 10651,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Bhadrachalam',
      id: 10652,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Charkhi Dadri',
      id: 10653,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Chatra',
      id: 10654,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Palasa Kasibugga',
      id: 10655,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nohar',
      id: 10656,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Yevla',
      id: 10657,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sirhind Fatehgarh Sahib',
      id: 10658,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Bhainsa',
      id: 10659,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Parvathipuram',
      id: 10660,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Shahade',
      id: 10661,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Chalakudy',
      id: 10662,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Narkatiaganj',
      id: 10663,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Kapadvanj',
      id: 10664,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Macherla',
      id: 10665,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Raghogarh-Vijaypur',
      id: 10666,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Rupnagar',
      id: 10667,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Naugachhia',
      id: 10668,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sendhwa',
      id: 10669,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Byasanagar',
      id: 10670,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Sandila',
      id: 10671,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Gooty',
      id: 10672,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Salur',
      id: 10673,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nanpara',
      id: 10674,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sardhana',
      id: 10675,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Vita',
      id: 10676,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Gumia',
      id: 10677,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Puttur',
      id: 10678,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Jalandhar Cantt.',
      id: 10679,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Nehtaur',
      id: 10680,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Changanassery',
      id: 10681,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Mandapeta',
      id: 10682,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Dumka',
      id: 10683,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Seohara',
      id: 10684,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Umarkhed',
      id: 10685,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Madhupur',
      id: 10686,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Vikramasingapuram',
      id: 10687,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Punalur',
      id: 10688,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Kendrapara',
      id: 10689,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Sihor',
      id: 10690,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Nellikuppam',
      id: 10691,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Samana',
      id: 10692,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Warora',
      id: 10693,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Nilambur',
      id: 10694,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Rasipuram',
      id: 10695,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Ramnagar',
      id: 10696,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Jammalamadugu',
      id: 10697,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nawanshahr',
      id: 10698,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Thoubal',
      id: 10699,
      state_id: 10028,
    },
    {
      type: 'city',
      label: 'Athni',
      id: 10700,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Cherthala',
      id: 10701,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Sidhi',
      id: 10702,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Farooqnagar',
      id: 10703,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Peddapuram',
      id: 10704,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Chirkunda',
      id: 10705,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Pachora',
      id: 10706,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Madhepura',
      id: 10707,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Pithoragarh',
      id: 10708,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Tumsar',
      id: 10709,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Phalodi',
      id: 10710,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Tiruttani',
      id: 10711,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Rampura Phul',
      id: 10712,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Perinthalmanna',
      id: 10713,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Padrauna',
      id: 10714,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Pipariya',
      id: 10715,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Dalli-Rajhara',
      id: 10716,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Punganur',
      id: 10717,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Mattannur',
      id: 10718,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Mathura',
      id: 10719,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Thakurdwara',
      id: 10720,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nandivaram-Guduvancheri',
      id: 10721,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mulbagal',
      id: 10722,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Manjlegaon',
      id: 10723,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Wankaner',
      id: 10724,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Sillod',
      id: 10725,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Nidadavole',
      id: 10726,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Surapura',
      id: 10727,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Rajagangapur',
      id: 10728,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Sheikhpura',
      id: 10729,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Parlakhemundi',
      id: 10730,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Kalimpong',
      id: 10731,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Siruguppa',
      id: 10732,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Arvi',
      id: 10733,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Limbdi',
      id: 10734,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Barpeta',
      id: 10735,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Manglaur',
      id: 10736,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Repalle',
      id: 10737,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Mudhol',
      id: 10738,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Shujalpur',
      id: 10739,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Mandvi',
      id: 10740,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Thangadh',
      id: 10741,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Sironj',
      id: 10742,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Nandura',
      id: 10743,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Shoranur',
      id: 10744,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Nathdwara',
      id: 10745,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Periyakulam',
      id: 10746,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sultanganj',
      id: 10747,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Medak',
      id: 10748,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Narayanpet',
      id: 10749,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Raxaul Bazar',
      id: 10750,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Rajauri',
      id: 10751,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Pernampattu',
      id: 10752,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Nainital',
      id: 10753,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Ramachandrapuram',
      id: 10754,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Vaijapur',
      id: 10755,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Nangal',
      id: 10756,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Sidlaghatta',
      id: 10757,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Punch',
      id: 10758,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Pandhurna',
      id: 10759,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Wadgaon Road',
      id: 10760,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Talcher',
      id: 10761,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Varkala',
      id: 10762,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Pilani',
      id: 10763,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Nowgong',
      id: 10764,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Naila Janjgir',
      id: 10765,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Mapusa',
      id: 10766,
      state_id: 10031,
    },
    {
      type: 'city',
      label: 'Vellakoil',
      id: 10767,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Merta City',
      id: 10768,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sivaganga',
      id: 10769,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mandideep',
      id: 10770,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Sailu',
      id: 10771,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Vyara',
      id: 10772,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Kovvur',
      id: 10773,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Vadalur',
      id: 10774,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Nawabganj',
      id: 10775,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Padra',
      id: 10776,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Sainthia',
      id: 10777,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Siana',
      id: 10778,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Shahpur',
      id: 10779,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sojat',
      id: 10780,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Noorpur',
      id: 10781,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Paravoor',
      id: 10782,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Murtijapur',
      id: 10783,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Ramnagar',
      id: 10784,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sundargarh',
      id: 10785,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Taki',
      id: 10786,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Saundatti-Yellamma',
      id: 10787,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Pathanamthitta',
      id: 10788,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Wadi',
      id: 10789,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Rameshwaram',
      id: 10790,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Tasgaon',
      id: 10791,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sikandra Rao',
      id: 10792,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sihora',
      id: 10793,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Tiruvethipuram',
      id: 10794,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Tiruvuru',
      id: 10795,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Mehkar',
      id: 10796,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Peringathur',
      id: 10797,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Perambalur',
      id: 10798,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Manvi',
      id: 10799,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Zunheboto',
      id: 10800,
      state_id: 10030,
    },
    {
      type: 'city',
      label: 'Mahnar Bazar',
      id: 10801,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Attingal',
      id: 10802,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Shahbad',
      id: 10803,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Puranpur',
      id: 10804,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nelamangala',
      id: 10805,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Nakodar',
      id: 10806,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Lunawada',
      id: 10807,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Murshidabad',
      id: 10808,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Mahe',
      id: 10809,
      state_id: 10024,
    },
    {
      type: 'city',
      label: 'Lanka',
      id: 10810,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Rudauli',
      id: 10811,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Tuensang',
      id: 10812,
      state_id: 10030,
    },
    {
      type: 'city',
      label: 'Lakshmeshwar',
      id: 10813,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Zira',
      id: 10814,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Yawal',
      id: 10815,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Thana Bhawan',
      id: 10816,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Ramdurg',
      id: 10817,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Pulgaon',
      id: 10818,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sadasivpet',
      id: 10819,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Nargund',
      id: 10820,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Neem-Ka-Thana',
      id: 10821,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Memari',
      id: 10822,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Nilanga',
      id: 10823,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Naharlagun',
      id: 10824,
      state_id: 10033,
    },
    {
      type: 'city',
      label: 'Pakaur',
      id: 10825,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Wai',
      id: 10826,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Tarikere',
      id: 10827,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Malavalli',
      id: 10828,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Raisen',
      id: 10829,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Lahar',
      id: 10830,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Uravakonda',
      id: 10831,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Savanur',
      id: 10832,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sirohi',
      id: 10833,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Udhampur',
      id: 10834,
      state_id: 10016,
    },
    {
      type: 'city',
      label: 'Umarga',
      id: 10835,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Pratapgarh',
      id: 10836,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Lingsugur',
      id: 10837,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Usilampatti',
      id: 10838,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Palia Kalan',
      id: 10839,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Wokha',
      id: 10840,
      state_id: 10030,
    },
    {
      type: 'city',
      label: 'Rajpipla',
      id: 10841,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Vijayapura',
      id: 10842,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Rawatbhata',
      id: 10843,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sangaria',
      id: 10844,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Paithan',
      id: 10845,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Rahuri',
      id: 10846,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Patti',
      id: 10847,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Zaidpur',
      id: 10848,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Lalsot',
      id: 10849,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Maihar',
      id: 10850,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Vedaranyam',
      id: 10851,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Nawapur',
      id: 10852,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Solan',
      id: 10853,
      state_id: 10029,
    },
    {
      type: 'city',
      label: 'Vapi',
      id: 10854,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Sanawad',
      id: 10855,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Warisaliganj',
      id: 10856,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Revelganj',
      id: 10857,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sabalgarh',
      id: 10858,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Tuljapur',
      id: 10859,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Simdega',
      id: 10860,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Musabani',
      id: 10861,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Kodungallur',
      id: 10862,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Phulabani',
      id: 10863,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Umreth',
      id: 10864,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Narsipatnam',
      id: 10865,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Nautanwa',
      id: 10866,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Rajgir',
      id: 10867,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Yellandu',
      id: 10868,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Sathyamangalam',
      id: 10869,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Pilibanga',
      id: 10870,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Morshi',
      id: 10871,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Pehowa',
      id: 10872,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Sonepur',
      id: 10873,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Pappinisseri',
      id: 10874,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Zamania',
      id: 10875,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Mihijam',
      id: 10876,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Purna',
      id: 10877,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Puliyankudi',
      id: 10878,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Shikarpur, Bulandshahr',
      id: 10879,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Umaria',
      id: 10880,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Porsa',
      id: 10881,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Naugawan Sadat',
      id: 10882,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Fatehpur Sikri',
      id: 10883,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Manuguru',
      id: 10884,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Udaipur',
      id: 10885,
      state_id: 10025,
    },
    {
      type: 'city',
      label: 'Pipar City',
      id: 10886,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Pattamundai',
      id: 10887,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Nanjikottai',
      id: 10888,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Taranagar',
      id: 10889,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Yerraguntla',
      id: 10890,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Satana',
      id: 10891,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sherghati',
      id: 10892,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sankeshwara',
      id: 10893,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Madikeri',
      id: 10894,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Thuraiyur',
      id: 10895,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sanand',
      id: 10896,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Rajula',
      id: 10897,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Kyathampalle',
      id: 10898,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Shahabad, Rampur',
      id: 10899,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Tilda Newra',
      id: 10900,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Narsinghgarh',
      id: 10901,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Chittur-Thathamangalam',
      id: 10902,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Malaj Khand',
      id: 10903,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Sarangpur',
      id: 10904,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Robertsganj',
      id: 10905,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sirkali',
      id: 10906,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Radhanpur',
      id: 10907,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Tiruchendur',
      id: 10908,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Utraula',
      id: 10909,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Patratu',
      id: 10910,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Vijainagar, Ajmer',
      id: 10911,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Periyasemur',
      id: 10912,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Pathri',
      id: 10913,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sadabad',
      id: 10914,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Talikota',
      id: 10915,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sinnar',
      id: 10916,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Mungeli',
      id: 10917,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Sedam',
      id: 10918,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Shikaripur',
      id: 10919,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sumerpur',
      id: 10920,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sattur',
      id: 10921,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sugauli',
      id: 10922,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Lumding',
      id: 10923,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Vandavasi',
      id: 10924,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Titlagarh',
      id: 10925,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Uchgaon',
      id: 10926,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Mokokchung',
      id: 10927,
      state_id: 10030,
    },
    {
      type: 'city',
      label: 'Paschim Punropara',
      id: 10928,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Sagwara',
      id: 10929,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Ramganj Mandi',
      id: 10930,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Tarakeswar',
      id: 10931,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Mahalingapura',
      id: 10932,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Dharmanagar',
      id: 10933,
      state_id: 10025,
    },
    {
      type: 'city',
      label: 'Mahemdabad',
      id: 10934,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Manendragarh',
      id: 10935,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Uran',
      id: 10936,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Tharamangalam',
      id: 10937,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Tirukkoyilur',
      id: 10938,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Pen',
      id: 10939,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Makhdumpur',
      id: 10940,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Maner',
      id: 10941,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Oddanchatram',
      id: 10942,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Palladam',
      id: 10943,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mundi',
      id: 10944,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Nabarangapur',
      id: 10945,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Mudalagi',
      id: 10946,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Samalkha',
      id: 10947,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Nepanagar',
      id: 10948,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Karjat',
      id: 10949,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Ranavav',
      id: 10950,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Pedana',
      id: 10951,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Pinjore',
      id: 10952,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Lakheri',
      id: 10953,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Pasan',
      id: 10954,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Puttur',
      id: 10955,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Vadakkuvalliyur',
      id: 10956,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Tirukalukundram',
      id: 10957,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mahidpur',
      id: 10958,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Mussoorie',
      id: 10959,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Muvattupuzha',
      id: 10960,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Rasra',
      id: 10961,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Udaipurwati',
      id: 10962,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Manwath',
      id: 10963,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Adoor',
      id: 10964,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Uthamapalayam',
      id: 10965,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Partur',
      id: 10966,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Nahan',
      id: 10967,
      state_id: 10029,
    },
    {
      type: 'city',
      label: 'Ladwa',
      id: 10968,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Mankachar',
      id: 10969,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Nongstoin',
      id: 10970,
      state_id: 10027,
    },
    {
      type: 'city',
      label: 'Losal',
      id: 10971,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sri Madhopur',
      id: 10972,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Ramngarh',
      id: 10973,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Mavelikkara',
      id: 10974,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Rawatsar',
      id: 10975,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Rajakhera',
      id: 10976,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Lar',
      id: 10977,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Lal Gopalganj Nindaura',
      id: 10978,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Muddebihal',
      id: 10979,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sirsaganj',
      id: 10980,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Shahpura',
      id: 10981,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Surandai',
      id: 10982,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sangole',
      id: 10983,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Pavagada',
      id: 10984,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Tharad',
      id: 10985,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Mansa',
      id: 10986,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Umbergaon',
      id: 10987,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Mavoor',
      id: 10988,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Nalbari',
      id: 10989,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Talaja',
      id: 10990,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Malur',
      id: 10991,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Mangrulpir',
      id: 10992,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Soro',
      id: 10993,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Shahpura',
      id: 10994,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Vadnagar',
      id: 10995,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Raisinghnagar',
      id: 10996,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sindhagi',
      id: 10997,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sanduru',
      id: 10998,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sohna',
      id: 10999,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Manavadar',
      id: 11000,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Pihani',
      id: 11001,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Safidon',
      id: 11002,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Risod',
      id: 11003,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Rosera',
      id: 11004,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sankari',
      id: 11005,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Malpura',
      id: 11006,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sonamukhi',
      id: 11007,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Shamsabad, Agra',
      id: 11008,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nokha',
      id: 11009,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'PandUrban Agglomeration',
      id: 11010,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Mainaguri',
      id: 11011,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Afzalpur',
      id: 11012,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Shirur',
      id: 11013,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Salaya',
      id: 11014,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Shenkottai',
      id: 11015,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Pratapgarh',
      id: 11016,
      state_id: 10025,
    },
    {
      type: 'city',
      label: 'Vadipatti',
      id: 11017,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Nagarkurnool',
      id: 11018,
      state_id: 10006,
    },
    {
      type: 'city',
      label: 'Savner',
      id: 11019,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sasvad',
      id: 11020,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Rudrapur',
      id: 11021,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Soron',
      id: 11022,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sholingur',
      id: 11023,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Pandharkaoda',
      id: 11024,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Perumbavoor',
      id: 11025,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Maddur',
      id: 11026,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Nadbai',
      id: 11027,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Talode',
      id: 11028,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Shrigonda',
      id: 11029,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Madhugiri',
      id: 11030,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Tekkalakote',
      id: 11031,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Seoni-Malwa',
      id: 11032,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Shirdi',
      id: 11033,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'SUrban Agglomerationr',
      id: 11034,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Terdal',
      id: 11035,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Raver',
      id: 11036,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Tirupathur',
      id: 11037,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Taraori',
      id: 11038,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Mukhed',
      id: 11039,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Manachanallur',
      id: 11040,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Rehli',
      id: 11041,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Sanchore',
      id: 11042,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Rajura',
      id: 11043,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Piro',
      id: 11044,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Mudabidri',
      id: 11045,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Vadgaon Kasba',
      id: 11046,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Nagar',
      id: 11047,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Vijapur',
      id: 11048,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Viswanatham',
      id: 11049,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Polur',
      id: 11050,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Panagudi',
      id: 11051,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Manawar',
      id: 11052,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Tehri',
      id: 11053,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Samdhan',
      id: 11054,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Pardi',
      id: 11055,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Rahatgarh',
      id: 11056,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Panagar',
      id: 11057,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Uthiramerur',
      id: 11058,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Tirora',
      id: 11059,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Rangia',
      id: 11060,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Sahjanwa',
      id: 11061,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Wara Seoni',
      id: 11062,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Magadi',
      id: 11063,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Rajgarh (Alwar)',
      id: 11064,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Rafiganj',
      id: 11065,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Tarana',
      id: 11066,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Rampur Maniharan',
      id: 11067,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sheoganj',
      id: 11068,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Raikot',
      id: 11069,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Pauri',
      id: 11070,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Sumerpur',
      id: 11071,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Navalgund',
      id: 11072,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Shahganj',
      id: 11073,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Marhaura',
      id: 11074,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Tulsipur',
      id: 11075,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sadri',
      id: 11076,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Thiruthuraipoondi',
      id: 11077,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Shiggaon',
      id: 11078,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Pallapatti',
      id: 11079,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mahendragarh',
      id: 11080,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Sausar',
      id: 11081,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Ponneri',
      id: 11082,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mahad',
      id: 11083,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Lohardaga',
      id: 11084,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Tirwaganj',
      id: 11085,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Margherita',
      id: 11086,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Sundarnagar',
      id: 11087,
      state_id: 10029,
    },
    {
      type: 'city',
      label: 'Rajgarh',
      id: 11088,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Mangaldoi',
      id: 11089,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Renigunta',
      id: 11090,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Longowal',
      id: 11091,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Ratia',
      id: 11092,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Lalgudi',
      id: 11093,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Shrirangapattana',
      id: 11094,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Niwari',
      id: 11095,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Natham',
      id: 11096,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Unnamalaikadai',
      id: 11097,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'PurqUrban Agglomerationzi',
      id: 11098,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Shamsabad, Farrukhabad',
      id: 11099,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Mirganj',
      id: 11100,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Todaraisingh',
      id: 11101,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Warhapur',
      id: 11102,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Rajam',
      id: 11103,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Urmar Tanda',
      id: 11104,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Lonar',
      id: 11105,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Powayan',
      id: 11106,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'P.N.Patti',
      id: 11107,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Palampur',
      id: 11108,
      state_id: 10029,
    },
    {
      type: 'city',
      label: 'Srisailam Project (Right Flank Colony) Township',
      id: 11109,
      state_id: 10013,
    },
    {
      type: 'city',
      label: 'Sindagi',
      id: 11110,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sandi',
      id: 11111,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Vaikom',
      id: 11112,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Malda',
      id: 11113,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Tharangambadi',
      id: 11114,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Sakaleshapura',
      id: 11115,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Lalganj',
      id: 11116,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Malkangiri',
      id: 11117,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Rapar',
      id: 11118,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Mauganj',
      id: 11119,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Todabhim',
      id: 11120,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Srinivaspur',
      id: 11121,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Murliganj',
      id: 11122,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Reengus',
      id: 11123,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Sawantwadi',
      id: 11124,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Tittakudi',
      id: 11125,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Lilong',
      id: 11126,
      state_id: 10028,
    },
    {
      type: 'city',
      label: 'Rajaldesar',
      id: 11127,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Pathardi',
      id: 11128,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Achhnera',
      id: 11129,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Pacode',
      id: 11130,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Naraura',
      id: 11131,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Nakur',
      id: 11132,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Palai',
      id: 11133,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Morinda, India',
      id: 11134,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Manasa',
      id: 11135,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Nainpur',
      id: 11136,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Sahaspur',
      id: 11137,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Pauni',
      id: 11138,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Prithvipur',
      id: 11139,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Ramtek',
      id: 11140,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Silapathar',
      id: 11141,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Songadh',
      id: 11142,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Safipur',
      id: 11143,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sohagpur',
      id: 11144,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Mul',
      id: 11145,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sadulshahar',
      id: 11146,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Phillaur',
      id: 11147,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Sambhar',
      id: 11148,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Prantij',
      id: 11149,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Nagla',
      id: 11150,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Pattran',
      id: 11151,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Mount Abu',
      id: 11152,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Reoti',
      id: 11153,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Tenu dam-cum-Kathhara',
      id: 11154,
      state_id: 10017,
    },
    {
      type: 'city',
      label: 'Panchla',
      id: 11155,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Sitarganj',
      id: 11156,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Pasighat',
      id: 11157,
      state_id: 10033,
    },
    {
      type: 'city',
      label: 'Motipur',
      id: 11158,
      state_id: 10011,
    },
    {
      type: 'city',
      label: "O' Valley",
      id: 11159,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Raghunathpur',
      id: 11160,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Suriyampalayam',
      id: 11161,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Qadian',
      id: 11162,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Rairangpur',
      id: 11163,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Silvassa',
      id: 11164,
      state_id: 10034,
    },
    {
      type: 'city',
      label: 'Nowrozabad (Khodargama)',
      id: 11165,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Mangrol',
      id: 11166,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Soyagaon',
      id: 11167,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sujanpur',
      id: 11168,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Manihari',
      id: 11169,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sikanderpur',
      id: 11170,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Mangalvedhe',
      id: 11171,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Phulera',
      id: 11172,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Ron',
      id: 11173,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Sholavandan',
      id: 11174,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Saidpur',
      id: 11175,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Shamgarh',
      id: 11176,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Thammampatti',
      id: 11177,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Maharajpur',
      id: 11178,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Multai',
      id: 11179,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Mukerian',
      id: 11180,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Sirsi',
      id: 11181,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Purwa',
      id: 11182,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sheohar',
      id: 11183,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Namagiripettai',
      id: 11184,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Parasi',
      id: 11185,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Lathi',
      id: 11186,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Lalganj',
      id: 11187,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Narkhed',
      id: 11188,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Mathabhanga',
      id: 11189,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Shendurjana',
      id: 11190,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Peravurani',
      id: 11191,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mariani',
      id: 11192,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Phulpur',
      id: 11193,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Rania',
      id: 11194,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Pali',
      id: 11195,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Pachore',
      id: 11196,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Parangipettai',
      id: 11197,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Pudupattinam',
      id: 11198,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Panniyannur',
      id: 11199,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Maharajganj',
      id: 11200,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Rau',
      id: 11201,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Monoharpur',
      id: 11202,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Mandawa',
      id: 11203,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Marigaon',
      id: 11204,
      state_id: 10019,
    },
    {
      type: 'city',
      label: 'Pallikonda',
      id: 11205,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Pindwara',
      id: 11206,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Shishgarh',
      id: 11207,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Patur',
      id: 11208,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Mayang Imphal',
      id: 11209,
      state_id: 10028,
    },
    {
      type: 'city',
      label: 'Mhowgaon',
      id: 11210,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Guruvayoor',
      id: 11211,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Mhaswad',
      id: 11212,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Sahawar',
      id: 11213,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sivagiri',
      id: 11214,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Mundargi',
      id: 11215,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Punjaipugalur',
      id: 11216,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Kailasahar',
      id: 11217,
      state_id: 10025,
    },
    {
      type: 'city',
      label: 'Samthar',
      id: 11218,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Sakti',
      id: 11219,
      state_id: 10018,
    },
    {
      type: 'city',
      label: 'Sadalagi',
      id: 11220,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Silao',
      id: 11221,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Mandalgarh',
      id: 11222,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Loha',
      id: 11223,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Pukhrayan',
      id: 11224,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Padmanabhapuram',
      id: 11225,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Belonia',
      id: 11226,
      state_id: 10025,
    },
    {
      type: 'city',
      label: 'Saiha',
      id: 11227,
      state_id: 10026,
    },
    {
      type: 'city',
      label: 'Srirampore',
      id: 11228,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Talwara',
      id: 11229,
      state_id: 10014,
    },
    {
      type: 'city',
      label: 'Puthuppally',
      id: 11230,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Khowai',
      id: 11231,
      state_id: 10025,
    },
    {
      type: 'city',
      label: 'Vijaypur',
      id: 11232,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Takhatgarh',
      id: 11233,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Thirupuvanam',
      id: 11234,
      state_id: 10007,
    },
    {
      type: 'city',
      label: 'Adra',
      id: 11235,
      state_id: 10008,
    },
    {
      type: 'city',
      label: 'Piriyapatna',
      id: 11236,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Obra',
      id: 11237,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Adalaj',
      id: 11238,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Nandgaon',
      id: 11239,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Barh',
      id: 11240,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Chhapra',
      id: 11241,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Panamattom',
      id: 11242,
      state_id: 10022,
    },
    {
      type: 'city',
      label: 'Niwai',
      id: 11243,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Bageshwar',
      id: 11244,
      state_id: 10023,
    },
    {
      type: 'city',
      label: 'Tarbha',
      id: 11245,
      state_id: 10021,
    },
    {
      type: 'city',
      label: 'Adyar',
      id: 11246,
      state_id: 10004,
    },
    {
      type: 'city',
      label: 'Narsinghgarh',
      id: 11247,
      state_id: 10012,
    },
    {
      type: 'city',
      label: 'Warud',
      id: 11248,
      state_id: 10002,
    },
    {
      type: 'city',
      label: 'Asarganj',
      id: 11249,
      state_id: 10011,
    },
    {
      type: 'city',
      label: 'Sarsod',
      id: 11250,
      state_id: 10015,
    },
    {
      type: 'city',
      label: 'Gandhinagar',
      id: 11251,
      state_id: 10005,
    },
    {
      type: 'city',
      label: 'Kullu',
      id: 11252,
      state_id: 10029,
    },
    {
      type: 'city',
      label: 'Manali',
      id: 11253,
      state_id: 10035,
    },
    {
      type: 'city',
      label: 'Mirzapur',
      id: 11254,
      state_id: 10010,
    },
    {
      type: 'city',
      label: 'Kota',
      id: 11255,
      state_id: 10009,
    },
    {
      type: 'city',
      label: 'Dispur',
      id: 11256,
      state_id: 10019,
    },
  ],
  COUNTRIES: [
    {
      code: 'in',
      label: 'India',
    },
    {
      code: 'us',
      label: 'United States',
    },
    {
      code: 'sg',
      label: 'Singapore',
    },
    {
      code: 'uae',
      label: 'UAE',
    },
  ],
  // Add more banks as needed
  BANKS: {
    sbi: {
      label: 'State Bank of India',
      value: 'sbi',
    },
    hdfc: {
      label: 'HDFC Bank',
      value: 'hdfc',
    },
    icici: {
      label: 'ICICI Bank',
      value: 'icici',
    },
    pnb: {
      label: 'Punjab National Bank',
      value: 'pnb',
    },
    axis: {
      label: 'Axis Bank',
      value: 'axis',
    },
    boi: {
      label: 'Bank of India',
      value: 'boi',
    },
    canara: {
      label: 'Canara Bank',
      value: 'canara',
    },
    bob: {
      label: 'Bank of Baroda',
      value: 'bob',
    },
    idbi: {
      label: 'IDBI Bank',
      value: 'idbi',
    },
    yes: {
      label: 'Yes Bank',
      value: 'yes',
    },
    indusind: {
      label: 'IndusInd Bank',
      value: 'indusind',
    },
    kotak: {
      label: 'Kotak Mahindra Bank',
      value: 'kotak',
    },
    idfc: {
      label: 'IDFC First Bank',
      value: 'idfc',
    },
    rbl: {
      label: 'RBL Bank',
      value: 'rbl',
    },
    federal: {
      label: 'Federal Bank',
      value: 'federal',
    },
    uco: {
      label: 'Uco Bank',
      value: 'uco',
    },
  },

  // LANGUAGE
  LANGUAGE: {
    hindi: {
      value: 'hindi',
      label: 'Hindi',
    },
    english: {
      value: 'english',
      label: 'English',
    },
    marathi: {
      value: 'marathi',
      label: 'Marathi',
    },
    tamil: {
      value: 'tamil',
      label: 'Tamil',
    },
    telugu: {
      value: 'telugu',
      label: 'Telugu',
    },
    malayalam: {
      value: 'malayalam',
      label: 'Malayalam',
    },
    bengali: {
      value: 'bengali',
      label: 'Bengali',
    },
    gujarati: {
      value: 'gujarati',
      label: 'Gujarati',
    },
    kannada: {
      value: 'kannada',
      label: 'Kannada',
    },
    odia: {
      value: 'odia',
      label: 'Odia',
    },
    punjabi: {
      value: 'punjabi',
      label: 'Punjabi',
    },
    assamese: {
      value: 'assamese',
      label: 'Assamese',
    },
    kashmiri: {
      value: 'kashmiri',
      label: 'Kashmiri',
    },
    bhojpuri: {
      value: 'bhojpuri',
      label: 'Bhojpuri',
    },
  },
  // Followers
  FOLLOWERS: {
    10000: {
      n: '10 K',
      value: 10000,
    },
    20000: {
      n: '20 K',
      value: 20000,
    },
    30000: {
      n: '30 K',
      value: 30000,
    },
    40000: {
      n: '40 K',
      value: 40000,
    },
    50000: {
      n: '50 K',
      value: 50000,
    },
    70000: {
      n: '70 K',
      value: 70000,
    },
    90000: {
      n: '90 K',
      value: 90000,
    },
    100000: {
      n: '100 K',
      value: 100000,
    },
    250000: {
      n: '250 K',
      value: 250000,
    },
    500000: {
      n: '500 K+',
      value: 500000,
    },
  },

  // REWARD TYPE
  REWARD_TYPE: {
    cash: {
      n: 'CASH',
    },
    discount: {
      n: 'DISCOUNT',
    },
    cashback: {
      n: 'CASHBACK',
    },
  },

  CATEGORY: {
    food_drink: {
      n: 'Food & Drink',
      id: 'food_drink',
    },
    fashion: {
      n: 'Fashion',
      id: 'fashion',
    },
    beauty: {
      n: 'Beauty & Personal Care',
      id: 'beauty',
    },
    web: {
      n: 'Social Media, Web & Tech',
      id: 'web',
    },
    art: {
      n: 'Photography, Art & Design',
      id: 'art',
    },
    event: {
      n: 'Entertainment & Events',
      id: 'event',
    },
    health: {
      n: 'Health, Fitness & Sport',
      id: 'health',
    },
    travel: {
      n: 'Travel & Destinations',
      id: 'travel',
    },
    hotel: {
      n: 'Restaurants, Bars & Hotels',
      id: 'hotel',
    },
    auto: {
      n: 'Automotive',
      id: 'auto',
    },
    family: {
      n: 'Children & Family',
      id: 'family',
    },
    home: {
      n: 'Home & Garden',
      id: 'home',
    },
    animal: {
      n: 'Animals',
      id: 'animal',
    },
    book: {
      n: 'Education & Books',
      id: 'book',
    },
    business: {
      n: 'Business, Finance & Insurance',
      id: 'business',
    },
    social: {
      n: 'Social Enterprise & Not-for-profit',
      id: 'social',
    },
  },

  CATEGORYS: {
    '4v4m': {
      label: 'Finance',
      value: '4v4m',
    },
    '5c20': {
      label: 'Entertainment',
      value: '5c20',
    },
    '9w6m': {
      label: 'Restaurants',
      value: '9w6m',
    },
    afie: {
      label: 'Auto',
      value: 'afie',
    },
    cjsh: {
      label: 'Travel',
      value: 'cjsh',
    },
    eops: {
      label: 'News',
      value: 'eops',
    },
    m0im: {
      label: 'Fashion & Beauty',
      value: 'm0im',
    },
    nsld: {
      label: 'Food & Drink',
      value: 'nsld',
    },
    p914: {
      label: 'Education',
      value: 'p914',
    },
    txv6: {
      label: 'Photography',
      value: 'txv6',
    },
    w7s7: {
      label: 'Technology',
      value: 'w7s7',
    },
    yngw: {
      label: 'Health & Fitness',
      value: 'yngw',
    },
  },
  CATEGORIES: {
    CATEGORYS: {
      '4v4m': {
        label: 'Finance',
        value: '4v4m',
      },
      '5c20': {
        label: 'Entertainment',
        value: '5c20',
      },
      '9w6m': {
        label: 'Restaurants',
        value: '9w6m',
      },
      afie: {
        label: 'Auto',
        value: 'afie',
      },
      cjsh: {
        label: 'Travel',
        value: 'cjsh',
      },
      m0im: {
        label: 'Beauty Influencer',
        value: 'm0im',
      },
      m1im: {
        label: 'Fashion and Lifestyle',
        value: 'm1im',
      },
      nsld: {
        label: 'Food & Drink',
        value: 'nsld',
      },
      p914: {
        label: 'Education',
        value: 'p914',
      },
      txv6: {
        label: 'Photography',
        value: 'txv6',
      },
      w7s7: {
        label: 'Technology',
        value: 'w7s7',
      },
      yngw: {
        label: 'Health & Fitness',
        value: 'yngw',
      },
      agri: {
        label: 'Agriculture',
        value: 'agri',
      },
      sprt: {
        label: 'Sports',
        value: 'sprt',
      },
      decr: {
        label: 'Home Decor',
        value: 'decr',
      },
    },
    PROFESSIONS: {
      '4v4m': [
        {
          id: '4v4m1',
          name: 'Personal Finance Expert',
        },
        {
          id: '4v4m2',
          name: 'Investment Advisor',
        },
        {
          id: '4v4m3',
          name: 'Stock market expert',
        },
        {
          id: '4v4m4',
          name: 'CA',
        },
      ],
      '5c20': [
        {
          id: '5c20_3',
          name: 'Vlogger',
        },
        {
          id: '5c20_4',
          name: 'Actor',
        },
        {
          id: '5c20_5',
          name: 'Musician',
        },
        {
          id: '5c20_6',
          name: 'Comedian',
        },
        {
          id: '5c20_7',
          name: 'Dancer',
        },
        {
          id: '5c20_11',
          name: 'Magician',
        },
        {
          id: '5c20_12',
          name: 'Tattoo Artist',
        },
        {
          id: '5c20_13',
          name: 'Memer',
        },
        {
          id: '5c20_14',
          name: 'RJs',
        },
        {
          id: '5c20_15',
          name: 'Parenting',
        },
      ],
      '9w6m': [
        {
          id: '9w6m1',
          name: 'Food Blogger',
        },
        {
          id: '9w6m2',
          name: 'Restaurant Owner',
        },
      ],
      afie: [
        {
          id: 'afie1',
          name: 'Car Enthusiast',
        },
        {
          id: 'afie2',
          name: 'Bike Enthusiast',
        },
        {
          id: 'afie3',
          name: 'Auto Reseller',
        },
        {
          id: 'afie4',
          name: 'Driver',
        },
      ],
      cjsh: [
        {
          id: 'cjsh1',
          name: 'Travel Blogger',
        },
        {
          id: 'cjsh2',
          name: 'Adventure Seeker',
        },
      ],
      m0im: [
        {
          id: 'm0im1',
          name: 'Skin Care Specialist',
        },
        {
          id: 'm0im2',
          name: 'Hair Care Specialist',
        },
        {
          id: 'm0im3',
          name: 'Makeup Artist',
        },
      ],
      m1im: [
        {
          id: 'm1im1',
          name: 'Vlogger',
        },
        {
          id: 'm1im2',
          name: 'Motivation Speaker',
        },
        {
          id: 'm1im3',
          name: 'Fashion Influencer',
        },
      ],
      nsld: [
        {
          id: 'nsld1',
          name: 'Food Critic',
        },
        {
          id: 'nsld2',
          name: 'Food Photographer',
        },
        {
          id: 'nsld3',
          name: 'Chef',
        },
        {
          id: 'nsld4',
          name: 'Foodie',
        },
        {
          id: 'nsld5',
          name: 'Bartender',
        },
      ],
      p914: [
        {
          id: 'p9141',
          name: 'Teacher',
        },
        {
          id: 'p9142',
          name: 'Student',
        },
        {
          id: 'p9142',
          name: 'Career Coach',
        },
        {
          id: 'p9143',
          name: 'Publisher',
        },
      ],
      txv6: [
        {
          id: 'txv6_1',
          name: 'Street Photographer',
        },
        {
          id: 'txv6_2',
          name: 'Wild Life Photographer',
        },
      ],
      w7s7: [
        {
          id: 'w7s7_0',
          name: 'Gamer',
        },
        {
          id: 'w7s7_1',
          name: 'Tech Reviewer',
        },
        {
          id: 'w7s7_2',
          name: 'Gadget Enthusiast',
        },
        {
          id: 'w7s7_3',
          name: 'Animator',
        },
        {
          id: 'w7s7_4',
          name: 'Video Editor',
        },
        {
          id: 'w7s7_5',
          name: 'Graphics Designer',
        },
        {
          id: 'w7s7_6',
          name: 'Marketing Expert',
        },
        {
          id: 'w7s7_7',
          name: 'Software Engineer',
        },
      ],
      yngw: [
        {
          id: 'yngw_1',
          name: 'Fitness Trainer',
        },
        {
          id: 'yngw_2',
          name: 'Nutritionist',
        },
        {
          id: 'yngw_3',
          name: 'Yoga Instructor',
        },
        {
          id: 'yngw_4',
          name: 'MBBS Doctor',
        },
        {
          id: 'yngw_5',
          name: 'Dentist',
        },
        {
          id: 'yngw_6',
          name: 'Ayurveda Doctor',
        },
        {
          id: 'yngw_7',
          name: 'Physiothearapist',
        },
        {
          id: 'yngw_8',
          name: 'Psychologist',
        },
      ],
      agri: [
        {
          id: 'agri1',
          name: 'Agricultural Influencer',
        },
        {
          id: 'agri2',
          name: 'Farmer',
        },
        {
          id: 'agri3',
          name: 'Plat lover',
        },
      ],
      decr: [
        {
          id: 'decr1',
          name: 'Interior designer',
        },
        {
          id: 'decr2',
          name: 'Architect',
        },
        {
          id: 'decr3',
          name: 'Construction Expert',
        },
      ],
      sprt: [
        {
          id: 'sprt1',
          name: 'Cricketer',
        },
        {
          id: 'sprt2',
          name: 'Athlete',
        },
        {
          id: 'sprt3',
          name: 'Coach',
        },
        {
          id: 'sprt4',
          name: 'Sport Expert',
        },
      ],
    },
  },
};
