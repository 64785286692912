import React, { useCallback, useEffect, useState } from "react";
import Sdk from "../../common/sdk/influencerSdk";
import { CustomText, FallBack } from "../../common/core_component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Utils from "../../common/utils";
import Ourlogo from "../../assets/images/small-logo.png";
import { Loader } from "../Loader";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Footer from "../common/Footer";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import ReactPullToRefresh from "react-pull-to-refresh";
import InfiniteScroll from "react-infinite-scroll-component";

function AllCampaignShow() {
  const [campaignList, setCampaignList] = useState([]);
  const [campaignInvitedList, setCampaignInvitedList] = useState([]);
  const [loading, setLoading] = useState();
  const [activeTab, setActiveTab] = useState("popular");
  const [next, setNext] = useState(10);

  const [start, setStart] = useState(0);

  const [invitedNext, setInvitedNext] = useState(0);
  const [inviteStart, setStartInvited] = useState(0);
  const limit = 10;

  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);

  // const handlePullToRefresh = useCallback(async () => {
  //   setPullToRefreshLoading(true);
  //   setLoading(true);
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setPullToRefreshLoading(false);
  //   setLoading(false);
  // }, []);

  // ------- Redux -------
  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { enqueueSnackbar } = useSnackbar();

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  console.log("influn", influencer_data.influencer_id);

  console.log("redux", reduxData.UserData.userData);

  const handlePopularClick = () => {
    setActiveTab("popular");
  };

  const handleInvitedClick = () => {
    setActiveTab("invited");
  };

  useEffect(() => {
    getCampaignList(start, updateProgress);
    getInvitedCampaignList(inviteStart, updateProgress);
  }, []);

  const navigate = useNavigate();

  function updateProgress(status) {
    setLoading(status);
  }

  // ----------- Campaign List ----------
  function getCampaignList(start, updateProgress) {
    const params = {
      start: start,
      limit: limit,
    };

    Sdk.getCampaignList(
      getCampaignListSuccess,
      getCampaignListFailed,
      updateProgress,
      // () => {},
      params
    );
  }

  function getCampaignListSuccess(api_response) {
    console.log("Res", api_response);
    const { campaign_data } = api_response;
    setCampaignList((prevList) => [...prevList, ...campaign_data]);
    setNext(api_response.page?.next);
  }
  console.log("next", next);

  // console.log("campagin ", campaignList);

  function getCampaignListFailed(error_list) {
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
    console.log("response error", error_list);
  }

  // ------- Invited Campaign list --------
  function getInvitedCampaignList(start, updateProgress) {
    if (!Utils.influencerLogined_Or_Not(influencer_data.influencer_id)) {
      return;
    }
    var params = {
      start: start,
      limit: limit,
    };

    Sdk.getInvitedCampaignList(
      getCampaignInvitedListSuccess,
      getCampaignInvitedListFailed,
      updateProgress,
      params
    );
  }

  function getCampaignInvitedListSuccess(api_response) {
    const { campaign_data = [] } = api_response;
    // setCampaignInvitedList(api_response.campaign_data)

    setCampaignInvitedList((prevList) => [...prevList, ...campaign_data]);

    setInvitedNext(api_response.page_data?.next);
  }

  console.log("invited List", campaignInvitedList);

  function getCampaignInvitedListFailed(error_list) {
    // console.log("response error", error_list);
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  // -------------- Render UI ------------

  function renderSocialMediaIcon(content_type = []) {
    const platforms = [];
    return content_type.map((item, index) => {
      if (
        item.platform_data &&
        item.platform_data.platform_id &&
        !platforms.includes(item.platform_data.platform_id)
      ) {
        platforms.push(item.platform_data.platform_id);
        // console.log('Platform ID:', item.platform_data.platform_id);
        const icon = Utils.socialMediaIcons(item.platform_data.platform_id);
        // console.log('Icon:', icon);
        return (
          <div key={index} className="card-icon-campaign">
            <Icon
              icon={Utils.socialMediaIcons(item.platform_data.platform_id)}
              height={15}
              width={15}
            />
          </div>
        );
      }
      return null;
    });
  }

  function renderCampaignList() {
    if (!campaignList || campaignList.length === 0) {
      return (
        <div className="m-t-15">
        <div className="fallback p-t-12">
          <FallBack
            icon={svgs.folder}
            heading="No Campaign Found"
            title=" No Campaign Found for You."
          />
        </div>
        </div>
      );
    }

    const navigateTo = (campaign_id) => {
      navigate(`/campaign/${campaign_id}`);
    };

    return (
      <>
        {campaignList ? (
          <div className="container" style={{ marginTop: "18px" }}>
            
            <div className="m-t-8">
              {campaignList &&
                campaignList.map((campagin, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      navigateTo(
                        campagin.campaign_data.campaign_id
                      )
                    }
                    className="card-campaign m-t-12"
                  >
                    <div className="card-icon-campaign">
                      <img
                        src={
                          campagin.brand_data?.image_id
                            ? Utils.generateBrandLogoUrl(
                                campagin.brand_data?.org_id,
                                campagin.brand_data?.image_id
                              )
                            : Ourlogo
                        }
                        alt="Brand Logo"
                      />
                    </div>
                    <div className="card-content-campaign">
                      <div className="card-title-campaign">
                       
                        <CustomText
                          h5
                          text={Utils.truncateText(campagin?.campaign_data?.name)}
                          className="ellipsisText text-14"
                        />
                      </div>
                      <div className="card-timing-campaign">
                        <CustomText
                          p
                          text={`Ending ${Utils.convertUnixToHumanDate(
                            campagin.campaign_data.end_date
                          )}`}
                        />
                      </div>
                      <div className="card-detals-campaign">
                        <CustomText
                          p
                          text={Utils.getCampaignTypeText(
                            campagin.campaign_data.type
                          )}
                          className="small-txt"
                        />
                        <CustomText
                          p
                          text={
                            campagin.campaign_data.collab_type == "BARTER"
                              ? "CASHBACK"
                              : `${campagin.reward_data.type.toUpperCase()} UPTO ${Utils.formatMoney(campagin.campaign_data.max_budget.toFixed(
                                  0
                                ))}`
                          }
                          className="small-txt"
                        />
                      </div>
                      <div className="card-footer-campaign">
                        <div className="credits-campaign">
                          {/* .. ICONS .... */}
                          {
                            <span>
                              {renderSocialMediaIcon(
                                campagin.content_type_deep_data
                              )}
                            </span>
                          }

                          <span>
                            {!campagin.campaign_data.campaign_credits
                              ? "FREE"
                              : campagin.campaign_data.campaign_credits +
                                " CREDITS"}
                          </span>
                        </div>
                        <div className="proposals-campaign">
                          {`${campagin.campaign_data.bid_count || 0} PROPOSALS`}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="fallback p-t-12">
            <FallBack
              icon={svgs.folder}
              heading="No Campaign Found"
              title="No campaigns found for you"
            />
          </div>
        )}
      </>
    );
  }

  function renderCampaignInvitedList() {
    if (!campaignInvitedList || campaignInvitedList.length === 0) {
      return (
        <div className="m-t-16">
        <div className="fallback p-t-12 ">
          <FallBack
            icon={svgs.folder}
            heading="No Invited Campaign Found"
            title=" No Invited Campaign Found for You."
          />
        </div>
        </div>
      );
    }
    const navigateTo = (campaign_id) => {
      navigate(`/campaign/${campaign_id}`);
    };

    return (
      <>
        {campaignInvitedList && campaignInvitedList.lenght > 0 ? (
          <div className="m-t-18 container">
            <div className="m-t-12 ">
              {campaignInvitedList &&
                campaignInvitedList.map((campagin, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      navigateTo(
                        campagin.campaign_data.campaign_id
                      )
                    }
                    className="card-campaign m-t-12"
                  >
                    <div className="card-icon-campaign">
                      <img
                        src={
                          campagin.brand_data?.image_id
                            ? Utils.generateBrandLogoUrl(
                                campagin.brand_data?.org_id,
                                campagin.brand_data?.image_id
                              )
                            : Ourlogo
                        }
                        alt="Brand Logo"
                      />
                    </div>
                    <div className="card-content-campaign">
                      <div className="card-title-campaign">
                        <CustomText h5 text={campagin.campaign_data.name} />
                      </div>
                      <div className="card-timing-campaign">
                        <CustomText
                          p
                          text={`Ending ${Utils.convertUnixToHumanDate(
                            campagin.campaign_data.end_date
                          )}`}
                        />
                      </div>
                      <div className="card-detals-campaign">
                        <CustomText
                          p
                          text={Utils.getCampaignTypeText(
                            campagin.campaign_data.type
                          )}
                          className="small-txt"
                        />
                        <CustomText
                          p
                          text={
                            campagin.campaign_data.collab_type == "BARTER"
                              ? "CASHBACK"
                              : `${campagin.reward_data.type.toUpperCase()} UPTO Rs ${campagin.campaign_data.max_budget.toFixed(
                                  0
                                )}`
                          }
                          className="small-txt"
                        />
                      </div>
                      <div className="card-footer-campaign">
                        <div className="credits-campaign">
                          {/* .. ICONS .... */}
                          {
                            <span>
                              {renderSocialMediaIcon(
                                campagin.content_type_deep_data
                              )}
                            </span>
                          }

                          <span>
                            {!campagin.campaign_data.campaign_credits
                              ? "FREE"
                              : campagin.campaign_data.campaign_credits +
                                " CREDITS"}
                          </span>
                        </div>
                        <div className="proposals-campaign">
                          {`${campagin.campaign_data.bid_count || 0} PROPOSALS`}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="fallback " style={{ marginTop: "18px" }}>
            <FallBack
              icon={svgs.folder}
              heading="No Invited Campaign Found"
              title="No Invited Campaign Found For You "
            />
          </div>
        )}
      </>
    );
  }

  const renderList = () => {
    if (activeTab === "popular") {
      return renderCampaignList();
    } else if (activeTab === "invited") {
      return renderCampaignInvitedList();
    }
  };

  return (
    <>
      
        <div className="bg-app-gray">
          <div className="header-fixed-list">
            <CustomText p text={"Campaigns"} className="p-l-15" />
            <div className="popular-invited " style={{ paddingTop: "20px" }}>
              <div
                onClick={handlePopularClick}
                className={`tab ${activeTab === "popular" ? "active-tab" : ""}`}
              >
                <CustomText
                  p
                  text={"POPULAR"}
                  className="small-txt-pop-invited"
                />
              </div>

              <div
                onClick={handleInvitedClick}
                className={`tab ${activeTab === "invited" ? "active-tab" : ""}`}
              >
                <CustomText
                  p
                  text={"INVITED"}
                  className="small-txt-pop-invited"
                />
              </div>
            </div>
          </div>

          {/* --------- Render Cards ------------ */}

          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div className="" style={{ paddingTop: "95px" }}>
              <div className="" style={{ paddingBottom: "65px" }}>
                {/* Infinite Scroll component */}

                {/* {activeTab === "popular" && (
                  <InfiniteScroll
                    dataLength={campaignList.length}
                    next={() => getCampaignList(next, () => {})}
                    hasMore={next !== null}
                    // loader={<Loader />}
                    scrollThreshold={0.9}
                  >
                    {renderCampaignList()}
                  </InfiniteScroll>
                )}

                {activeTab === "invited" && (
                  <InfiniteScroll
                    dataLength={campaignInvitedList.length}
                    next={() => getInvitedCampaignList(invitedNext, () => {})}
                    hasMore={invitedNext !== null}
                    // loader={<Loader />}
                    scrollThreshold={0.9}
                  >
                    {renderCampaignInvitedList()}
                  </InfiniteScroll>
                )} */}
                {renderList()}
              </div>
              <Footer />
            </div>
          )}
        </div>
       
    </>
  );
}

export default AllCampaignShow;
