import Utils from '../utils';

const Influencer = {
  createInfluencerData: function () {
    return {
      country: 'in',
    };
  },

  updateInfluencerProfileStatus: function (influencer_data, profile_status) {
    if (Utils.isNullOrUndefined(profile_status)) {
      return influencer_data;
    }

    console.log('influencer_datainfluencer_data', influencer_data);

    influencer_data['profile_status'] = profile_status;

    return influencer_data;
  },

  updateInfluencerPhoneNumber: function (influencer_data, phone) {
    if (Utils.isNullOrUndefined(phone)) {
      return influencer_data;
    }

    influencer_data['phone'] = phone;

    return influencer_data;
  },

  updateInfluencerName: function (influencer_data, name) {
    if (Utils.isNullOrUndefined(name)) {
      return influencer_data;
    }

    influencer_data['name'] = name;

    return influencer_data;
  },

  updateInfluencerDob: function (influencer_data, date_of_birth) {
    if (Utils.isNullOrUndefined(date_of_birth)) {
      return influencer_data;
    }

    influencer_data['date_of_birth'] = date_of_birth;

    return influencer_data;
  },

  updateInfluencerGender: function (influencer_data, gender) {
    if (Utils.isNullOrUndefined(gender)) {
      return influencer_data;
    }

    influencer_data['gender'] = gender;

    return influencer_data;
  },

  updateInfluencerLocation: function (influencer_data, state) {
    if (Utils.isNullOrUndefined(state)) {
      return influencer_data;
    }

    influencer_data['state'] = state;

    return influencer_data;
  },

  updateInfluencerInfo: function (influencer_data, note) {
    if (Utils.isNullOrUndefined(note)) {
      return influencer_data;
    }

    influencer_data['note'] = note;

    return influencer_data;
  },

  updateInfluencerCatagories: function (influencer_data, category) {
    if (Utils.isNullOrUndefined(category)) {
      return influencer_data;
    }

    influencer_data = {
      ...influencer_data['category'],
      category: [...category],
    };

    return influencer_data;
  },

  updateInfluencerWorkingHour: function (influencer_data, full_time) {
    if (Utils.isNullOrUndefined(full_time)) {
      return influencer_data;
    }

    influencer_data['full_time'] = full_time;

    return influencer_data;
  },

  getDateString: function (date) {
    let formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    var newdate = new Date(formattedDate);

    var unixTimeStamp = Math.floor(newdate.getTime() / 1000);

    return formattedDate;
  },
};

export default Influencer;
