import React, { useEffect, useState } from "react";
import svgs from "../../assets/icons/svgs";
import Icon from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { CustomText } from "../../common/core_component";
import { Loader } from "../Loader";
import Sdk from "../../common/sdk/influencerSdk";

function HelpAndSupport() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState();
  const [faq_list, setFaqList] = useState();

  useEffect(() => {
    getFaqListPublic();
  }, []);

  function getFaqListPublic() {
    Sdk.getFaqListPublic(
      getFaqListPublicSuccess,
      getFaqListPublicFailed,
      updateProgress
    );
  }

  function getFaqListPublicSuccess(response) {
    setFaqList(response.faq_list);
  }
  console.log("dom", faq_list);

  function getFaqListPublicFailed(err) {}

  function updateProgress(status) {
    setLoading(status);
  }

  function renderData() {
    return (
      <>
        {faq_list &&
          faq_list.map((item, index) => (
            <div
              key={index}
              onClick={() => window.open(item.link, "_blank")}
              className="flex-row align-center  justify-between  relative p-t-12 m-t-10"
              style={{
                borderBottom: "1px solid rgb(195 196 197)",
                minHeight: "35px",
                cursor: "pointer",
              }}
            >
              <div className="p-l-20">
                <CustomText p text={item.label} />
              </div>
              <div className="arrow-icon-right-help">
                <Icon icon={svgs.icon_dropdownRight} width={15} height={15} />
              </div>
            </div>
          ))}
      </>
    );
  }

  function renderChatBot() {
    return (
      <>
        <div
          onClick={() =>
            window.open(
              "https://go.crisp.chat/chat/embed/?website_id=51773738-d935-4511-ae8c-4b55d5e0ab46",
              "_blank"
            )
          }
          className="bottom-tab"
        
        >
          <div className="p-l-20">
        
            <CustomText p text={"Customer Care"} />
          </div>
          <div className="arrow-icon-right-help">
            
            <Icon icon={svgs.icon_dropdownRight} width={15} height={15} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="header-fixed flex gap-10">
        <div
          className="cursor-pointer p-t-3"
          style={{paddingLeft : "10px"}}
          onClick={() => navigate("/settings")}
        >
          <Icon icon={svgs.back_black_icon} width={13} height={13} />
        </div>
        <CustomText p text={"Help and Support"} />
      </div>
      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div style={{paddingTop  : '60px'}} > {renderData()}
        {renderChatBot()} </div>
      )}
    </>
  );
}

export default HelpAndSupport;
