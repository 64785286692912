import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sdk from "../../common/sdk/influencerSdk";
import { CustomText, FallBack } from "../../common/core_component";
import svgs from "../../assets/icons/svgs";
import Utils from "../../common/utils";
import Brandlogo from '../../assets/images/small-logo.png'
import { renderHook } from "@testing-library/react";
import { Loader } from "../Loader";
import InfiniteScroll from "react-infinite-scroll-component";

export default function InActive() {
  const [loading, set_loading] = useState(false);
  const [bidList, setBidList] = useState([]);
  const [selectedBid, setSelectedBid] = useState();
  const [selectedBidData, setSelectedBidData] = useState(null);
  const [bidSummary, setBidSummary] = useState(null);
  const [bidSummaryStatus, setBidSummaryStatus] = useState(null);
  const [next, setNext] = useState(10);
  const [start, setStart] = useState(0);
  const limit = 10; 
  

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const navigate = useNavigate();

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;

  //  ------ APi calls -------
  useEffect(() => {
    getBidList(start, updateProgress);
  }, []);

  function getBidList(start , updateProgress){
    var params = {
      // auth: authToken,
      start: start,
      limit: limit,
    };

    Sdk.getPastBidList(getBidListSuccess, getBidListFailed, updateProgress, params);
  }

  function getBidListSuccess(api_response) {
    console.log("res", api_response);
    const { bid_list } = api_response;

    setBidList((prevList) => [...prevList, ...bid_list]);
    setNext(api_response.pagination_data?.next); 
    // setBidList(api_response?.bid_list)
    
  }
  console.log("past",bidList);

  function updateProgress(status) {
    set_loading(status);
  }

  function getBidListFailed(error_list) {
    console.error("response error bid list", error_list);
  }

 


  //  ----- Render Ui ----- 

  function renderBidCard() {
    
    if (!bidList || bidList.length === 0) {
      return (
        <div className="fallback p-t-12 m-t-15">
          <FallBack
            icon={svgs.folder}
            heading="No Past Proposals Found"
            title="You don't have any past proposals."
          />
        </div>
      );
    }


  const isBarter= bidList.campaign_deep_data?.campaign_data?.collab_type === 'BARTER';

  const reward_value =  bidList.campaign_deep_data?.campaign_data?.reward_value;

  function replaceUnderscoreWithSpace(str) {
    return str.replace(/_/g, ' ');
  }  

    return (
      <div className="m-t-6 container">
        <div className="m-t-12">
          {bidList.map((bid, index) => (
            <div
              key={index}
              className="card-bid-contract m-t-12"
            >
              <div className="card-icon-bid-contract">
                <img
                  src={
                    bid?.brand_deep_data?.brand_data?.image_id
                      ? Utils.generateBrandLogoUrl(
                          bid?.brand_deep_data.brand_data?.org_id,
                          bid?.brand_deep_data?.brand_data?.image_id
                        )
                      : Brandlogo
                  }
                  alt="Brand Logo"
                />
              </div>
              <div className="card-content-campaign">
                <div className="card-title-campaign">
                  {/* 30 is another  parameter of function */}
                  <CustomText
                    h5
                    text={ Utils.truncateText(bid?.campaign_deep_data?.campaign_data.name || "-" ,30)}
                    className="text-13"
                  />
                </div>
                <div className="card-timing-campaign">
                  <CustomText
                    p
                    text ={ 
                      replaceUnderscoreWithSpace(bid?.bid_data?.status)|| "-"
                    }
                    className="text-12"
                  />
                </div>
  
                <div className="card-detals-campaign">
                  <CustomText
                    p
                    text={
                      "Submitted " +
                      Utils.convertUnixToHumanDate(bid?.bid_data?.date_added)
                    }
                    className="text-12 m-t-4"
                  />
                  <CustomText
                    p
                    text={
                      bid?.bid_data.type === 'cash'
                      ?(` ${Utils.formatMoney(bid?.bid_data?.value)} (CASH)`)
                      : bid?.campaign_deep_data?.campaign_data?.reward_value
                    }
                    className="text-12 m-t-4"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  

  return <>
 {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <>
          <div className="m-t-8">
          <InfiniteScroll
                  dataLength={bidList.length} 
                  next={()=> getBidList(next, ()=>{})} 
                  hasMore={ next !== 0 && next !== null && next !== undefined} 
                  loader={""} 
                  scrollThreshold={0.9}
                >
                  
            {renderBidCard()}
                </InfiniteScroll>
            </div>
          
        </>
      )}
    </>;
}
