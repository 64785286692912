import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sdk from "../../common/sdk/influencerSdk";
import { CustomText, FallBack, PrimaryCta } from "../../common/core_component";
import svgs from "../../assets/icons/svgs";
import Utils from "../../common/utils";
import Brandlogo from "../../assets/images/small-logo.png";
import { Loader } from "../Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { BottomSheet } from "react-spring-bottom-sheet";

export default function Active() {
  const [loading, set_loading] = useState(false);
  const [bidList, setBidList] = useState([]);
  const [selectedBid, setSelectedBid] = useState();
  const [selectedBidData, setSelectedBidData] = useState(null);
  const [bidSummary, setBidSummary] = useState(null);
  const [bidSummaryStatus, setBidSummaryStatus] = useState(null);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [updateNeeded, setUpdateNeeded] = useState(false);


  const closeRejectProposalModal = () => setOpenRejectModal(false);

  const openRejectModalHandler = () => setOpenRejectModal(true);

  const closeApproveProposalModal = () => setOpenApproveModal(false);

  const openApproveModalHandler = () => setOpenApproveModal(true);

  const [next, setNext] = useState(10);
  const [start, setStart] = useState(0);

  const limit = 10;

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;

  //  ------ APi calls -------
  useEffect(() => {
    getBidList(start, updateProgress);
  }, []);

  // useEffect(() => {
  //   console.log("Fetching bid list...");
  //   getBidList(start, updateProgress);
  // }, [updateNeeded]);
  

  useEffect(() => {
    if (!Utils.isNullOrUndefined(selectedBidData)) {
      getBidSummaryAmount(selectedBidData);
    }
  }, [selectedBidData]);

  // console.log("bidFata", selectedBidData);

  function getBidList(start, updateProgress) {
    var params = {
      // auth: authToken,
      start: start,
      limit: limit,
    };

    Sdk.getBidList(getBidListSuccess, getBidListFailed, updateProgress, params);
  }

  function getBidListSuccess(api_response) {
    console.log("reshbh", api_response?.bid_list);
    const { bid_list } = api_response;

    if(start == 0){
      setBidList(api_response?.bid_list)
    }else{
      setBidList([...bidList, ...api_response?.bid_list])
    }

  

    setNext(api_response.pagination_data?.next);
  }
  console.log("bids", bidList);

  function getBidListFailed(error_list) {
    console.error("response error bid list", error_list);
  }

  function postBidBoost(bidId) {
    var params = {
      bid_id: bidId,
    };

    Sdk.postBidBoost(
      postBidBoostSuccess,
      postBidBoostFailed,
      updateProgress,
      params
    );
  }

  function postBidBoostSuccess(response) {
    // enqueueSnackbar("Proposal boosted successfully");
    setSelectedBid();
    getBidList();
  }
  // console.log("id", selectedBid);

  function postBidBoostFailed(error_list) {
    console.log("response error bid list", error_list);
    setSelectedBid();
  }

  function postApproveCounter(bidId) {
    var params = {
      bid_id: bidId,
    };

    Sdk.postApproveCounter(
      postApproveCounterSuccess,
      postApproveCounterFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function postApproveCounterSuccess(response) {
    enqueueSnackbar("Counter offer accepted", { variant: "success" });
    setUpdateNeeded(!updateNeeded);
    setSelectedBid("");
    getBidList(start, updateProgress);
    closeApproveProposalModal();
  }

  function postApproveCounterFailed(error_list) {
    setSelectedBid("");
  }

  function postRejectCounter(bidId) {
    var params = {
      bid_id: bidId,
    };

    Sdk.postRejectCounter(
      postRejectCounterSuccess,
      postRejectCounterFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function postRejectCounterSuccess(response) {
    // console.log("Counter offer Rejected", response);
    enqueueSnackbar("Counter offer Rejected", { variant: "info" });
    // setUpdateNeeded(!updateNeeded);
    setSelectedBid("");
    getBidList(start, updateProgress);
    closeRejectProposalModal();
  }

  function postRejectCounterFailed(error_list) {
    setSelectedBid("");
  }

  function updateProgress(status) {
    set_loading(status);
  }

  function getBidSummaryAmount(bid_data) {
    let params = {
      amount: bid_data?.counter_value,
    };

    Sdk.getBidSummaryAmount(
      getBidSummaryAmountSuccess,
      getBidSummaryAmountFailed,
      // updateProgress,
      state => {
        setBidSummaryStatus(state)
      },
      params
    );
  }

  function getBidSummaryAmountSuccess(response) {
    setBidSummary(response["bid_summery"]);
    // console.log("res summ", response);
  }

  function getBidSummaryAmountFailed(error_list) {
    console.error("Error", error_list);
  }

  //  ----- Render Ui -----

  function renderRejectBottomSheet() {
    return (
      <>
        <BottomSheet
          open={openRejectModal}
          onDismiss={closeRejectProposalModal}
          snapPoints={({ maxHeight }) => [0.6 * maxHeight, maxHeight]}
          style={{ overflowY: "auto", zIndex: "1000", position: "absolute" }}
        >
          <div
      style={{
        width: "100%", 
        maxWidth: "786px", 
        margin: "0 auto", 
        paddingTop: " 10px",
      }}
    >
            <div className="text-align">
              <CustomText p text={"Reject Counter Offer "} className="m-t-8" />
            </div>

            <div className="container">
              <CustomText
                p
                text={
                  "Brand manager saw your proposal, If you accept counter offer the proposal amount will be changed to offer amount"
                }
                className="text-14 text-align text-color-p m-t-12"
              />

              <div
                className="m-t-15  text-14 text-color-p"
                style={{ fontWeight: "bolder" }}
              >
                <CustomText
                  p
                  text={`Offered Amount : ${bidSummary?.amount}`}
                  className="m-t-4"
                />
                <CustomText
                  p
                  text={`Platform Charges : ${bidSummary?.platform_charges}`}
                  className="m-t-4"
                />
                <CustomText
                  p
                  text={`Tax amount : ${bidSummary?.tax_amount}`}
                  className="m-t-4"
                />
                <CustomText
                  p
                  text={`Final payout:  ${bidSummary?.final_payout}`}
                  className="m-t-4"
                />
              </div>

              <div className="flex justify-center m-b-8 m-t-40">
                <PrimaryCta
                  text="Reject Offer"
                  className="custom-button"
                  onClick={() => postRejectCounter(selectedBid)}
                  disabled = {bidSummaryStatus}
                  isLoading={effectiveLoad}
                />
              </div>
            </div>
          </div>
        </BottomSheet>
      </>
    );
  }

  function renderApproveBottomSheet() {
    return (
      <>
        <BottomSheet
          open={openApproveModal}
          onDismiss={closeApproveProposalModal}
          snapPoints={({ maxHeight }) => [0.6 * maxHeight, maxHeight]}
          style={{ overflowY: "auto", zIndex: "1000", position: "absolute" }}
        >
          <div
      style={{
        width: "100%", 
        maxWidth: "786px", 
        margin: "0 auto", 
        paddingTop: "10px",
      }}
    >
            <div className="text-align">
              <CustomText p text={" Accept Counter Offer"} className="m-t-8" />
            </div>

            <div className="container">
              <CustomText
                p
                text={
                  "Brand manager saw your proposal, If you accept counter offer the proposal amount will be changed to offer amount"
                }
                className="text-14 text-align text-color-p m-t-12"
              />

              <div
                className="m-t-15  text-14 text-color-p"
                style={{ fontWeight: "bolder" }}
              >
                <CustomText
                  p
                  text={`Offered Amount : ${bidSummary?.amount}`}
                  className="m-t-4"
                />
                <CustomText
                  p
                  text={`Platform Charges : ${bidSummary?.platform_charges}`}
                  className="m-t-4"
                />
                <CustomText
                  p
                  text={`Tax amount : ${bidSummary?.tax_amount}`}
                  className="m-t-4"
                />
                <CustomText
                  p
                  text={`Final payout : ${bidSummary?.final_payout}`}
                  className="m-t-4"
                />
              </div>

              <div className="flex justify-center m-b-8 m-t-40">
                <PrimaryCta
                  text="Accept Offer"
                  className="custom-button"
                  onClick={() => postApproveCounter(selectedBid)}
                  disabled = {bidSummaryStatus}
                  isLoading={effectiveLoad}
                />
              </div>
            </div>
          </div>
        </BottomSheet>
      </>
    );
  }

  function renderBidCard() {
    if (!bidList || bidList.length === 0) {
      return (
        <div className="fallback p-t-12 m-t-15">
          <FallBack
            icon={svgs.folder}
            heading="No Active Proposals Found"
            title="You don't have any active proposals."
          />
        </div>
      );
    }
    function replaceUnderscoreWithSpace(str) {
      return str.replace(/_/g, " ");
    }

    return (
      <div className="m-t-6 container">
        <div className="m-t-12">
          {bidList.map((bid, index) => (
            <React.Fragment key={index}>
              <div className="card-bid-contract m-t-12">
                <div className="card-icon-bid-contract">
                  <img
                    src={
                      bid?.brand_deep_data?.brand_data?.image_id
                        ? Utils.generateBrandLogoUrl(
                            bid?.brand_deep_data.brand_data?.org_id,
                            bid?.brand_deep_data?.brand_data?.image_id
                          )
                        : Brandlogo
                    }
                    alt="Brand Logo"
                  />
                </div>
                <div className="card-content-campaign">
                  <div className="card-title-campaign">
                    {/* 30 is another  parameter of function */}
                   
                    <CustomText
                      h5
                      text={ Utils.truncateText(bid?.campaign_deep_data?.campaign_data.name || "-" ,30)}
                      className="text-13"
                    />
                  </div>
                  <div className="card-timing-campaign">
                    <CustomText
                      p
                      text={
                        replaceUnderscoreWithSpace(bid?.bid_data?.status) || "-"
                      }
                      className="text-12"
                    />
                  </div>

                  <div className="card-detals-campaign">
                    <CustomText
                      p
                      text={
                        "Submitted " +
                        Utils.convertUnixToHumanDate(bid?.bid_data?.date_added)
                      }
                      className="text-12 text-color-p m-t-4"
                    />
                  <CustomText
  p
  text={
    bid?.bid_data.type === "cash"
      ? ` ${isNaN(bid?.bid_data?.value) ? "Rs -" : Utils.formatMoney(bid?.bid_data?.value)} (CASH)`
      : bid?.campaign_deep_data?.campaign_data?.reward_value || "N/A"
  }
  className="text-12 m-t-4"
/>

                   { bid?.bid_data?.is_counter == '1' && bid.bid_data?.counter_value && (
                      <div className="bid-counter-card">
                        <CustomText
                          p
                          text={"Counter offer from Brand"}
                          className="text-color-p text-12"
                        />
                        <CustomText
                          p
                          text={`Rs ${bid?.bid_data?.counter_value ||  "N/A" } `}
                          className=" text-14 m-t-6"
                        />
                        <div className="flex  justify-between align-center">
                          <PrimaryCta
                            className={"bid-btn-counter m-t-8"}
                            onClick={() => {
                              openApproveModalHandler();
                              setSelectedBid(bid?.bid_data.bid_id);
                              setSelectedBidData(bid?.bid_data);
                            }}
                            text={"Accept"}
                          />
                          <button
                            className="bid-btn-counter-reject"
                            onClick={() => {
                              openRejectModalHandler();
                              setSelectedBid(bid?.bid_data.bid_id);
                              setSelectedBidData(bid?.bid_data);
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <>
          <div>
            <InfiniteScroll
              dataLength={bidList.length}
              next={() => getBidList(next, () => {})}
              hasMore={next !== 0 &&  next !== null && next !== undefined}
              loader={""}
              scrollThreshold={0.9}
            >
              {renderBidCard()}
            </InfiniteScroll>
          </div>
          {renderRejectBottomSheet()}
          {renderApproveBottomSheet()}
          {/* <Footer /> */}
        </>
      )}
    </>
  );
}
