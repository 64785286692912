import React, { useCallback, useEffect, useState } from 'react'
import Sdk from '../../common/sdk/influencerSdk';
import { useSnackbar } from 'notistack';
import Icon from '../../assets/icons';
import svgs from '../../assets/icons/svgs';
import { useNavigate } from 'react-router-dom';
import { CustomText, PrimaryCta } from '../../common/core_component';
import { Loader } from '../Loader';
import ReactPullToRefresh from 'react-pull-to-refresh';

function AddressPage(){

  const [addressDetails, setAddressDetails] = useState();
  const [refreshaddress, setRefreshAddress] = useState("");
  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate(); 
  const enqueueSnackbar = useSnackbar(); 


  function updateProgress(status) {
    setLoading(status);
  }
  useEffect(() => {
    getAddressData();
  }, []);


  
  function getAddressData(){
    var params = {
      // address_id: influencerData.address,
    };
    Sdk.getAddressData(
      getAddressDataSuccess,
      getAddressDataFail,
      updateProgress,
      params,
    );
  }
  function getAddressDataSuccess(response) {
    setAddressDetails(response.address_data);
  }
  console.log("details" , addressDetails);

  function getAddressDataFail(error) {
    enqueueSnackbar(error.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  function renderAddress (){
    return (
      <>
        {addressDetails ? (
        <div
        className=""
        
      >
            <div
              className="flex-column white-card-bg flex"
              style={{ gap: "10px", margin: "20px", padding: "15px" }}
            >
              <div className="flex justify-between ">
                <CustomText p text={"Premise"} className = "text-13" />
                <CustomText
                  p
                  text={addressDetails?.address_premise || "-"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>
              <div className="flex justify-between ">
                <CustomText p text={"Street"} className = "text-13" />
                <CustomText
                  p
                  text={addressDetails?.address_street || "-"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>

              <div className="flex justify-between ">
                <CustomText p text={" City"} className = "text-13" />
                <CustomText
                  p
                  text={addressDetails?.address_city || "N/A"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>
              <div className="flex justify-between ">
                <CustomText p text={"State"} className = "text-13" />
                <CustomText
                  p
                  text={addressDetails?.address_state || "-"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>
              <div className="flex justify-between ">
                <CustomText p text={"Country"} className = "text-13" />
                <CustomText
                  p
                  text={addressDetails?.address_country || "-"}
                  className="text-color-p text-12 m-t-4 capitalize"
                />
              </div>

              </div>
            </div>
     
        
        ) : (
          <div className='align-center justify-center flex-column container' style={{height : "84vh"}}>
           
          <CustomText p
          text = {"No Address Found"}
          className = "text-color-p"
/>
            <PrimaryCta
              className="custom-button m-t-12"
              onClick={() => navigate('/add-address')}
              text="Add Address"
            />
          </div>
        )}
      </>
    );
    
  }
  

  return (
    <>
      <div >
     <div className="header-fixed flex justiify-center gap-10">

      <div className="cursor-pointer p-t-3" onClick={ ()=> navigate('/profile')} style={{paddingLeft : "10px"}}>
            <Icon icon={svgs.back_black_icon} width={13} height={13} />
          </div>
        <CustomText
         p 
         text={"Address Details"}/>

  {
    addressDetails ? (
      <div className='arrow-icon-right cursor-pointer'  style={{paddingRight : "20px"}} 
      onClick = { ()=> navigate ('/add-address')} >
        <CustomText 
        p
        text = {"Edit"}/>
      </div>
    ) : ""
  }

      </div>


      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
     

        <div
        className="" style={{paddingTop: '48px' }}>
          <div>{renderAddress()}</div>
        </div>
      )}

       </div>
     

   
    
    </>
  )
}

export default AddressPage
