import React from 'react'
import { CustomText, FallBack, PrimaryCta } from '../../common/core_component'
import svgs from '../../assets/icons/svgs'
import Icon from '../../assets/icons'
import { useNavigate } from 'react-router-dom'


function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="page-center flex-column gap-10" style={{height : "75vh"}}>
      <Icon 
      icon = {svgs.reelaxLogo}
      height={200}
      width={200}
      />
      <div className='m-t-12 m-b-12 text-align'>
      <CustomText
      h3
       text = {"It seems you've hit a wrong URL."} 
       className = "p-t-15"/>
      <CustomText
      p
       text = {"Oops! The page you're looking for doesn't exist"} 
       className = "m-t-6"/>

      </div>
      <div className="container ">
            <PrimaryCta
              className={"custom-button"}
              onClick={() => navigate("/")}
              text={"Go to home screen"}
            />
          </div>
    
      </div>
   
  )
}


export default NotFound
