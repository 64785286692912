import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CustomInputBox,
  CustomText,
  PrimaryCta,
} from "../../common/core_component";
import Sdk from "../../common/sdk/influencerSdk";
import { useSelector } from "react-redux";
import { Loader } from "../Loader";
import Ourlogo from "../../assets/images/small-logo.png";
import Utils from "../../common/utils";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Rating } from "react-simple-star-rating";
import ReactHtmlParser from "html-react-parser";
import { useSnackbar } from "notistack";
import Icon from "../../assets/icons";
import Svgs from "../../assets/icons/svgs";
import * as InfluencerInfo from "../../common/data/influencer";
import ReactPullToRefresh from "react-pull-to-refresh";
import CreditBottom from "../common/CreditBottom";
import LoginOtpScreen from "../common/LoginOtpScreen";

const influencerGuidelines = {
  dos: [
    "Keep checking the Reelax app for proposal approval and updates.",
    "Apply only if you can deliver the content within the specified delivery time.",
    "Ensure the quality of your content meets the platforms standards to avoid rejection.",
    "Clarify any doubts through the contract chat on the Reelax platform.",
    "Maintain professional and responsive communication with brands within the Reelax platform.",
    "Craft a creative cover letter to increase your chances of getting proposals approved.",
  ],
  donts: [
    "Do not share contact information, such as phone numbers or email addresses, outside of Reelax.",
    "Do not accept payments outside of the Reelax platform; all transactions should be processed through the platform.",
    "Avoid using false information in your cover letter or profile; be honest and transparent.",
    "Do not apply for a campaign if you cannot deliver the content with the expected quality.",
  ],
};

function CampaignDetails() {
  const [campaignDeepData, setcampaignDeepData] = useState();
  const [contentList, setContentList] = useState();
  const [bidData, setBidData] = useState();
  const [isCreditBottomSheetVisible, setCreditBottomSheetVisible] =
    useState(false);
  const [isVisible, setVisible] = useState(false);
  const [bidSummary, setBidSummary] = useState({});
  const [loading, setLoading] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [error_message, setErrorMessage] = useState(null);
  const [bid_error_message, setBidErrorMessage] = useState(null);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [bidDataLoading, setBidDataLoading] = useState(false);
  const [isLoginOtpSheet, setisLoginOtpSheet] = useState(false);
  const [fromDetail, setFromDetails] = useState(false);
  const [comingFromProposal, setComingFromProposal] = useState(false);

  const openLoginOtp_Sheet = () => {
    setisLoginOtpSheet(true);
  };

  const openCreditBottomSheet = () => setCreditBottomSheetVisible(true);

  useEffect(() => {
    setBidDataLoading(!bidData);
  }, [bidData]);

  const campaign_id = useParams().id;
  const navigate = useNavigate();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const brand_id = urlParams.get("brand_id");

  console.log("bid Data", bidData);

  const { enqueueSnackbar } = useSnackbar();

  // console.log("Campaign ID:", campaign_id);
  // console.log("Brand ID:", brand_id);

  const showError = (message) => {
    setBidErrorMessage(message);
  };

  function updateProgress(status) {
    setLoading(status);
  }

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });
  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  // console.log("influn", influencer_data);
  // console.log("userData", userData);

  useEffect(() => {
    getCampaignContentListUser();
    getCampaignDataInfluencer();

    // Mark seen after 2 seconds
    setTimeout(() => seenCampaign(), 2000);
  }, []);

  
  

  useEffect(() => {
    if (
      campaignDeepData &&
      !Utils.isEmptyObject(campaignDeepData) &&
      campaignDeepData?.campaign_data?.collab_type !== "BARTER"
    ) {
      setBidData(createBidData());
    }
    
    if (campaignDeepData?.campaign_data?.collab_type === "BARTER") {
      let prevData = createBidData();
      prevData["value"] = campaignDeepData?.campaign_data?.product_price;
      // console.log("prevDara", prevData);
      setBidData(prevData);
    }
  }, [campaignDeepData]);

  // --------- Save Campaign Bid ----------


  function saveCampaignBidData() {
    let is_category_matched = validateCategory();

    if (is_category_matched) {
      console.log("returned from herre ");

      return enqueueSnackbar(
        `You can't send a proposal as you do not belong to the required category`
      );
    }

    if (validateBidAmount(bidData?.value)) {
      var params = {
        bid_data: JSON.stringify(bidData),
        campaign_id: bidData.campaign_id,
      };
      Sdk.saveCampaignBidData(
        saveCampaignBidDataSuccess,
        saveCampaignBidDataFailed,
        (status) => setEffectiveLoad(status),
        params
      );
    }
  }

  function saveCampaignBidDataSuccess(api_response) {
    // props.navigation.navigate("BidSuccess");
    // console.log("save campaign", api_response);
    closeSendProposalModal();
    getCampaignContentListUser();
    enqueueSnackbar("Proposal Successfull", { variant: "success" });
    navigate("/bid-success");
  }

  function saveCampaignBidDataFailed(error_list) {
    // showError(error_list.map((item) => item.m).join(", "));
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
    // console.error("save bid error", error_list);
    closeSendProposalModal();
    // setBidData("");
  }

  //  ---------- Campaign Detail APIs ----------

  function getCampaignDataInfluencer() {
    var params = {
      // brand_id: brand_id,
      campaign_id: campaign_id,
    };
    Sdk.getCampaignDataInfluencer(
      getCampaignDataInfluencerSuccess,
      getCampaignDataInfluencerFailed,
      updateProgress,
      params
    );
  }

  function getCampaignDataInfluencerSuccess(response) {
    setcampaignDeepData(response.campaign_data);
  }
  console.log("campaign ", campaignDeepData);

  function getCampaignDataInfluencerFailed(error_list) {
    // console.log(" Campaign data influencer fail", error);
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  function getCampaignContentListUser() {
    var params = {};

    Sdk.getCampaignContentListUser(
      getCampaignContentListUserSuccess,
      getCampaignContentListUserFailed,
      updateProgress,
      params
    );
  }

  function getCampaignContentListUserSuccess(response) {
    setContentList(response.content_data);
  }
  console.log("content ", contentList);

  function getCampaignContentListUserFailed(error_list) {
    // console.log("contentlist user error", error)
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  // ------ Bid Amount ------

  function seenCampaign() {
  if(!influencer_data.influencer_id){
    return; 
  }
    var params = {
      campaign_id: campaign_id,
    };
    Sdk.seenCampaign(seenCampaignSuccess, seenCampaignFailed, () => {}, params);
  }

  function seenCampaignSuccess(response) {
    console.log("Campaign Marked Seen");
  }

  function seenCampaignFailed(error_list) {
    // console.log("Campaign Marked Seen - Error");
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  function getBidSummary(amount) {
    var params = {
      amount: amount,
    };

    Sdk.getBidSummaryAmount(
      getBidSummaryAmountSuccess,
      getBidSummaryAmountFailed,
      (state) => {
        console.log(state);
      },
      params
    );
  }

  function getBidSummaryAmountSuccess(response) {
    console.log("summary", response);
    setBidSummary(response.bid_summery);
    // setAppLoaded(true);
  }
  // console.log("bid", bidSummary);

  function getBidSummaryAmountFailed(error_list) {
    // console.log("contentlist user error", error);
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  // -------- Bid Valdate --------

  function createBidData() {
    if (!Utils.isEmptyObject(campaignDeepData)) {
      return {
        campaign_id: campaignDeepData.campaign_data.campaign_id,
        type: campaignDeepData?.reward_data?.type,
        proposal_note: " ",
      };
    }
    // return null;
  }

  function validateCategory() {
    const { campaign_data, influencer_data } = campaignDeepData;
    const { is_strict, profession } = campaign_data;
    const { categories } = influencer_data;

    if (is_strict) {
      // now - 0 means false
      if (profession == influencer_data?.profession) {
        return false;
      } else {
        return true;
      }
    }
  }

  const validateBidAmount = (amount) => {
    if (!amount) {
      showError("Invalid proposal amount");
      return false;
    }
    if (campaignDeepData?.campaign_data?.collab_type === "BARTER") {
      return true;
    }
    if (campaignDeepData?.reward_data?.type === "cash") {
      if (isNaN(amount)) {
        showError("Invalid proposal amount");
        return false;
      }
      if (
        Number(amount) > Number(campaignDeepData?.campaign_data?.max_budget)
      ) {
        showError("Proposal amount should be below maximum proposal amount");
        return false;
      }
      if (Number(amount) < 500) {
        showError("Minimum proposal amount is Rs 500");
        return false;
      }
    }
    setBidErrorMessage(null);
    return true;
  };

  function validateAmount(amount) {
    if (isNaN(amount)) {
      setErrorMessage("Please enter a valid amount");
      return false;
    }
    if (Number(amount) < 500) {
      setErrorMessage("Proposal amount should not be below Rs 500");
      return false;
    } else {
      setErrorMessage(null);
      return true;
    }
  }

  function updateAmountinBidData(amount) {
    // console.log("amount", amount);

    if (validateAmount(amount)) {
      setBidData((prev_state) => {
        return {
          ...prev_state,
          // value: amount,
          value: Number(amount),
        };
      });
      getBidSummary(amount);
    }
  }

  function renderAmount(collabType) {
    if (collabType === "BARTER") {
      return <></>;
    }
    return (
      <div className="m-t-16">
        <CustomText
          p
          text={`Amount (Max ${campaignDeepData.campaign_data.max_budget.toFixed(
            0
          )})*`}
          className="text-15 m-b-4"
        />
        <CustomInputBox
          type="number"
          value={bidData?.value}
          placeholder={"Amount"}
          onChange={(e) => {
            updateAmountinBidData(e.target.value);
          }}
        />
        {error_message && <div className="error">{error_message}</div>}
        {bid_error_message && <div className="error">{bid_error_message}</div>}
      </div>
    );
  }

  //  ----------- ui Rendering -----------

  function renderSocialMediaIcon(content_type = []) {
    const platforms = [];
    return content_type.map((item, index) => {
      if (
        item.platform_data &&
        item.platform_data.platform_id &&
        !platforms.includes(item.platform_data.platform_id)
      ) {
        platforms.push(item.platform_data.platform_id);

        const icon = Utils.socialMediaIcons(item.platform_data.platform_id);
        return (
          <div key={index} className="card-icon-campaign flex align-center">
            <Icon
              icon={Utils.socialMediaIcons(item.platform_data.platform_id)}
              height={16}
              width={16}
            />
          </div>
        );
      }
      return null;
    });
  }

  function findContentDetails(id) {
    if (contentList) {
      const contentDetails = contentList.find(
        (values) => values.content_data.content_type_id === id
      );

      if (contentDetails && contentDetails.content_data.platform_id) {
        const platformId = contentDetails.content_data.platform_id;

        return (
          <div
            className=" flex align-center"
            style={{ gap: "4px", alignItems: "center" }}
          >
            <div
              className="icon-container"
              style={{ position: "relative", top: "1px" }}
            >
              {renderSocialMediaIcon([
                { platform_data: { platform_id: platformId } },
              ])}
            </div>
            <div style={{ position: "relative", bottom: "1px" }}>
              <CustomText
                p
                text={
                  " " +
                  contentDetails.content_data.name +
                  " - " +
                  Utils.Capitalize(contentDetails.content_data.media_type)
                }
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
          </div>
        );
      }
    }

    return null;
  }

  function postStory(content_type_deep_data) {
    if (!content_type_deep_data || content_type_deep_data.length === 0) {
      return "N/A";
    }
    //  this code is written by chatgpt ------ for showing "-" if nothing this there

    const content = content_type_deep_data
      .map((item, index) => {
        const details = findContentDetails(item.content_data?.content_type_id);
        return details ? <div key={index}>{details}</div> : null;
      })
      .filter(Boolean);

    return content.length > 0 ? content : "-";
  }

  function renderDeliverable() {
    return (
      <>
        <div className="m-b-5 ">
          <CustomText p text={"Deliverables"} className="text-13 font-600" />
        </div>
        <div
          className="white-card-bg flex-column flex"
          style={{ gap: "10px", border: "1px solid #6E6E6E" }}
        >
          <div className="flex-column " style={{ gap: "5px" }}>
            {postStory(campaignDeepData?.content_type_deep_data) || (
              <CustomText
                p
                text={"-"}
                className="text-color-p text-align text-12 m-t-4"
              />
            )}
          </div>
        </div>
      </>
    );
  }

  function renderCampaignBrandDetails() {
    return (
      <div
        className="white-card-brand-data flex m-t-12"
        style={{ gap: "14px" }}
      >
        <div className="card-icon-campaign flex">
          <img
            src={
              campaignDeepData?.brand_data?.image_id
                ? Utils.generateBrandLogoUrl(
                    campaignDeepData?.brand_data?.org_id,
                    campaignDeepData?.brand_data?.image_id
                  )
                : Ourlogo
            }
            alt="Brand Logo"
          />
        </div>
        <div className="">
          <CustomText p text={campaignDeepData?.brand_data?.name} />
          <div className="flex" style={{ gap: "5px" }}>
            <CustomText
              p
              text={campaignDeepData?.org_data?.org_data?.name}
              className="text-color-p text-12 m-t-4"
            />
          </div>
          <div className="m-t-4">
            <Rating
              initialValue={campaignDeepData?.org_data?.org_data?.ratings}
              size={16}
              readonly={true}
            />
          </div>
        </div>
      </div>
    );
  }

  const renderDisplaySample = () => {
    if (campaignDeepData?.sample_content_data?.length > 0) {
      return (
        <div>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <CustomText p text={"Samples"} className="text-13 font-600" />
          </div>

          <div
            style={{
              marginHorizontal: 16,
              display: "flex",
              flexDirection: "row",
              marginBottom: 6,
              flexWrap: "wrap",
            }}
          >
            {campaignDeepData.sample_content_data.map((item) => {
              const sampleContentUrl = `/image-video-fullscreen?brandId=${campaignDeepData?.campaign_data?.brand_id}&sampleContentId=${item.sample_content_id}`;

              return (
                <div
                  key={item.sample_content_id}
                  onClick={() => navigate(sampleContentUrl)}
                  style={{
                    height: 93,
                    width: 77,
                    marginRight: 5,
                    marginBottom: 10,
                    borderRadius: 8,
                    backgroundColor: "#919191",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={Utils.generateCampaignSampleThumbUrl(
                      campaignDeepData?.campaign_data?.brand_id,
                      item.sample_content_id
                    )}
                    alt="Sample Thumbnail"
                    style={{ height: "100%", width: "100%", borderRadius: 8 }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  function renderCampaignDetails() {
    return (
      <>
        <div className="m-t-b-10">
          <CustomText p text={"Details"} className="text-13 font-600" />
        </div>

        <div
          className="white-card-bg text-end flex-column flex"
          style={{ gap: "10px" }}
        >
          <div className="flex justify-between ">
            <CustomText p text={"Apply Before"} className="text-14 font-400" />
            <CustomText
              p
              text={Utils.formatDateTaskDetails(
                campaignDeepData?.campaign_data?.end_date
              )}
              className="text-color-p text-12 m-t-4"
            />
          </div>
          <div className="flex justify-between ">
            <CustomText p text={"Campaign Type"} className="text-14 font-400" />
            <CustomText
              p
              text={Utils.getCampaignTypeText(
                campaignDeepData?.campaign_data?.type
              )}
              className="text-color-p text-12 m-t-4"
            />
          </div>
          <div className="flex justify-between ">
            <CustomText
              p
              text={"Max Offer Amount"}
              className="text-14 font-400"
            />
            <CustomText
              p
              text={` ${Utils.formatMoney(
                campaignDeepData?.campaign_data?.max_budget.toFixed(0)
              )}`}
              className="text-color-p text-12 m-t-4"
            />
          </div>
        </div>
      </>
    );
  }

  function getProfessionName() {
    let category = Object.keys(
      campaignDeepData?.influencer_data?.categories
    )[0];

    let profession_data = InfluencerInfo.CATEGORIES["PROFESSIONS"][category];
    let name;

    profession_data.forEach((cat) => {
      if (cat.id == campaignDeepData["campaign_data"]["profession"]) {
        name = cat.name;
      }
    });

    return name || "-";
  }

  function renderCampaignEligibility() {
    return (
      <>
        <div className="m-t-b-10">
          <CustomText p text={"Eligibility"} className="text-13 font-600" />
        </div>

        <div
          className="white-card-bg text-end flex-column flex"
          style={{ gap: "10px" }}
        >
          <div className="flex justify-between ">
            <CustomText p text={"Gender"} className="text-14 font-400" />
            <CustomText
              p
              text={campaignDeepData?.audience_data?.gender || "N/A"}
              className="text-color-p text-12 m-t-4 capitalize"
            />
          </div>
          <div className="flex justify-between ">
            <CustomText p text={"Language"} className="text-14 font-400" />
            <CustomText
              p
              text={campaignDeepData?.audience_data?.language?.join(", ")}
              className="text-color-p text-12 m-t-4 capitalize"
            />
          </div>
          <div className="flex justify-between ">
            <CustomText p text={"Categories"} className="text-14 font-400" />
            <CustomText
              p
              text={
                Object.values(
                  campaignDeepData?.influencer_data?.categories || {}
                )
                  .map((category) => category.name)
                  .filter((name) => name)
                  .join(", ") || "N/A"
              }
              className="text-color-p text-12 m-t-4"
            />
          </div>
          <div className="flex justify-between ">
            <CustomText p text={"Profession"} className="text-14 font-400" />
            <CustomText
              p
              text={
                (campaignDeepData?.campaign_data?.profession &&
                  getProfessionName()) ||
                "N/A"
              }
              className="text-color-p text-12 m-t-4 capitalize"
            />
          </div>
          <div className="flex justify-between ">
            <CustomText p text={"Location"} className="text-14 font-400" />
            <CustomText
              p
              text={
                campaignDeepData?.audience_data?.state
                  ? Utils.getCityString(campaignDeepData.audience_data.state)
                  : "N/A"
              }
              className="text-color-p text-12 m-t-4 capitalize"
            />
          </div>
          <div className="flex justify-between ">
            <CustomText p text={"Credits"} className="text-14 font-400" />
            <CustomText
              p
              text={campaignDeepData?.campaign_data?.campaign_credits || "N/A"}
              className="text-color-p text-12 m-t-4 capitalize"
            />
          </div>
        </div>
      </>
    );
  }

  function renderProposals() {
    return (
      <>
        <div className="m-t-b-10">
          <CustomText p text={"Analytics"} className="text-13 font-600" />
        </div>

        <div
          className="white-card-bg flex-column flex"
          style={{ gap: "10px", height: "20px" }}
        >
          <div className="flex justify-between align-center">
            <CustomText p text={"Proposals"} className="text-14 font-400" />
            <CustomText
              p
              text={campaignDeepData?.campaign_data?.bid_count || 0}
              className="text-color-p text-12 m-t-4"
            />
          </div>
        </div>
      </>
    );
  }

  function renderUrls() {
    return (
      <>
        <div className="m-t-b-10">
          <CustomText p text={"URL"} className="text-13 font-600" />
        </div>

        <div
          className="white-card-bg flex-column flex"
          style={{ gap: "10px", borderRadius: "0" }}
        >
          <div className="flex justify-between anchor-tag">
            {campaignDeepData?.campaign_data?.product_url?.trim() ? (
              <a
                target="_blank"
                href={campaignDeepData?.campaign_data.product_url.trim()}
                style={{
                  pointerEvents: "auto",
                  cursor: "pointer",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  color: "#2680EB",
                  textDecoration: "none",
                }}
              >
                {campaignDeepData?.campaign_data.product_url.trim()}
              </a>
            ) : (
              <p style={{ color: "black" }}>N/A</p>
            )}
          </div>
        </div>
      </>
    );
  }

  function renderScript() {
    const textToString = String(
      campaignDeepData?.instruction_data?.instruction_note || "N/A"
    );
    // .trim();
    // react-html-parser only take Strings

    return (
      <>
        <div className="m-t-b-10">
          <CustomText
            p
            text={"Scripts and Instructions"}
            className="text-13 font-600"
          />
        </div>

        <div
          className="white-card-bg flex-column"
          style={{ borderRadius: "0" }}
        >
          <div className="flex">
            <CustomText
              p
              text={ReactHtmlParser(textToString)}
              className="text-color-p flex-wrap subtitle1"
            />
          </div>
        </div>
      </>
    );
  }

  // --------- Send Proposals Condtions ---------

  function BidSummaryData() {
    return (
      <div style={{ marginTop: "10px" }}>
        {bidSummary.amount >= 100 && (
          <>
            <div style={{ marginBottom: "10px" }}>
              <CustomText
                p
                text="Proposal Summary"
                className="text-dark font-600"
              />
            </div>

            <CustomText
              p
              text={`You will receive: Rs ${bidSummary.final_payout.toFixed(
                2
              )}`}
              className="text-dark text-14"
            />

            <CustomText
              p
              text={`Platform Charges: Rs ${bidSummary.platform_charges.toFixed(
                2
              )}`}
              className="text-dark text-14"
            />

            <CustomText
              p
              text={`Taxes: Rs ${bidSummary.tax_amount.toFixed(2)}`}
              className="text-dark text-14"
            />
          </>
        )}
      </div>
    );
  }

  function renderCreditAmount() {
    return (
      <>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <CustomText
            p
            text={`You will receive
   ${campaignDeepData?.reward_data?.value} credits if you complete the contract on time.`}
            className="text-dark "
          />
        </div>
      </>
    );
  }

  function renderFooterBid() {
    return (
      <div style={{ marginTop: "20px", width: "100%" }}>
        {campaignDeepData?.campaign_data.product_url ? (
          <div style={{ marginTop: 10 }}>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <PrimaryCta
                text="Submit Proposal"
                onClick={() => saveCampaignBidData()}
                //  disabled = {bidData}
                isLoading={effectiveLoad}
                className="custom-button m-t-20 m-b-20"
              />
              <CustomText p text={"Terms"} className="m-b-5 font-600 opacity" />

              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="1. Once you get the contract you need to purchase the product from the given Product URL link"
                />
              </div>
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text={`2. On completion of the contract you will receive extra cashback of ${Utils.formatMoney(
                    campaignDeepData?.campaign_data?.product_price
                  )}`}
                />
              </div>
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="3. Make sure you order the same product and from the given platform only."
                />
              </div>
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="4. Accepting or Rejecting the proposal is totally brand's decision."
                />
              </div>
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="5. Read the Eligibility criteria and instructions before sending proposals, after getting the contract if you deny your account can get banned."
                />
              </div>
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="6. By sending the proposal you are accepting all the terms."
                />
              </div>
            </div>
            <div className="page-center"></div>
          </div>
        ) : (
          <div style={{ marginTop: 10 }}>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <PrimaryCta
                text="Submit Proposal"
                onClick={() => saveCampaignBidData()}
                isLoading={effectiveLoad}
                className="custom-button m-t-20 m-b-20"
              />
              <CustomText p text={"Terms"} className="m-b-5 opacity font-600" />
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="1. Accepting or Rejecting the proposal is totally brand's decision."
                />
              </div>
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="2. Once you get the contract or counter offer you will be notified."
                />
              </div>
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="3. Read the Eligibility criteria and instructions before sending proposals, after getting the contract if you deny your account can get banned."
                />
              </div>
              <div className="text-13 text-dark">
                <CustomText
                  p
                  text="4. By sending the proposal you are accepting all the terms."
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderErrorBottomSheet(errorData) {
    return (
      <div
        className="flex justify-center align-center flex-column"
        style={{
          marginTop: "30px",
        }}
      >
        <div
        // style={{ marginTop: 10 }}
        >
          <CustomText h3 text={errorData.title} className="" />
        </div>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <CustomText
            p
            text={errorData.message}
            className="text-color-p text-align text-16 m-t-4"
          />
        </div>
        <PrimaryCta
          text={errorData.ctaText}
          // fullWidth
          onClick={errorData.ctaAction}
          className="custom-button"
        />
      </div>
    );
  }

  function renderBidForm() {
    return (
      <div>
        <div className="m-t-6">
          <CustomText
            p
            text={"Send Proposal"}
            className="text-align font-600 text-dark Proposals"
          />
        </div>
        {campaignDeepData?.reward_data?.type === "cash" &&
          renderAmount(campaignDeepData.campaign_data.collab_type)}
        {campaignDeepData?.reward_data?.type === "credit" &&
          renderCreditAmount()}

        {campaignDeepData.campaign_data.collab_type &&
          campaignDeepData.campaign_data.collab_type !== "BARTER" &&
          BidSummaryData()}
        {renderFooterBid()}
      </div>
    );
  }

  function renderBidUI() {
    if (
      userData?.influencer_data?.credits <
      campaignDeepData.campaign_data?.campaign_credits
    ) {
      let errorData = {
        title: "Not Enough Credits",
        message:
          "You need atleast " +
          campaignDeepData.campaign_data?.campaign_credits +
          " credits to send a proposal, you can purchase credits from profile screen",
        ctaText: "Purchase credit",

        ctaAction: () => {
          openCreditBottomSheet();
          setModalOpen(false);
        },
      };
      return renderErrorBottomSheet(errorData);
    }

    if (userData?.platforms_data) {
      if (userData?.platforms_data.length == 0) {
        let errorData = {
          title: "No Platform connected",
          message:
            "Please connect atleast one social account to send proposal.",
          ctaText: "Connect Account",

          ctaAction: () => {
            navigate("/social-channel", {
              state: { from: true },
            });
          },
        };
        return renderErrorBottomSheet(errorData);
      } else {
        var isVerified = false;
        var totalFollowers = 0;
        userData?.platforms_data.map((item) => {
          if (item.is_verified) {
            isVerified = true;
            totalFollowers = totalFollowers + Number(item.followers);
          }
        });

        if (!isVerified) {
          let errorData = {
            title: "No Platform Verified",
            message: "Your Social platform is not verified",
            ctaText: "Verify Account",

            ctaAction: () => {
              navigate("/social-channel", {
                state: { from: true },
              });
            },
          };
          return renderErrorBottomSheet(errorData);
        } else {
          if (
            campaignDeepData.influencer_data &&
            campaignDeepData.influencer_data.minimum_followers
          ) {
            if (
              totalFollowers <
              Number(campaignDeepData.influencer_data.minimum_followers)
            ) {
              let errorData = {
                title:
                  "Minimum " +
                  campaignDeepData.influencer_data.minimum_followers +
                  " Followers Required",
                message:
                  "Your total followers should be greater than Minimum followers count, You can try other campaigns",
                ctaText: "Add New Social Account",

                ctaAction: () => {
                  navigate("/social-channel", {
                    state: { from: true },
                  });
                },
              };
              return renderErrorBottomSheet(errorData);
            }
          }
        }
      }
    }

    // If infleuncer invited no need to check valdiations
    // if (isInvited) {
    //   return renderBidForm();
    // }
    // uncomment this after checking

    if (
      userData?.influencer_data &&
      userData?.influencer_data?.language &&
      campaignDeepData.audience_data?.language &&
      !campaignDeepData.audience_data?.language.includes(
        userData?.influencer_data?.language
      )
    ) {
      let errorData = {
        title: "Language not matched",
        message:
          "Brand is looking for Influencer with specific language, check campaign details first",
        ctaText: "Change Language",
        ctaAction: () => {
          navigate("/edit-profile", {
            state: { from: true },
          });
        },
      };
      return renderErrorBottomSheet(errorData);
    }

    if (
      userData?.influencer_data &&
      campaignDeepData?.audience_data?.state &&
      campaignDeepData?.audience_data?.state !== "null"
    ) {
      if (
        !userData?.influencer_data?.state_id ||
        !(
          JSON.parse(campaignDeepData?.audience_data?.state).includes(
            Number(userData?.influencer_data?.state_id)
          ) ||
          JSON.parse(campaignDeepData?.audience_data?.state).includes(
            Number(userData?.influencer_data?.city_id)
          )
        )
      ) {
        let errorData = {
          title: "Location not matched",
          message:
            "Brand is looking for Influencer with specific city or state, check campaign details first",
          ctaText: "Change City",
          ctaAction: () => {
            navigate("/edit-profile", {
              state: { from: true },
            });
          },
        };
        return renderErrorBottomSheet(errorData);
      }
    }

    // comment here
    if (
      Utils.isEmpty(userData?.influencer_data?.profession) ||
      Utils.isNullOrUndefined(userData?.influencer_data?.profession)
    ) {
      let errorData = {
        title: "Profession not matched",
        message: "You need to add your profession to send proposal",
        ctaText: "Add Profession",

        ctaAction: () => {
          navigate("/profession", {
            state: { from: true },
          });
        },
      };
      return renderErrorBottomSheet(errorData);
    }

    if (
      campaignDeepData?.audience_data?.gender !== "all" &&
      campaignDeepData.audience_data?.gender !==
        userData?.influencer_data?.gender
    ) {
      let str =
        campaignDeepData.audience_data?.gender == "female"
          ? "females"
          : "males";

      let errorData = {
        title: "Gender not matched",
        message: `Brand is running this campaign for ${str} only`,
        ctaText: "Explore other campaigns",
        ctaAction: () => {
          navigate("/campaigns");
          // alert("Go back ");
        },
      };

      return renderErrorBottomSheet(errorData);
    }

    return renderBidForm();
  }

  const openSendProposalModal = () => {
    if (!Utils.influencerLogined_Or_Not(influencer_data.influencer_id)) {
      setisLoginOtpSheet(true);
      setComingFromProposal(true);
    } else {
      setModalOpen(true);
    }
  };

  function closeSendProposalModal() {
    setModalOpen(false);
  }

  function renderDos() {
    return (
      <>
        <div className="m-t-b-10">
          <CustomText p text={"Do's"} className="text-13 font-600" />
        </div>
        {influencerGuidelines.dos.map((data, index) => (
          <div
            key={index}
            className="do-donot-text text-color-p  "
            style={{ borderRadius: "0" }}
          >
            <CustomText p text={data} />
          </div>
        ))}
      </>
    );
  }

  function renderDonts() {
    return (
      <>
        <div className="m-t-b-10">
          <CustomText p text={"Dont's"} className="text-13 font-600" />
        </div>
        {influencerGuidelines.donts.map((data, index) => (
          <div
            key={index}
            className="do-donot-text text-color-p"
            style={{ borderRadius: "0" }}
          >
            <CustomText p text={data} />
          </div>
        ))}
      </>
    );
  }


  return (
    <>
      <div className="">
        {loading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <>
            {/* -------- Render Main Heading here  */}
            <div className="header-fixed flex justiify-center gap-10">
              <div
                className="cursor-pointer p-t-3"
                onClick={() => navigate("/campaigns")}
                style={{ paddingLeft: "10px" }}
              >
                <Icon icon={Svgs.back_black_icon} width={13} height={13} />
              </div>
              <CustomText p text={campaignDeepData?.campaign_data?.name} />
            </div>

            <div className="bg-app-gray m-b-75">
              <div className="container p-t-60" style={{ paddingTop: "58px" }}>
                {renderCampaignBrandDetails()}
                {/* -------- Campaign Details------ */}
                {renderCampaignDetails()}
                {/* --------- Campaign Eligibility --- */}
                {renderCampaignEligibility()}
                {/* -------- Campaign Proposals -------- */}
                {renderProposals()}
                {/* ------- Campaign Delivery ------- */}
                {renderDeliverable()}
                {/* -------- Campaign URL -------- */}
                {renderUrls()}
                {/* -------- Campaign Scripts -------- */}
                {renderScript()}
                {/* -------- Render Sample -------- */}
                {renderDisplaySample()}
                {/* ------ DO and Don't -------- */}
                {renderDos()}
                {renderDonts()}
              </div>
              {/* --------- Button -------- */}
              <div className="btn-container-details-page">
                <PrimaryCta
                  className={"btn-bottom-detail-page"}
                  onClick={openSendProposalModal}
                  text={"Send Proposal"}
                  disabled={bidDataLoading}
                />
              </div>
            </div>

            {/* --------- Bottom Modal --------- */}
            <BottomSheet
              open={isModalOpen}
              onDismiss={closeSendProposalModal}
              snapPoints={({ maxHeight }) => [0.75 * maxHeight, maxHeight]}
              style={{
                overflowY: "auto",
                zIndex: "1000",
                position: "absolute",
              }}
            >
              <div
                style={{
                  width: "90%",
                  maxWidth: "786px",
                  margin: "0 auto",
                  padding: "20px",
                }}
              >
                {campaignDeepData && renderBidUI()}
              </div>
            </BottomSheet>

            {/* ------- Login Sheet ------  */}
            {isLoginOtpSheet && (
              <LoginOtpScreen
                isModalOpen={isLoginOtpSheet}
                setModalOpen={setisLoginOtpSheet}
                CampaginModalOpen={setModalOpen}
              />
            )}

            <CreditBottom
              isModalOpen={isCreditBottomSheetVisible}
              setModalOpen={setCreditBottomSheetVisible}
            />
          </>
        )}
      </div>
    </>
  );
}

export default CampaignDetails;
