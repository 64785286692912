import React from 'react'

function NotificationsScreen() {
  return (
    <div>
      NotificationsScreen .....
    </div>
  )
}

export default NotificationsScreen
