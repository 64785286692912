import React, { useEffect, useState } from "react";
import Sdk from "../../../common/sdk/influencerSdk";
import { useNavigate, useParams } from "react-router-dom";
import { CustomText, FallBack, PrimaryCta } from "../../../common/core_component";
import { Loader } from "../../Loader";
import Brandlogo from "../../../assets/images/small-logo.png";
import Utils from "../../../common/utils";
import Icon from "../../../assets/icons";
import svgs from "../../../assets/icons/svgs";
import MessageScreen from "./MessageScreen";
import { useSnackbar } from "notistack";

function ContractDetails() {
  const assignment_id = useParams().id;
  //  console.log("id", assignment_id);

  const [assignmentData, setAssignmentData] = useState();
  const [contentList, setContentList] = useState();
  const [isLoading, setIsLoding] = useState(false);
  const [contentUrl, setContentUrl] = useState();
  const [contentId, setContentId] = useState();

  const [activeTab, setActiveTab] = useState("detail");

  const handleOpenClick = () => {
    setActiveTab("detail");
  };

  const handleCloseClick = () => {
    setActiveTab("message");
  };

  useEffect(() => {
    refreshAssignment();
  }, []);

  function refreshAssignment() {
    getCampaignContentListUser();
    getAssignmentData();
  }

  // get Assignment Data start
  function getAssignmentData() {
    var params = {
      assignment_id: assignment_id,
    };
    Sdk.getAssignmentData(
      getAssignmentDataSuccess,
      getAssignmentDataFailed,
      updateProgress,
      params
    );
  }
  function getAssignmentDataSuccess(response) {
    setAssignmentData(response.assignment_deep_data);
  }
  console.log("agm", assignmentData);

  function getAssignmentDataFailed(error) {
    console.error(" get assignment fail");
  }

  // get Assignment Data start
  function startAssignment() {
    var params = {
      assignment_id: assignment_id,
    };
    Sdk.startAssignment(
      startAssignmentSuccess,
      startAssignmentFailed,
      updateProgress,
      params
    );
  }
  function startAssignmentSuccess(response) {
    getAssignmentData();
  }

  function startAssignmentFailed(error) {
    console.error("Start Assignment");
  }

  //  get Campaign Content List User start
  function getCampaignContentListUser() {
    var params = {};
    Sdk.getCampaignContentListUser(
      getCampaignContentListUserSuccess,
      getCampaignContentListUserFailed,
      updateProgress,
      params
    );
  }

  function getCampaignContentListUserSuccess(response) {
    console.log("contentlist user success", response);
    setContentList(response.content_data);
  }
  console.log("lst", contentList);

  function getCampaignContentListUserFailed(error) {
    console.error("contentlist user error", error);
  }
  function updateProgress(status) {
    setIsLoding(status);
  }

  const {enqueueSnackbar} = useSnackbar();

  // const downloadAppNavigatorBTN = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  //   if(/android/i.test(userAgent)) {
  //     // Redirect to Play Store
  //     window.location.href = "https://play.google.com/store/apps/details?id=com.reelax.influencer";
  //   } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     window.location.href = "https://apps.apple.com/in/app/reelax-influencer-app/id6464481879";
  //   }
  //    else {
  //    enqueueSnackbar("Unsupported platform. Please visit on iOS or Android.");
  //   }
  // };

  const downloadAppNavigatorBTN = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // console.log("Detected userAgent:", userAgent); 
  
    if (/android/i.test(userAgent)) {
    
      window.location.href = "https://play.google.com/store/apps/details?id=com.reelax.influencer";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
   
      window.location.href = "https://apps.apple.com/in/app/reelax-influencer-app/id6464481879";
    } else {
      // console.log("Unsupported platform detected.");

      enqueueSnackbar("Unsupported platform. Please visit on iOS or Android.");
    }
  };
  
 
  const navigate = useNavigate(); 



  //  Render Ui -----

  function contractHeadScreen() {
    return (
      <>
        {assignmentData ? (
          <div className="m-t-20">
            <div className="white-card-bg flex gap-20" style={{ gap: "15px" }}>
              <div className="card-icon-campaign">
                <img
                  src={
                    assignmentData?.brand_data?.image_id
                      ? Utils.generateBrandLogoUrl(
                          assignmentData?.brand_data?.org_id,
                          assignmentData?.brand_data?.image_id
                        )
                      : Brandlogo
                  }
                  alt="Brand Logo"
                />
              </div>
              <div className="">
                <CustomText
                  p
                  text={assignmentData?.assignment_data?.name}
                  className="text-16"
                />
                <div className="" style={{ gap: "5px" }}>
                  <CustomText
                    p
                    text={ " Contract Id : #" + assignmentData?.assignment_data?.assignment_id.toUpperCase()}
                    className="text-color-p text-14 m-t-6"
                  />
                  <CustomText
                    p
                    text={assignmentData?.brand_data?.name}
                    className="text-color-p text-14 m-t-6"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderCollabDetails() {
    const renderBudget = (reward_type, amount) => {
      if (reward_type == "cash") {
        return Utils.formatMoney(amount);
      } else {
        return amount + " Credits";
      }
    };
    return (
      assignmentData && (
        <>
          <div className="m-b-4 m-t-8">
            <CustomText h5 text={"Collab Requirement"} />
          </div>

          <div
            className="white-card-bg flex-column flex"
            style={{ gap: "10px" }}
          >
            <div className="flex justify-between ">
              <CustomText h5 text={"Delivery Date"} />
              <CustomText
                p
                text={
                  Utils.formatDateTaskDetails(
                    assignmentData?.assignment_data?.delivery_date
                  ) || "-"
                }
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
            <div className="flex justify-between ">
              <CustomText h5 text={"Status"} />
              <CustomText
                p
                text={assignmentData?.assignment_data?.assignment_status || "-"}
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
            <div className="flex justify-between ">
              <CustomText h5 text={"Campaign Type"} />
              <CustomText
                p
                text={Utils.getCampaignTypeText(
                  assignmentData?.assignment_data?.type || "-"
                )}
                className="text-color-p text-12 m-t-4"
              />
            </div>
            <div className="flex justify-between ">
              <CustomText h5 text={"Payment Type"} />
              <CustomText
                p
                text={assignmentData?.reward_data?.reward_type || "-"}
                // text={
                //  assignmentData?.assignment_data || "N/A"
                // }
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
            <div className="flex justify-between ">
              <CustomText h5 text={"Reward"} />
              <CustomText
                p
                text={
                  renderBudget(
                    assignmentData?.reward_data?.reward_type,
                    assignmentData?.reward_data?.value?.amount
                  ) || "-"
                }
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
          </div>
        </>
      )
    );
  }

  function contractDetailsScreen() {
    return (
      <>
        {contractHeadScreen()}
        {renderCollabDetails()}
        {renderDeliverable()}
      </>
    );
  }

  function findContentDetails(id) {
    if (contentList) {
      const contentDetails = contentList.find(
        (values) => values.content_data.content_type_id === id
      );
      if (contentDetails && contentDetails.content_data.platform_id) {
        return (
          <div className="flex justify-between" style={{ gap: "10px" }}>
            {/* <Icon
              icon={svgs.Utils.socialMediaIcons(
                contentDetails.content_data.platform_id
              )}
              height={17}
              width={17}
            /> */}
            <CustomText
              p
              text={
                " " +
                contentDetails.content_data.name +
                " - " +
                Utils.Capitalize(contentDetails.content_data.media_type)
              }
              className="text-14 m-t-4 capitalize"
            />
          </div>
        );
      }
    }
    return null;
  }

  
  function postStory(contents_deep_data = {}) {
  const dataArray = Object.values(contents_deep_data);

  if (!Array.isArray(dataArray) || dataArray.length === 0) {
    console.log("Returning '-' because contents_deep_data is either not an array or is empty");
    return "-";
  }

  const content = dataArray
      .map((item, index) => {
        const details = findContentDetails(item.content_data?.content_type_id);
        return details ? <div key={index}>{details}</div> : null;
      })
      .filter(Boolean);  
    // console.log("Mapped content:", content);
    return content.length > 0 ? content : "-";
  }


  function renderDeliverable() {
    return (
      <>
        <div className="m-t-8 m-b-5">
        
          <CustomText h5 text={"Deliverables"} />
        </div>
        <div className="white-card-bg  flex" style={{ gap: "10px" }}>
          {" "}
          <div className="flex align-center justify-center">
            {" "}
            <div className="flex justify-between">
            {postStory(assignmentData?.contents_deep_data)}

            </div>{" "}
          </div>{" "}
        </div>{" "}
      </>
    );
  }

  function renderData() {
    if (activeTab === "detail") {

      return <div className="container " style={{ paddingBottom: "65px"}}>
     {contractDetailsScreen()}
      </div> 
    } else if (activeTab === "message"){
      return <MessageScreen/>

    }
  }

  return (
    <div className='bg-app-gray'>
      <div className="header-fixed flex  gap-10">
        <div
          className="cursor-pointer p-t-3"
          onClick={() => navigate("/contract")}
          style={{paddingLeft : "10px"}}
        >
          <Icon icon={svgs.back_black_icon} width={13} height={13} />
        </div>
        <CustomText p text={"Contract Details"} />
      </div>
      
      {isLoading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div style={{paddingTop: "45px"}}>
          <div className='m-t-20'>
            <div className="fallback">
              <FallBack
                icon={svgs.reelaxLogo}
                heading="Download Reelax App"
                title=" For Contract details Download our app"
              />
            <div className='container page-center' style={{ marginTop: '12px', marginBottom : "23px" }}>
              <PrimaryCta
                className={"custom-button"}
                onClick={() => downloadAppNavigatorBTN()}
                text={"Download  Reelax App"}
              />
            </div>
            </div>
          </div>
          {/* {renderData()} */}
        </div>
      )}
    </div>
  );
  
}

export default ContractDetails;
