import React, { useState } from "react";
import Styles from "./style.js";
import Influencer from "./influencer.js";
import Icon from "../../assets/icons.js";
import { Loader } from "../../screens/Loader.js";
import BtnLoader from "../../screens/common/BtnLoader.js";



export const Core = {
  Influencer: Influencer,
};

export const CustomText = function (props) {
  function textStyle(props) {
    var style;
    if (props.textColor) {
      style = Styles[props.textColor];
    }
    if (props.fontSize) {
      style = { ...style, ...Styles[props.fontSize] };
    }
    if (props.fontWeight) {
      style = { ...style, ...Styles[props.fontWeight] };
    }
    if (props.textAlign) {
      style = { ...style, ...Styles[props.textAlign] };
    }
    if (props.width) {
      style = { ...style, ...Styles[props.width] };
    }
    if (props.height) {
      style = { ...style, ...Styles[props.height] };
    }
    if (props.lineHeight) {
      style = { ...style, ...Styles[props.lineHeight] };
    }
    if (props.noOfLine) {
      style = { ...style, ...Styles[props.noOfLine] };
    }
    if (props.ellipsis) {
      style = { ...style, ...Styles["ellipsisText"] };
    }
    return style;
  }

  // Define the class name based on props
  let className = props.className ? props.className : "";

  if (props.h1) {
    return <h1 style={textStyle(props)}>{props.text}</h1>;
  } else if (props.h2) {
    return <h2 style={textStyle(props)}>{props.text}</h2>;
  } else if (props.h3) {
    return <h3 style={textStyle(props)}>{props.text}</h3>;
  } else if (props.h4) {
    return <h4 style={textStyle(props)}>{props.text}</h4>;
  } else if (props.h5) {
    return (
      <h5 className={className} style={textStyle(props)}>
        {props.text}
      </h5>
    );
  }
  if (props.h6) {
    return <h6 style={textStyle(props)}>{props.text}</h6>;
  } else if (props.label) {
    return <label style={textStyle(props)}>{props.text}</label>;
  } else if (props.p) {
    return (
      <p className={className} style={textStyle(props)}>
        {props.text}
      </p>
    );
  }
};

export const CustomSelectBox = ({ value, onChange, options, placeholder }) => {
  return (
    <select value={value} onChange={onChange} className="custom-select-box">
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const CustomTextArea = ({ value, onChange, placeholder }) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="custom-text-area"
    />
  );
};
export const CustomInputBox = ({
  type = "text",
  value,
  onChange,
  placeholder,
  disabled = false,
  autoFocus = false
}) => {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="custom-input-box"
      disabled={disabled} 
      autoFocus={autoFocus} 
    />
  );
};


export const PrimaryCta = function (props) {
  var style = Styles.primaryCtaContainer;
  var textColor = "text_color_light";
  var loaderColor = props.sixth ? "#212529" : "#fff"; // Set loader color to black if 'sixth' prop is passed

  if (props.fullWidth) {
    style = Styles.fullWidthPrimaryCtaContainer;
  }
  if (props.fullWidth100) {
    style = Styles.fullWidthPrimaryCtaContainer100;
  }

  if (props.disabled) {
    style = { ...style, ...Styles.primaryCtaDisabledContainer };
  }
  if (props.secondary) {
    style = { ...style, ...Styles.primaryCtaSecondaryContainer };
  }
  if (props.invert) {
    textColor = props.textColor ? props.textColor : "text_color_primary";
    style = { ...style, ...Styles.primaryCtaInvertContainer };
  }
  if (props.third) {
    style = {
      ...style,
      ...Styles.primaryCtaThirdContainer,
    };
  }
  if (props.borderCta) {
    style = {
      ...style,
      ...Styles.primaryCtaBorderContainer,
    };
  }

  if (props.fourth) {
    textColor = "text_color_black";
    style = { ...style, ...Styles.primaryCtaFourthContainer };
  }
  if (props.fifth) {
    textColor = "text_color_light";
    style = { ...style, ...Styles.primaryCtaFifthContainer };
  }
  if (props.sixth) {
    textColor = "Theme.colors.TC_BACK";
    style = {
      ...style,
      ...Styles.primaryCtaSixthContainer,
    };
    loaderColor = "#212529"; // Set loader color to black if 'sixth' prop is passed
  }
  if (props.white) {
    style = {
      ...style,
      ...Styles.primaryCtaWhiteContainer,
    };
  }
  if (props.App) {
    style = {
      ...style,
      ...Styles.primaryAppContainer,
    };
  }

  return (
    <button
      className={`${props.className}`}
      style={style}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {!props.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.iconName && (
              <div style={{ marginTop: "4px", marginRight: "6px" }}>
                <Icon iconName={props.iconName} height={12} width={12} />
              </div>
            )}

            <CustomText
              p
              className="button-text"
              text={props.text}
              textColor={props.textColor ? props.textColor : textColor}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 2,
            paddingBottom: 2,
            color: loaderColor, // Use loader color from the prop
            fontSize: 15,
          }}
        >
          <BtnLoader loaderColor={loaderColor} /> {/* Pass the color to the loader */}
        </div>
      )}
    </button>
  );
};


export const CustomButton = ({
  className,
  onClick,
  label,
  disabled,
  isLoading,
}) => {
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
    >
      {label}
    </button>
  );
};

export const FallBack = function (props) {
  return (
    <div style={{ textAlign: "center", alignItems: "center", paddingTop : "20px", maxWidth: 600 }}>
      <Icon icon={props.icon} width={90} height={70} />
      <div className="flex-column" style={{ padding: 20 }}>
        <CustomText
          p
          fontSize={"font_size_h4"}
          text={props.heading}
          textColor={"text_color_primary"}
        />
        <div className="m-t-4">
          <CustomText
            p
            fontSize={"font_size_p"}
            text={props.title}
            textColor={"text_color_secondary"}
          />
        </div>

        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.subTitle}
          textColor={"text_color_secondary"}
        />
      </div>
    </div>
  );
};

export const FallBackBtn = function (props) {
  return (
    <div style={{ textAlign: "center", alignItems: "center" }}>
      <Icon
        iconName={props.icon}
        width={props.widthIcon}
        height={props.heightIcon}
      />
      <div className="flex-column" style={{ padding: 20 }}>
        <CustomText
          h1
          fontSize={"font_size_xl"}
          text={props.heading}
          textColor={"text_color_primary"}
        />
        <div style={{ marginTop: 10 }}>
          <CustomText
            p
            fontSize={"font_size_small"}
            text={props.title}
            textColor={"text_color_secondary"}
          />
        </div>

        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.subTitle}
          textColor={"text_color_secondary"}
        />
      </div>
      {props.text && (
        <div className="">
          <PrimaryCta
            fifth
            onClick={props.onClick}
            fontSize={"font_size_large"}
            text={props.text}
            isLoading={props.isLoading}
          />
        </div>
      )}
    </div>
  );
};