import React, { useState } from "react";
import {
  CustomInputBox,
  CustomTextArea,
  PrimaryCta,
} from "../common/core_component/index.js";
import Select from "react-dropdown-select";
import 'react-day-picker/dist/style.css';
import DatePicker from "react-datepicker";
import Utils from "../common/utils.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLocalUserData } from "../common/storage/storage.js";
import * as Action from "../redux/actions.js";
import Sdk from "../common/sdk/influencerSdk.js";
import { enqueueSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import 'react-day-picker/dist/style.css';



function InfluencerDataScreen() {
  const currentDate = new Date(new Date().setDate(new Date().getDate() - 6570));

  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(Utils.formatDateObj(currentDate));
  const [gender, setGender] = useState(null);
  const [bio, setBio] = useState("");
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    dob: "",
    gender: "",
    bio: "",
  });

  const location = useLocation();
  const {returnUrl = "/" } = location.state || {};
  

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });
  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;

  const options = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  function updateGender(selectedOptions) {
    const selectedValue =
      selectedOptions && selectedOptions.length > 0
        ? selectedOptions[0].value
        : null;
    setGender(selectedValue);
  }

  function validateForm() {
    let isValid = true;
    let newErrors = { fullName: "", email: "", dob: "", gender: "", bio: "" };

    if (!fullName.trim()) {
      newErrors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!dob) {
      newErrors.dob = "Date of Birth is required";
      isValid = false;
    }

    if (!gender) {
      newErrors.gender = "Gender is required";
      isValid = false;
    }

    if (!bio.trim()) {
      newErrors.bio = "Bio is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  }
  function onBoardInfluencer() {
    const isFormValid = validateForm();
    let params;
    if (!isFormValid) {
      return; 
    }
      const { influencer_id } = influencer_data;
      params = {
        influencer_id: influencer_id,
        name: fullName,
        email: email,
        gender: gender,
        note: bio,
        dob: Utils.formatDateIntoMDY(dob),
      
    }
    Sdk.onboardInfluencer(
      onBoardInfluencerSuccess,
      onBoardInfluencerFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function onBoardInfluencerSuccess(response) {
    const { influencer_deep_data } = response;
    dispatch({
      type: Action.UPDATE_USER_DATA,
      payload: influencer_deep_data,
    });

    setLocalUserData(response); // Save data in async storage
    navigate("/about-work");
  }

  function onBoardInfluencerFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "), {variant : "error"})
  }

  function updateProgress(status) {
    setLoading(status);
  }

  const handleInputChange = (info) => (e) => info(e.target.value);

  return (
    <div className="m-t-15 ">
      <div className="container">
        <div className="flex align-center m-b-8" style={{ gap: "8px" }}>
          
          <h5 className="">Step 1</h5>
        </div>
        <div>
          <h2> General Information</h2>
        </div>
        <div className=" m-t-8 progress-bar">
          <div className="bar1"></div>
          <div className="bar0"></div>
        </div>

        <div>
          {/* Full Name */}
          <div className="m-t-12">
            <p className="font-bold">Full Name</p>
            <div className="m-t-4">
              <CustomInputBox
                type="text"
                value={fullName}
                onChange={handleInputChange(setFullName)}
                placeholder="Full Name"
              />
            </div>
            {errors.fullName && <p className="error">{errors.fullName}</p>}
          </div>

          {/* Email */}
          <div className="m-t-12">
            <p className="font-bold">Email</p>
            <div className="m-t-4">
              <CustomInputBox
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            {errors.email && <p className="error">{errors.email}</p>}
          </div>

          <div className="m-t-12">
            <p>Date Of Birth</p>
            <div className="m-t-4">
              <DatePicker
                className="react-datepicker-size datepicker-bid-list date-picker"
                selected={dob}
                onChange={(date) => setDob(date)}
                maxDate={new Date()}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />

            </div>

            {errors.dob && <p className="error">{errors.dob}</p>}
          </div>

          {/* Gender */}
          <div className="m-t-12">
            <p className="font-bold">Gender</p>
            <div className="m-t-4 "  > 
              <Select
                multi={false}
                options={options}
                value={
                  options.find((option) => option.value === gender) || null
                }
                onChange={updateGender}
                placeholder="Select Gender"
                autoFocus={false}
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "1px solid #A6A5BB",
                    backgroundColor: "white",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    borderRadius: "4px",
                    fontFamily: "Inter-Medium",
                  }),
                  option: (base) => ({
                    ...base,
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                  }),
                }}
              />
            </div>

            {errors.gender && <p className="error">{errors.gender}</p>}
          </div>

          {/* Description */}
          <div className="m-t-12">
            <p className="font-bold">Bio</p>
            <div className="m-t-4 m-b-30" style={{marginBottom : "110px"}} >
              <CustomTextArea
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder="Tell us About your self."
              />
            </div>

            {errors.bio && <p className="error">{errors.bio}</p>}
          </div>

        </div>
        <div className="fixed-button-container">
    <PrimaryCta
      className={"custom-button-bottom"} 
      onClick={onBoardInfluencer}
      text={"Continue"}
      isLoading={effectiveLoad}
    />
  </div>
      </div>

    </div>
  );
}

export default InfluencerDataScreen;




