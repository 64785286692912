import React, { useEffect, useState } from "react";
import Sdk from "../../../common/sdk/influencerSdk";
import { useNavigate, useParams } from "react-router-dom";
import { CustomText, FallBack, PrimaryCta } from "../../../common/core_component";
import { Loader } from "../../Loader";
import Brandlogo from "../../../assets/images/small-logo.png";
import Utils from "../../../common/utils";
import Icon from "../../../assets/icons";
import svgs from "../../../assets/icons/svgs";
import MessageScreen from "./MessageScreen";
import { useSnackbar } from "notistack";

function DetailPage() {
  const assignment_id = useParams().id;
  //  console.log("id", assignment_id);

  const [assignmentData, setAssignmentData] = useState();
  const [contentList, setContentList] = useState();
  const [isLoading, setIsLoding] = useState(false);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  
  
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    refreshAssignment();
  }, []);

  function refreshAssignment() {
    getCampaignContentListUser();
    getAssignmentData();
  }

  // get Assignment Data start
  function getAssignmentData() {
    var params = {
      assignment_id: assignment_id,
    };
    Sdk.getAssignmentData(
      getAssignmentDataSuccess,
      getAssignmentDataFailed,
      updateProgress,
      params
    );
  }
  function getAssignmentDataSuccess(response) {
    setAssignmentData(response.assignment_deep_data);
  }
  console.log("agm", assignmentData);

  function getAssignmentDataFailed(error) {
    console.error(" get assignment fail");
  }

  // get Assignment Data start
  function startAssignment() {
    var params = {
      assignment_id: assignment_id,
    };
    Sdk.startAssignment(
      startAssignmentSuccess,
      startAssignmentFailed,
      (status)=> {setEffectiveLoad(status)},
      params
    );
  }
  function startAssignmentSuccess(response) {
    enqueueSnackbar("Contract signed successfully", {variant : "success"})
    getAssignmentData();
    navigate('/');
  }

  function startAssignmentFailed(error) {
    console.error("Start Assignment" , error);
  }

  //  get Campaign Content List User start
  function getCampaignContentListUser() {
    var params = {};
    Sdk.getCampaignContentListUser(
      getCampaignContentListUserSuccess,
      getCampaignContentListUserFailed,
      updateProgress,
      params
    );
  }

  function getCampaignContentListUserSuccess(response) {
    console.log("contentlist user success", response);
    setContentList(response.content_data);
  }
  console.log("lst", contentList);

  function getCampaignContentListUserFailed(error) {
    console.error("contentlist user error", error);
  }
  function updateProgress(status) {
    setIsLoding(status);
  }


  const navigate = useNavigate(); 


  //  Render Ui -----

  function contractHeadScreen() {
    return (
      <>
        {assignmentData ? (
          <div className="m-t-20">
            <div className="white-card-bg flex gap-20" style={{ gap: "15px" }}>
              <div className="card-icon-campaign">
                <img
                  src={
                    assignmentData?.brand_data?.image_id
                      ? Utils.generateBrandLogoUrl(
                          assignmentData?.brand_data?.org_id,
                          assignmentData?.brand_data?.image_id
                        )
                      : Brandlogo
                  }
                  alt="Brand Logo"
                />
              </div>
              <div className="">
                <CustomText
                  p
                  text={assignmentData?.assignment_data?.name}
                  className="text-15"
                />
                <div className="" style={{ gap: "5px" }}>
                  <CustomText
                    p
                    text={ " Contract Id : #" + assignmentData?.assignment_data?.assignment_id.toUpperCase()}
                    className="text-color-p text-13 m-t-6"
                  />
                  <CustomText
                    p
                    text={assignmentData?.brand_data?.name}
                    className="text-color-p text-13 m-t-6"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderCollabDetails() {
    const renderBudget = (reward_type, amount) => {
      if (reward_type == "cash") {
        return Utils.formatMoney(amount);
      } else {
        return amount + " Credits";
      }
    };
    return (
      assignmentData && (
        <>
          <div className="m-b-8 m-t-10">
            <CustomText h5 text={"Collab Requirement"} />
          </div>

          <div
            className="white-card-bg flex-column flex"
            style={{ gap: "10px" }}
          >
            <div className="flex justify-between ">
              <CustomText p text={"Delivery Date"} className = "text-13  "/>
              <CustomText
                p
                text={
                  Utils.formatDateTaskDetails(
                    assignmentData?.assignment_data?.delivery_date
                  ) || "-"
                }
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
            <div className="flex justify-between ">
              <CustomText p text={"Status"} className = "text-13" />
              <CustomText
                p
                text={assignmentData?.assignment_data?.assignment_status || "-"}
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
            <div className="flex justify-between ">
              <CustomText p text={"Campaign Type"}  className = "text-13" />
              <CustomText
                p
                text={Utils.getCampaignTypeText(
                  assignmentData?.assignment_data?.type || "-"
                )}
                className="text-color-p text-12 m-t-4"
              />
            </div>
            <div className="flex justify-between ">
              <CustomText p text={"Payment Type"} className = "text-13" />
              <CustomText
                p
                text={assignmentData?.reward_data?.reward_type || "-"}
               
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
            <div className="flex justify-between ">
              <CustomText p text={"Reward"} className ="text-13" />
              <CustomText
                p
                text={
                  renderBudget(
                    assignmentData?.reward_data?.reward_type,
                    assignmentData?.reward_data?.value?.amount
                  ) || "-"
                }
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
          </div>
        </>
      )
    );
  }

  function contractDetailsScreen() {
    return (
      <>
        {contractHeadScreen()}
        {renderCollabDetails()}
        {renderDeliverable()}
      </>
    );
  }

  function renderSocialMediaIcon(content_type = []) {
    const platforms = [];
    return content_type.map((item, index) => {
      if (
        item.platform_data &&
        item.platform_data.platform_id &&
        !platforms.includes(item.platform_data.platform_id)
      ) {
        platforms.push(item.platform_data.platform_id);

        const icon = Utils.socialMediaIcons(item.platform_data.platform_id);
        return (
          <div key={index} className="card-icon-campaign flex align-center">
            <Icon
              icon={Utils.socialMediaIcons(item.platform_data.platform_id)}
              height={16}
              width={16}
            />
          </div>
        );
      }
      return null;
    });
  }

  function findContentDetails(id) {
    if (contentList) {
      const contentDetails = contentList.find(
        (values) => values.content_data.content_type_id === id
      );
      if (contentDetails && contentDetails.content_data.platform_id) {
        return (
          <div
            className=" flex align-center"
            style={{ gap: "4px", alignItems: "center" }}
          >
            <div
              className="icon-container"
              style={{ position: "relative", top: "1px" }}
            >
              {renderSocialMediaIcon([
                { platform_data: { platform_id: contentDetails.content_data.platform_id } },
              ])}
            </div>
            <div style={{ position: "relative", bottom: "1px" }}>
              <CustomText
                p
                text={
                  " " +
                  contentDetails.content_data.name +
                  " - " +
                  Utils.Capitalize(contentDetails.content_data.media_type)
                }
                className="text-color-p text-12 m-t-4 capitalize"
              />
            </div>
          </div>
        );
      }
    }
    return null;
  }

  function postStory(contents_deep_data = {}) {
  const dataArray = Object.values(contents_deep_data);

  if (!Array.isArray(dataArray) || dataArray.length === 0) {
    // console.log("Returning '-' because contents_deep_data is either not an array or is empty");
    return "-";
  }

  const content = dataArray
      .map((item, index) => {
        const details = findContentDetails(item.content_data?.content_type_id);
        return details ? <div key={index}>{details}</div> : null;
      })
      .filter(Boolean);  
    // console.log("Mapped content:", content);
    return content.length > 0 ? content : "-";
  }


  function renderDeliverable() {
    return (
      <>
        <div className="m-t-8 m-b-5">
        
          <CustomText h5 text={"Deliverables"} />
        </div>
        <div
          className="white-card-bg flex-column flex"
          style={{ gap: "10px", border: "1px solid #6E6E6E" }}
        >
          <div className="flex-column " style={{ gap: "5px" }}>
            {postStory(assignmentData?.contents_deep_data)}

            </div>{" "}
          </div>{" "}
      
      </>
    );
  }


  return (
    <div className='bg-app-gray'>
      <div className="header-fixed flex  gap-10">
        <div
          className="cursor-pointer p-t-3"
          onClick={() => navigate("/contracts")}
          style={{paddingLeft : "10px"}}
        >
          <Icon icon={svgs.back_black_icon} width={13} height={13} />
        </div>
        <CustomText p text={"Contract Details"} />
      </div>
      
      {isLoading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div style={{paddingTop: "45px"}}>
          <div className='m-t-20 container'>
          {contractDetailsScreen()}
             
            <div className=' page-center m-t-16'>
              <PrimaryCta
                className={"custom-button"}
                onClick={() => startAssignment()}
                isLoading={effectiveLoad}
                text={"Sign Contract "}
              />
            </div>
      
          </div>
        </div>
      )}
    </div>
  );
  
}

export default DetailPage;
