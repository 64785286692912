import * as Actions from '../actions';
const UserData = (state = {}, action) => {
  if (action.type === Actions.UPDATE_USER_DATA) {
    state['userData'] = action.payload;
  }

  return state;
};

export default UserData;
