import {combineReducers, createStore} from 'redux';
import UserDataReducer from './reducers/user';

const AppReducers = combineReducers({
  UserDataReducer,
});

const rootReducer = (state, action) => {
  return AppReducers(state, action);
};

export default createStore(rootReducer);
