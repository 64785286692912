import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import { useSelector } from "react-redux";

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  // Redux selector to get user data
  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  const influencer_id = influencer_data?.influencer_id;

  // Define the icons
  const icons = [
    {
      id: "home",
      route: "/",
      icon: svgs.homeIcon,
      width: 20,
      height: 20,
    },
    {
      id: "bids",
      route: "/bids",
      icon: svgs.bidListIcon,
      width: 20,
      height: 20,
    },
    {
      id: "campaign",
      route: "/campaigns",
      icon: svgs.explore,
      width: 20,
      height: 20,
    },
    {
      id: "assignment",
      route: "/contracts",
      icon: svgs.assignmentCheck,
      width: 20,
      height: 20,
    },
    {
      id: "profile",
      route: "/profile",
      icon: svgs.filledUser,
      width: 20,
      height: 20,
    },
  ];

  // const renderIcons = influencer_id ? icons : [icons[0]];

  return (
    <div className="footer">
      {icons.map(({ id, route, icon, width, height }) => (
        <div
          key={id}
          className={`icon ${location.pathname === route ? "selected" : ""}`}
          id={id}
          onClick={() => navigate(route)}
        >
          <Icon icon={icon} width={width} height={height} />
        </div>
      ))}
    </div>
  );
}

export default Footer;
