import React, { useEffect, useState } from "react";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import {
  CustomInputBox,
  CustomText,
  PrimaryCta,
} from "../../common/core_component";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import Select from "react-dropdown-select";
import { CITIES, LOCATION } from "../../common/data/influencer";
import Sdk from "../../common/sdk/influencerSdk";
import { useSnackbar } from "notistack";

function AddAddress() {
  const [addressDetails, setAddressDetails] = useState();
 
  console.log("details", addressDetails);

  const {
    address_premise,
    address_street,
    address_city,
    address_pincode,
    address_state,
  } = addressDetails || {};

  const [Premise, setPrimise] = useState(address_premise || "");
  const [city, setCity] = useState(address_city || "");
  // const [city, setCity] = useState(city_id);
  const [userState, setUserState] = useState();
  const [street, setStreet] = useState(address_street || "");
  const [pincode, setPincode] = useState(address_pincode || "");
  const [state, setState] = useState(address_state || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [country, setCountry] = useState("India");

  useEffect(() => {
    if (address_premise) {
      setPrimise(address_premise);
    }
    if (address_city) {
      setCity(address_city);
    }
    if (address_street) {
      setStreet(address_street);
    }
    if (address_pincode) {
      setPincode(address_pincode);
    }
    if (address_state) {
      setState(address_state);
    }
  }, [
    address_premise,
    address_city,
    address_street,
    address_pincode,
    address_state,
  ]);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  function updateProgress(status) {
    setLoading(status);
  }

  useEffect(() => {
    getAddressData();
  }, []);

  // --------- API --------



  function getAddressData() {
    var params = {
      //address_id: influencerData.address,
    };

    Sdk.getAddressData(
      getAddressDataSuccess,
      getAddressDataFail,
      updateProgress,
      params
    );
  }
  function getAddressDataSuccess(response) {
    setAddressDetails(response.address_data);
    // setAppLoaded(true);
  }

  console.log("id", addressDetails?.address_id);
  

  function getAddressDataFail(error) {
    // showError(errorList.map(item => item.m).join(', '));
    console.error("err", error);
  }

  function addAddressDetails() {
    if (!validateForm()) return false;

    var params = {
      address_city: city,
      address_pincode: pincode,
      address_premise: Premise,
      address_state: state,
      address_country: "India",
      address_street: street,
    };

    Sdk.postAddAddressData(
      addAddressSuccess,
      addAddressFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function addAddressSuccess(response) {
    enqueueSnackbar("Address Added Successfully ", { variant: "success" });
    navigate("/profile");
    console.log("success added", response);
  }
  function addAddressFailed(errorList) {
    enqueueSnackbar(errorList.map((item) => item.m).join(", "));
  }

  // update address data

  function updateAddressDetails() {
    if (!validateForm()) return false;


    // var params = {
    //   address_city: city,
    //   address_pincode: pincode,
    //   address_premise: Premise,
    //   address_state: state,
    //   address_country: "India",
    //   address_street: street,
    //   // address_id  : addressDetails?.address_id
    // };

    var params = {
      address_data: {
        address_premise: Premise,
        address_street: street,
        address_city: city,
        address_state: state,
        address_country: country,
        address_pincode: pincode,
        address_id  : addressDetails?.address_id
      },
    };
    Sdk.postUpdateAddressData(
      updateAddressSuccess,
      updateAddressFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function updateAddressSuccess(response) {
    enqueueSnackbar("Address Updated", { variant: "success" });
    // navigate("/profile");
    navigate("/address-page");
    console.log("success update", response);
  }
  function updateAddressFailed(errorList) {
    console.error("up err ", errorList);
    
    enqueueSnackbar(errorList.map((item) => item.m).join(", "), {
      variant: "error",
    });
  }

  function validateForm() {
    let isValid = true;
    let newErrors = {
      Premise: "",
      street: "",
      city: "",
      state: "",
      pincode: "",
    };

    if (!Premise || !Premise.trim()) {
      newErrors.Premise = "Premise is required";
      isValid = false;
    }

    if (!street || !street.trim()) {
      newErrors.street = "Please enter Street";
      isValid = false;
    }

    if (!city || !city.trim()) {
      newErrors.city = "City is required";
      isValid = false;
    }
    if (!state) {
      newErrors.state = "State is required";
      isValid = false;
    }
    if (!pincode) {
      newErrors.pincode = "Pincode is required";
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  }

  const handleStateSelection = (selectedOption) => {
    if (selectedOption && selectedOption.length > 0) {
      const selectStateId = selectedOption[0]?.label;
      setState(selectStateId);
    }
  };

  // console.log("state", state);

  function renderStateSelection() {
    const locationArray = Object.values(LOCATION);
    return (
      <>
        <div className="m-t-12">
          <CustomText p text={"State"} className="m-b-5" />
          <div className="m-t-4">
            <Select
  isMulti={false}
  options={locationArray.map((state) => ({
    value: state?.value,
    label: state?.label,
  }))}
  value={
    state
      ? {
          value: state,
          label: locationArray.find((c) => c.id === state)?.label,
        }
      : null
  }
  onChange={(selectedOption) => handleStateSelection(selectedOption)}
  placeholder={ state || "Enter State"}
  style={{
    border: "1px solid #A6A5BB",
    backgroundColor: "white",
    height: "44px", 
    fontFamily: "Inter-Medium",
  }}

/>

          </div>
          {error.state && <p className="error">{error.state}</p>}
        </div>
      </>
    );
  }

  const handleSelectionCity = (selectedOption) => {
    if (selectedOption && selectedOption.length > 0) {
      const selectedLabel = selectedOption[0].label;
      const selectStateId = selectedOption[0]?.state_id;
      setCity(selectedLabel);
      setUserState(selectStateId)
    }
  };

  function renderCity() {
    return (
      <>
        <div className="m-t-12">
          <CustomText p text={"City"} className= "m-b-5" />
          <div className="m-t-4">
            <Select
              isMulti={false}
              options={CITIES.filter((city) => city.type === "city").map((city) => ({
                value: city.id,
                label: city.label,
                state_id: city.state_id,
              }))}
              value={
                city
                  ? {
                      value: city,
                      label: CITIES.find((c) => c.id === city)?.label,
                    }
                  : null
              }
              onChange={(selectedOption) => handleSelectionCity(selectedOption)}
              placeholder={city ||  "Enter City"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #A6A5BB",
                  backgroundColor: "white",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  fontFamily: "Inter-Medium",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }),
              }}
            />
          </div>
          {error.city && <p className="error">{error.city}</p>}
        </div>
      </>
    );
  }

  function renderInputBoxes() {
    return (
      <>
        {/* Full Name  */}
        <div className="m-t-12">
          <CustomText p text="Premise" className="m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={Premise}
              onChange={(e) => setPrimise(e.target.value)}
              placeholder={"Enter Your Premise"}
            />
          </div>
          {error.Premise && <p className="error">{error.Premise}</p>}
        </div>
        {/* legal Name  */}
        <div className="m-t-12">
          <CustomText p text="Street" className="m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              placeholder={"Enter Your Street"}
            />
          </div>
          {error.street && <p className="error">{error.street}</p>}
        </div>
        {/* ----- render City ----- */}
{renderCity()}
          {error.city && <p className="error">{error.city}</p>}

        {/* render Selection box---n */}
        {renderStateSelection()}
        <div className="m-t-12">
          <CustomText p text="Pincode" className="m-b-5" />
          <div className="">
            <CustomInputBox
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              placeholder={"Enter Your Pincode"}
            />
          </div>
          {error.pincode && <p className="error">{error.pincode}</p>}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="header-fixed flex justiify-center gap-10">
        <div
          className="cursor-pointer p-t-3" style={{paddingLeft : "10px"}}
          onClick={() => navigate("/address-page")}
        >
          <Icon icon={svgs.back_black_icon} width={13} height={13} />
        </div>
        <CustomText p text={" Add Address Details"} />
      </div>

      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        <div
          className="container m-b-75"
          style={{
            paddingTop: "58px",
            marginBottom: "120px",
          }}
        >
          {/*-- input boxes -- */}
          {renderInputBoxes()}

          <div className="btn-container-details-page">
            <PrimaryCta
              className={"btn-bottom-detail-page"}
              onClick={() =>
                addressDetails ? updateAddressDetails() : addAddressDetails()
              }
              isLoading={effectiveLoad}
              text={addressDetails ? "Update" : "Save"}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AddAddress;
