// Import user data key
import { USER_DATA } from './keys';

// Function to set user data in localStorage
export const setLocalUserData = (userData) => {
  if (userData) {
    localStorage.setItem(USER_DATA, JSON.stringify(userData));
  } else {
    localStorage.removeItem(USER_DATA);
  }
};

// Function to get user data from localStorage
export const getLocalUserData = () => {
  let data = localStorage.getItem(USER_DATA);

  if (data) {
    data = JSON.parse(data);
  }
  return data;
};
