import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../assets/icons";
import svgs from "../../assets/icons/svgs";
import { CustomText } from "../../common/core_component";
import { Loader } from "../Loader";
import Sdk from "../../common/sdk/influencerSdk";
import Utils from "../../common/utils";
import Ourlogo from "../../assets/images/small-logo.png";
import ReactPullToRefresh from "react-pull-to-refresh";
import InfiniteScroll from "react-infinite-scroll-component";

function AllBrand() {
  const navigate = useNavigate();
  const [loading, set_loading] = useState(false);
  const [brandList, setBrandList] = useState([]); 
  const [next, setNext] = useState(10);
  const [start, setStart] = useState(0);

  const limit = 10; 

  const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);

  // const handlePullToRefresh = useCallback(async () => {
  //   setPullToRefreshLoading(true);
  //   set_loading(true);
  //   await new Promise((resolve) => setTimeout(resolve, 1500));
  //   setPullToRefreshLoading(false);
  //   set_loading(false);
  // }, []);

  function updateProgress(status) {
    set_loading(status);
  }

  useEffect(() => {
    getBrandList(start, updateProgress);
  }, []); 

   function getBrandList(start, updateProgress) {
    const params = {
      start: start,
      limit: limit,
    };
     Sdk.getBrandList(
        getBrandListSuccess,
        getBrandListFailed,
        updateProgress,
        // () => {}, 
        params
      );
   
  }

   function getBrandListSuccess(api_response) {
    console.log("Rsses", api_response);
    
    const { brand_list } = api_response;

    setBrandList((prevList) => [...prevList, ...brand_list]);
    setNext(api_response.pagination_data?.next); 
    set_loading(false); 
  }

  function getBrandListFailed(error_list) {
    console.log("response error", error_list);
    set_loading(false); // Stop loader on failure
  }

  function renderBrands() {
    const navigateTo = (id) => {
      navigate(`/campaign-brand/${id}`);
    };
  
    return (
      brandList && (
        <div className="brand-container m-t-8">
          <div className="m-t-">
            {brandList.map((brand, index) => (
              <div
                key={index}
                onClick={() => navigateTo(brand?.brand_id)}
                className="brand-card cursor-pointer m-t-8"
              >
                <div className="brand-icon">
                  <img
                    src={
                      brand?.image_id
                        ? Utils.generateBrandLogoUrl(
                            brand?.org_id,
                            brand?.image_id
                          )
                        : Ourlogo
                    }
                    alt="Logo"
                  />
                </div>
                <div className="brand-content">
                  <div className="card-title-campaign">
                    <CustomText h4 text={brand?.name} />
                    <CustomText
                      h5
                      text={brand?.note}
                      className="small-txt brand-content"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    );
  }
  

  return (
    <>
    
        <div className="bg-app-gray">
          <div className="header-fixed flex gap-10" style={{paddingLeft : "10px"}}>
            <div
              className="cursor-pointer p-t-3"
              onClick={() => navigate("/")}
            >
              <Icon icon={svgs.back_black_icon} width={13} height={13} />
            </div>
            <CustomText p text={"Browse by Brand"} />
          </div>

          { loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div className="">
              <div className="" style={{ paddingTop: "48px" }}>

                {/* Infinite Scroll component */}
                
                <InfiniteScroll
                  dataLength={brandList.length} 
                  next={()=> getBrandList(next, ()=>{})} 
                  hasMore={next !== null && next !== undefined} 
                  loader={""} 
                 
                  scrollThreshold={0.9}
                >
                  {renderBrands()}
                </InfiniteScroll>
              </div>
            </div>
          )}
        </div>
        
    </>
  );
}

export default AllBrand;
