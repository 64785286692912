var Config = {
  // http request constants
  //PROD ---- uncomment the prod api from .env file ----
  // BASE_URL:  process.env.REACT_APP_API_BASE_URL,
  // MEDIA_BASE_URL: process.env.REACT_APP_MEDIA_BASE_URL,
  // PHONE_PAY_BASE_URL: 'https://mercury-t2.phonepe.com/transact/pg?token=',

  //DEV
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  MEDIA_BASE_URL: process.env.REACT_APP_MEDIA_BASE_URL,
  // PHONE_PAY_BASE_URL:
  //   'https://mercury-uat.phonepe.com/transact/simulator?token=',
  // MEDIA_BASE_URL: 'https://ind-file-upload.s3.amazonaws.com',

  // Common constants
  GET_METHOD: 'GET',
  POST_METHOD: 'POST',
  JSON_REQUEST: 'json',

  //Error codes
  SERVER_ERROR: 'err_internal_server_error',

  DEFAULT_COUNTRY_CODE: 'in',

  // End Points
  API_ENDPOINTS: {
    API_URN_INFLUENCER_VERIFY_CODE: '/influencer/send-verification-code',
    API_URN_INFLUENCER_SIGNIN: '/influencer/influencer/signin',
    API_URN_CREATE_INFLUENCER: '/influencer/create-influencer',
    API_URN_GET_INFLUENCER_DATA_SELF: '/influencer/influencer/details-self',
    API_URN_UPDATE_INFLUENCER: '/influencer/influencer/update',
    API_URN_UPDATE_INFLUENCER_PP: '/influencer/update-profile-picture',
    API_URN_UPDATE_INFLUENCER_CATEGORY: '/influencer/update-category',
    API_URN_UPDATE_INFLUENCER_DATA: '/influencer/update-influencer-data',
    API_URN_ONBOARD_INFLUENCER: '/influencer/onboard-influencer',
    API_URN_POST_ADD_BANK_DETAILS: '/influencer/add-bank-details',
    API_URN_GET_KYC_DETAILS: '/influencer/get-kyc-details-influencer',
    API_URN_GET_BANK_DETAILS: '/influencer/get-bank-details-influencer',
    API_URN_POST_DELETE_ACCOUNT: '/influencer/influencer/delete',
    API_URN_POST_LOGOUT_ACCOUNT: '/influencer/influencer/logout',
    API_URN_GET_GENERATE_URL_INFLUENCER: '/influencer/generate-url-influencer',

    API_URN_GET_BRAND_LIST: '/brand/influencer/list',
    API_URN_GET_BRAND_DATA_PUBLIC: '/brand/user/details-public',

    API_URN_GET_CATEGORY_LIST: '/category/influencer/list',

    API_URN_GET_CAMPAIGN_LIST: '/campaign/influencer/list',
    API_URN_GET_APP_DATA: '/public/get-app-data',

    API_URN_GET_INVITED_CAMPAIGN_LIST: '/campaign/influencer/invited-campaigns',
    API_URN_GET_CAMPAIGN_CONTENT_USER: '/campaign/get-content-user',
    API_URN_GET_CAMPAIGN_DATA_INFLUENCER: '/campaign/influencer/details',

    API_URN_POST_CAMPAIGN_SEEN_INFLUENCER: '/campaign/create-campaign-seen',

    API_URN_POST_BID_SUBMIT: '/bid/influencer/create',
    API_URN_GET_BIDS_LIST_INFLUENCER: '/bid/influencer/list',
    API_URN_GET_PAST_BIDS_LIST_INFLUENCER: '/bid/get-past-bid-list-influencer',

    API_URN_GET_ASSIGNMENT_LIST_INFLUENCER: '/assignment/influencer/list',
    API_URN_GET_ASSIGNMENT_LIST_INFLUENCER: '/assignment/influencer/list',
    API_URN_POST_SUBMIT_CONTENT_INFLUENCER:
      '/assignment/submit-content-influencer',
    API_URN_GET_ASSIGNMENT_INFLUENCER: '/assignment/influencer/details',
    API_URN_POST_START_ASSIGNMENT: '/assignment/influencer/start',
    API_URN_POST_SUBMIT_CONTENT_URL_INFLUENCER:
      '/assignment/submit-content-url-influencer',

    API_URN_GET_ACTIVE_SUBSCRIPTION_INFLUENCER:
      '/subscription/get-active-subscription-influencer',
    API_URN_GET_SUBSCRIPTION_INFLUENCER: '/subscription/influencer/details',
    API_URN_POST_CREATE_SUBSCRIPTION_INFLUENCER:
      '/subscription/create-subscription-influencer',

    API_URN_GET_PLATFORMS_PUBLIC: '/platform/get-platforms',
    API_URN_GET_PLATFORMS_INFLUENCER: '/platform/get-platforms-influencer',
    API_URN_POST_ADD_PLATFORM: '/platform/add-platform',
    API_URN_POST_VERIFY_PLATFORM: '/platform/verify-platform',

    API_URN_POST_CREATE_ORDER_SUBSCRIPTION_INFLUENCER:
      'order/influencer/create',
    API_URN_GET_ORDER_DETAILS: '/order/influencer/details',
    API_URN_GET_TRANSACTION_LIST_INFLUENCER: '/order/influencer/list',
    API_URN_GET_PAYOUTS_LIST_INFLUENCER: '/payouts/influencer/list',

    API_URN_GET_NOTIFICATION_LIST_INFLUENCER:
      '/notification/influencer/details',
    API_URN_POST_REGISTER_DEVICE_INFLUENCER: '/notification/influencer/create',
    API_URN_POST_ADD_KYC_DETAILS: '/influencer/add-kyc-details',
    API_URN_POST_REMOVE_PLATFORM: '/platform/remove-platform',
    API_URN_POST_SYNC_PLATFORM: '/platform/sync-platform',
    API_URN_GET_CREDT_LIST: '/credit/influencer/list',
    API_URN_GET_BALANCE: '/influencer/get-balance',
    API_URN_GET_CREDIT_DETAILS_INFLUENCER:
      '/influencer/get-credit-details-influencer',
    API_URN_POST_CREATE_ORDER_INFLUENCER: '/order/influencer/create-credit',
    API_URN_POST_SEND_MESSAGE: '/message/influencer/send',
    API_URN_GET_MESSAGE_LIST: '/message/influencer/list',
    API_URN_POST_DELETE_MESSAGE: '/message/influencer/delete',
    API_URN_GET_ADDRESS_DATA: '/influencer/get-address-data',
    API_URN_UPDATE_ADDRESS_DATA: '/influencer/update-address-data',
    API_URN_POST_ADD_ADDRESS_DATA: '/influencer/add-address-data',
    API_URN_GET_BID_SUMMARY: '/influencer/influencer/bid-summery',
    API_URN_POST_RATE_ORG: '/influencer/rate-user',
    API_URN_BID_BOOST: '/influencer/influencer/bid-boost',
    API_URN_ACCEPT_OFFER: '/bid/influencer/accept-counter',
    API_URN_REJECT_OFFER: '/bid/influencer/reject-counter',
    API_URN_CREDIT_INFLUENCER_LIST: '/credit/influencer/credit-list',
    API_URN_CREATE_PORTFOLIO: '/portfolio/influencer/add-portfolio',
    API_URN_LIST_PORTFOLIO: '/portfolio/influencer/list-portfolio',
    API_URN_DELETE_PORTFOLIO: '/portfolio/influencer/delete-portfolio',
    API_URN_SPONSOR_ME: '/influencer/influencer/sponsor-me',
    API_URN_GET_FAQ_LIST: '/faq/public/list-faq',
    API_URN_SYNC_PLATFORM_AUTO: '/platform/sync-platform-auto',
    API_URN_ADD_PLATFORM_AUTO: '/platform/add-platform-auto'
  },
};

export default Config;
