import { enqueueSnackbar, useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sdk from "../../common/sdk/influencerSdk";
import { Core, CustomInputBox, CustomText, PrimaryCta } from "../../common/core_component";
import { BottomSheet } from "react-spring-bottom-sheet";
import logo from '../../assets/images/reelax-logo-dark-full.png'
import OTPInput from "react-otp-input";
import * as Action from "../../redux/actions";
import { useDispatch } from "react-redux";

function LoginOtpScreen({ isModalOpen, setModalOpen, CampaginModalOpen }){

// console.log("shjvjh", comingFromProposal);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // State for login screen
  const [phoneNumber, setPhoneNumber] = useState("");
  const [influencer_data, setUserData] = useState(
    Core.Influencer.createInfluencerData()
  );
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [error_message, set_error_message] = useState(null);
  const [influencerData, setInfluencerData] = useState(null);
  const [vfyD, setVfyD] = useState({ cooldown_period: 45 });


  // State for OTP screen
  const [otp, setOTP] = useState("");
  const [seconds, setSeconds] = useState(vfyD.cooldown_period);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let interval;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds]);


  const validatePhoneNumber = (phone) => {
    if (isNaN(phone) || phone.length !== 10) {
      set_error_message('Please enter a valid 10-digit phone number');
      return false;
    } else {
      set_error_message(null);
      return true;
    }
  };


  const updatePhoneNumberInInfluencerData = (phone) => {
    if (validatePhoneNumber(phone)) {
      const updatedInfluencerData = Core.Influencer.updateInfluencerPhoneNumber(
        influencer_data,
        phone
      );
      // setUserData({ ...updatedInfluencerData });
      setUserData(updatedInfluencerData);
    }
  };

  
  const handleInputChange = (value) => {
    if (value.length <= 10) {
      setPhoneNumber(value);
      updatePhoneNumberInInfluencerData(value); 
    }
  };


  // ------ API's ------
  function sendVerificationCode() {
    const updatedInfluencerData = {
      ...influencer_data, 
      phone: String(phoneNumber) 
    };
    
    var params = {
      influencer_data: JSON.stringify(updatedInfluencerData),
    };

    Sdk.sendVerificationCode(
      sendVerificationCodeSuccess,
      sendVerificationCodeFailed,

      (status) => setEffectiveLoad(status),
      params
    );
  }

  function sendVerificationCodeSuccess(api_response) {
    const { influencer_data, success, vfy_d } = api_response;
    if (success) {
      setInfluencerData(influencer_data); 
      setVfyD(vfy_d);
      setShowOtpScreen(true);
      setSeconds(vfy_d.cooldown_period);
      setResendEnabled(false);
    } else {
      enqueueSnackbar("Failed to send verification code", { variant: "error" });
    }
  }

  function sendVerificationCodeFailed(error_list) {
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }

  //  ------- otp api call --------
  const userNavigator = (influencerData) => {
    if (influencerData) {
      const { profile_status } = influencerData;

      if (profile_status === "NEW") {
          navigate("/influencer-data");
          return;
      }
      if (profile_status === "ACTIVE"){
    setModalOpen(false);
    if(CampaginModalOpen){
      CampaginModalOpen(true)
    }
      }
    } else {
      navigate("/influencer-data");
    }
  };

  function signInPublic(){
    if (!influencerData) {
      enqueueSnackbar("Influencer data is missing. Please try again.", {
        variant: "warning",
      });
      return;
    }
  
    const params = {
      influencer_data: JSON.stringify(influencerData), 
      phone_verification_code: otp,
    };
    Sdk.signInPublic(
      signInPublicSuccess,
      signInPublicFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function signInPublicSuccess(userData) {
    console.log("user data", userData);

    if (userData) {
      const { influencer_deep_data } = userData;

      if (influencer_deep_data) {
        const { influencer_data } = influencer_deep_data;

        dispatch({
          type: Action.UPDATE_USER_DATA,
          payload: influencer_deep_data,
        });
        userNavigator(influencer_data);
        setOTP("");
      } else {
        userNavigator();
      }
    }
    enqueueSnackbar("Login Successfull", { variant: "success" });
  }

  function signInPublicFailed(error_list) {
    console.log("error", error_list);
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }
  

  const resendOTP = () => {
    if (resendEnabled) {
      setResendEnabled(false);
      setSeconds(vfyD.cooldown_period);
      sendVerificationCode();
    }
  };

  const changeNumber = ()=>{
    setShowOtpScreen(false); 
    setOTP('')
    setSeconds(vfyD.cooldown_period);
  }
  const handleOtpChange = (value) => {
    if (value.length <= 4) {
      setOTP(value);
    }
  };
  
  

  function renderAuthContent(){
    return (
      <>
        <div className="container">
          <div
            className="flex align-center justify-center m-t-40 gap-10"
            style={{ fontWeight: "700", fontSize: "20px", textAlign: "center" }}
          >
            <CustomText p text="Login To" className="text-align" />
            <div className="flex align-center justify-center">
              <img src={logo} width={120} />
            </div>
          </div>
    
          {!showOtpScreen && (
            <>
              <div className="m-t-20">
                <label>Phone Number</label>
                <div className="m-t-4">
                  <CustomInputBox
                    type="number"
                    value={phoneNumber}
                    onChange={(e) => handleInputChange(e.target.value)}
                    disabled={showOtpScreen}
                    placeholder="Enter your Number"
                  />
                </div>
    
                <div className="m-t-12 page-center">
                  <PrimaryCta
                    className="custom-button "
                    onClick={sendVerificationCode}
                    disabled={phoneNumber.length < 10}
                    text="Send OTP"
                    isLoading={effectiveLoad}
                  />
                </div>
              </div>
            </>
          )}
    
          {showOtpScreen && (
            <div className="m-t-20">
              <div className="">
            {/* <p className="m-t-12">
              Enter 4-Digit OTP sent to your
            </p> */}
            <p className="m-t-4 ">Mobile Number - {phoneNumber}</p>
            </div>
             
              {/* <div className="otp-container">
                <OTPInput
                  value={otp}
                  onChange={setOTP}
                  numInputs={4}
                  containerStyle="otp-container-style"
                  inputStyle="otp-input-style"
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      className="otp-input"
                      autoFocus={index === 0}
                    />
                  )}
                />
              </div> */}
         <div className="otp-container">
  <CustomInputBox
    type="number"
    value={otp}
    onChange={(e) => handleOtpChange(e.target.value)}
    placeholder="Enter your OTP"
    maxLength={4} 
    autoFocus= {true}
  />
</div>
              <div className="m-t-12 page-center">
                <PrimaryCta
                  className="custom-button "
                  onClick={signInPublic}
                  disabled={otp.length < 4}
                  text="Verify"
                  isLoading={effectiveLoad}
                />
              </div>
    
              <div className="changeNo" style={{ marginTop: "8px" }}>
                <div onClick={changeNumber} style={{ cursor: "pointer" , color : "#007bff" }}>
                  <CustomText p text="Change Number?" />
                </div>
    
                <div
                  onClick={resendEnabled ? resendOTP : null}
                  style={{
                    cursor: resendEnabled ? "pointer" : "not-allowed",
                    color: resendEnabled ? "#007bff" : "#4e4d5f",
                  }}
                >
<CustomText
  p
  text={
    resendEnabled ? (
      "Re-Send OTP"
    ) : (
      <>Resend OTP in <span style={{ width: '28px', display: 'inline-block', textAlign: 'center' }}>
        {seconds < 10 ? `0${seconds}` : seconds}s
      </span></>
    )
  }
/>


                </div>
              </div>

      </div>
           
          )}
        </div>
      </>
    );
    
  }
  

  return (
    <BottomSheet
  open={isModalOpen} 
  onDismiss={() => setModalOpen(false)} 
  snapPoints={({ maxHeight }) => [0.8 * maxHeight, maxHeight]} 
  style={{ overflowY: "auto", zIndex: "1000", position: "absolute" }}
>
<div
      style={{
        width: "100%", 
        maxWidth: "786px", 
        margin: "0 auto", 
        paddingTop: "0px",
      }}
    >
    {renderAuthContent()}</div>
</BottomSheet>

  );
}

export default LoginOtpScreen;
