import Theme from "../../resources/themes/light.js";

const Style = {
  // Font Family and Weights Start
  Poppins_bold: {
    fontFamily: Theme.fonts.BOLD,
    fontWeight: "normal",
  },
  Poppins_boldItalic: {
    fontFamily: Theme.fonts.BOLD_ITALIC,
    fontWeight: "normal",
  },
  Poppins_extraBold: {
    fontFamily: Theme.fonts.EXTRA_BOLD,
    fontWeight: "normal",
  },
  Poppins_extraBoldItalic: {
    fontFamily: Theme.fonts.EXTRA_BOLD_ITALIC,
    fontWeight: "normal",
  },
  Poppins_semiBold: {
    fontFamily: Theme.fonts.SEMI_BOLD,
    fontWeight: "normal",
  },
  Poppins_semiBoldItalic: {
    fontFamily: Theme.fonts.SEMI_BOLD_ITALIC,
    fontWeight: "normal",
  },
  Poppins_regular: {
    fontFamily: Theme.fonts.REGULAR,
    fontWeight: "normal",
  },
  Poppins_regular_medium: {
    fontFamily: Theme.fonts.REGULAR,
    fontWeight: 500,
  },
  Inter_semi_bold_500: {
    fontFamily: Theme.fonts.SEMI_BOLD,
    fontWeight: 500,
  },
  Inter_medium_500: {
    fontFamily: Theme.fonts.MEDIUM,
    fontWeight: 500,
  },
  Inter_SemiBold: {
    fontFamily: Theme.fonts.SEMI_BOLD,
  },
  Inter_regular_small: {
    fontFamily: Theme.fonts.REGULAR,
    fontWeight: 400,
  },
  Poppins_regular_large: {
    fontFamily: Theme.fonts.REGULAR,
    fontWeight: 600,
  },

  Poppins_regularItalic: {
    fontFamily: Theme.fonts.REGULAR_ITALIC,
    fontWeight: "normal",
  },
  Poppins_light: {
    fontFamily: Theme.fonts.LIGHT,
    fontWeight: "normal",
  },
  Poppins_lightItalic: {
    fontFamily: Theme.fonts.LIGHT_ITALIC,
    fontWeight: "normal",
  },
  // Inter font
  Inter_Thin: {
    fontFamily: "Inter-Thin", // 100
  },
  Inter_ExtraLight: {
    fontFamily: "Inter-ExtraLight", // 200
  },
  Inter_Light: {
    fontFamily: "Inter-Light", //300
  },
  Inter_Regular: {
    fontFamily: "Inter-Regular", // 400
  },
  Inter_Medium: {
    fontFamily: "Inter-Medium", //500
  },
  Inter_SemiBold: {
    fontFamily: "Inter-SemiBold" /*600 */,
  },
  Inter_Bold: {
    fontFamily: "Inter-Bold", //700
  },
  Inter_ExtraBold: {
    fontFamily: "Inter-ExtraBold", //800
  },
  Inter_Black: {
    fontFamily: "Inter-Black", // 900
  },
  ellipsisText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  // Font Family and Weights End

  // Font Sizing Start
  font_size_h7: {
    fontSize: 20,
  },
  font_size_tag: {
    fontSize: Theme.dimensions.TAG, //8
  },
  font_size_extraSmall: {
    fontSize: Theme.dimensions.EXTRA_SMALL, // 10
  },
  font_size_label: {
    fontSize: Theme.dimensions.LABEL, //12
    // lineHeight: 38
  },

  font_size_small: {
    fontSize: Theme.dimensions.SMALL, // 13
    // lineHeight: 42
  },
  font_size_regular: {
    fontSize: Theme.dimensions.REGULAR, //14
    // lineHeight: 36
  },
  font_size_paragraph: {
    fontSize: Theme.dimensions.PARAGRAPH, //14
    // lineHeight: 36
  },
  font_size_large: {
    fontSize: Theme.dimensions.LARGE, //16
    // lineHeight: 34
  },
  font_size_extra_large: {
    fontSize: Theme.dimensions.EXTRA_LARGE,
  },
  font_size_xl: {
    fontSize: Theme.dimensions.XL, //18
  },
  font_size_2xl: {
    fontSize: Theme.dimensions.H3, //20
    // lineHeight: 30
  },
  font_size_3xl: {
    fontSize: Theme.dimensions.H2, //24
    // lineHeight: 28
  },
  font_size_25: {
    fontSize: Theme.dimensions.H_25,
  },
  font_size_4xl: {
    fontSize: Theme.dimensions.XL_28, //28
    // lineHeight: 26
  },
  font_size_5xl: {
    fontSize: Theme.dimensions.H5, //28
    // lineHeight: 26
  },
  font_size_h1: {
    fontSize: Theme.dimensions.H1, // 30
  },
  font_logo_size: {
    fontSize: Theme.dimensions.XL_26,
  },
  font_size_medium: {
    fontSize: Theme.dimensions.MEDIUM, //15
  },
  font_max_size: {
    fontSize: Theme.dimensions.max_size,
  },
  // Font Sizing End

  // Text alignment Start
  text_align_left: {
    textAlign: "left",
  },
  text_align_center: {
    textAlign: "center",
  },
  text_align_right: {
    textAlign: "right",
  },
  // Text alignment End
  text_color_gray_500: {
    color: "var(--gray-gray-500)",
  },
  text_color_gray_400: {
    color: "var(--gray-gray-400)",
  },
  text_color_blue_500: {
    color: "var(--blue-blue-500)",
  },

  text_color_grey_100: {
    color: Theme.colors.FC_FIFTH,
  },
  text_general_color_error: {
    color: "var(--general-error)",
  },
  text_color_green: {
    color: "#009A29",
  },
  text_color_red: {
    color: "red",
  },

  text_color_primary: {
    color: Theme.colors.TC_PRIMARY,
  },
  text_color_secondary: {
    color: Theme.colors.TC_SECONDARY,
  },
  text_color_light: {
    color: Theme.colors.FC_LIGHT,
  },
  text_color_tertiary: {
    color: Theme.colors.TC_TERTIARY,
  },
  text_color_light: {
    color: Theme.colors.TC_LIGHT,
  },
  text_color_disabled: {
    color: Theme.colors.TC_DISABLED,
  },
  text_color_error: {
    color: Theme.colors.TC_ERROR,
  },
  text_color_success: {
    color: Theme.colors.FC_SUCCESS,
  },
  text_color_register: {
    color: Theme.colors.FC_FOURTH,
  },
  text_color_forgot: {
    color: "var(--blue-blue-500)",
  },

  text_color_black: {
    color: Theme.colors.TC_BACK,
  },

  text_color_send: {
    color: Theme.colors.FC_FIFTH,
  },

  text_light_weight_color: {
    color: Theme.colors.TC_LIGHT_WEIGHT,
  },

  text_Gradient_Color: {
    color: Theme.colors.Gradient_Color,
  },

  bc_cards_color: {
    color: Theme.colors.BC_TABLE_CARD,
  },

  text_active_color: {
    color: Theme.colors.TC_ACTIVE,
  },
  text_pending_color: {
    color: Theme.colors.TC_PENDING,
  },
  text_completed_color: {
    color: Theme.colors.TC_COMPLETED,
  },
  text_credit_color: {
    color: Theme.colors.TC_CREDIT,
  },
  text_debit_color: {
    color: Theme.colors.TC_DEBIT,
  },
  text_grey_color: {
    color: Theme.colors.TC_GREY,
  },
  text_grey_400_color: {
    color: Theme.colors.GREY_400,
  },
  text_grey_50_color: {
    color: Theme.colors.GREY_50,
  },
  text_grey_100_color: {
    color: Theme.colors.TC_GREY_100,
  },

  text_color_red: {
    color: Theme.colors.TC_RED,
  },
  text_color_green: {
    color: Theme.colors.TC_GREEN,
  },

  line_height_15_6: {
    lineHeight: "15.6px",
  },
  line_height_19_2: {
    lineHeight: "19.2px",
  },
  line_height_24: {
    lineHeight: "24px",
  },

  line_height_22_5: {
    lineHeight: "22.5px",
  },
  line_height_24: {
    lineHeight: "24px",
  },
  h1Primary: {
    fontSize: 28,
    color: "#4D4D4D",
    fontFamily: Theme.fonts.REGULAR,
    fontWeight: "bold",
    //lineHeight: 2
  },
  h1Disabled: {
    fontSize: Theme.dimensions.H1,
    color: Theme.colors.TC_PRIMARY_DISABLED,
    fontFamily: Theme.fonts.SEMI_BOLD,
    fontWeight: "normal",
  },
  h2Default: {
    fontSize: Theme.dimensions.H2,
    color: "#444444",
    fontFamily: Theme.fonts.EXTRA_BOLD,
    fontWeight: "normal",
  },

  h2Primary: {
    fontSize: Theme.dimensions.H2,
    color: "#4D4D4D",
    fontFamily: Theme.fonts.EXTRA_BOLD,
    fontWeight: "normal",
  },

  h2Disabled: {
    fontSize: Theme.dimensions.H2,
    color: Theme.colors.TC_PRIMARY_DISABLED,
    fontFamily: Theme.fonts.EXTRA_BOLD,
    fontWeight: "normal",
  },
  h3Default: {
    fontSize: Theme.dimensions.H3,
    color: "#202020",
    fontFamily: Theme.fonts.EXTRA_BOLD,
    fontWeight: "normal",
  },

  h3Primary: {
    fontSize: Theme.dimensions.H3,
    color: "#202020",
    fontFamily: Theme.fonts.EXTRA_BOLD,
    fontWeight: "normal",
  },

  h3Disabled: {
    fontSize: Theme.dimensions.H3,
    color: Theme.colors.TC_PRIMARY_DISABLED,
    fontFamily: Theme.fonts.EXTRA_BOLD,
    fontWeight: "normal",
  },
  mediumDefault: {
    fontSize: Theme.dimensions.MEDIUM,
    color: Theme.colors.TC_PRIMARY,
    fontFamily: Theme.fonts.BOLD,
    fontWeight: "normal",
  },

  mediumPrimary: {
    fontSize: Theme.dimensions.REGULAR,
    color: Theme.colors.TC_PRIMARY,
    fontFamily: Theme.fonts.BOLD,
    fontWeight: "normal",
  },
  mediumLight: {
    fontSize: Theme.dimensions.MEDIUM,
    color: Theme.colors.TC_PRIMARY_WHITE,
    fontFamily: Theme.fonts.SEMI_BOLD,
    fontWeight: "normal",
    letterSpacing: "2px",
  },

  mediumDisabled: {
    fontSize: Theme.dimensions.MEDIUM,
    color: Theme.colors.TC_PRIMARY_DISABLED,
    fontFamily: Theme.fonts.REGULAR,
    fontWeight: "normal",
  },

  twoLines: {
    display: "-webkit-box", // Required for Safari
    WebkitLineClamp: 2, // Limit the number of lines to 2
    WebkitBoxOrient: "vertical", // Required for Safari
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },

  customtextbox_main_container: {
    width: 369,
    height: 54,
    position: "relative",
  },

  heightChange: {
    minWidth: 369,
    height: 117,
    position: "relative",
  },
  invert_input_field: {
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    borderWidth: 1,
    borderRadius: 4,
    width: 311,
    borderColor: "#fff",
    outline: "none",
    border: "1px solid transparent",
    fontSize: 16,
    // margin: "7px 9px",
  },

  custom_textbox_main_wrapper: {
    width: "100%",
    height: "100%",
    border: "1px solid",
    borderRadius: 8,
    borderColor: Theme.colors.GREY_50,
    padding: 15,
    boxSizing: "border-box",
    outline: "none",
    fontSize: Theme.dimensions.REGULAR,
    color: Theme.colors.TC_PRIMARY,
    fontFamily: Theme.fonts.REGULAR,
    backgroundColor: "var(--general-white-color)",
  },

  customtextarea_box_main_container: {
    border: "1px solid #E6E7E8",
    borderRadius: 6,
    width: "100%",
    height: 100,
  },

  detailInputField: {
    width: 343,
    height: 44,
    display: "flex",
    backgroundColor: "#FFFFFF",
    // marginRight: 10,
    marginLeft: 7,
    marginTop: 6,
    // marginBottom: 10,
    borderRadius: 4,
    cursor: "pointer",
  },

  custom_textarea_box_main_wrapper: {
    width: "100%",
    height: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    boxSizing: "border-box",
    border: "none",
    outline: "none",
    fontSize: Theme.dimensions.REGULAR,
    color: Theme.colors.TC_PRIMARY,
    fontFamily: Theme.fonts.REGULAR,
    fontWeight: "normal",
    borderRadius: 4,
    background: "transparent",
    color: "#090a14",
    fontSize: "16",
  },
  custom_textarea_box_main_wrapper_box: {
    width: "100%",
    height: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    boxSizing: "border-box",
    border: "none",
    outline: "none",
    fontSize: Theme.dimensions.REGULAR,
    color: Theme.colors.TC_PRIMARY,
    fontFamily: Theme.fonts.REGULAR,
    fontWeight: "normal",
    borderRadius: 6,
    background: "#F0F3F9",
    fontSize: "16",
  },

  invert_custom_text_area: {
    // width: 702,
    // height: 140,
    borderRadius: 4,
    color: "#090a14",
  },

  handleCampaignBtn: {
    height: "24px",
    width: "24px",
    backgroundColor: Theme.colors.TC_SECONDARY,
  },

  primaryCtaContainer: {
    // backgroundColor: Theme.colors.FC_THIRD,
    textAlign: "center",
    outline: "none",
    cursor: "pointer",
    // border: 0,
    userSelect: "none",
  },

  primaryCtaDisabledContainer: {
    backgroundColor: "grey",
    opacity: 0.7,
    cursor: "not-allowed",
  },
  primaryAppContainer: {
    width: "100%",
    padding: "12px",
    backgroundColor: "rgb(0, 102, 255)",
    color: "white",
    border: "none",
    height: "50px",
    borderRadius: "4px",
    fontSize: "20px",
    cursor: "pointer"
  },

  fullWidthPrimaryCtaContainer: {
    borderRadius: 9,
    maxWidth: 369,
    width: "100%",
    textAlign: "center",
    paddingRight: 48,
    paddingLeft: 48,
    paddingTop: 15,
    paddingBottom: 15,
    outline: "none",
    border: 0,
    userSelect: "none",
    cursor: "pointer",
    background: "var(--gray-gray-500)",
  },
  fullWidthPrimaryCtaContainer100: {
    borderRadius: 9,
    width: "100%",
    textAlign: "center",
    paddingRight: 48,
    paddingLeft: 48,
    paddingTop: 15,
    paddingBottom: 15,
    outline: "none",
    border: 0,
    userSelect: "none",
    cursor: "pointer",
    background: "var(--gray-gray-500)",
  },
  primaryCtaInvertContainer: {
    backgroundColor: Theme.colors.FC_LIGHT,
    border: "1px solid",
    borderRadius: 4,
    borderColor: Theme.colors.FC_LIGHT,
    backgroundColor: "transparent",
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 40,
    paddingRight: 40,
    border: "1px solid var(--gray-gray-500)",
  },

  primaryCtaSecondaryContainer: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },

  primaryCtaThirdContainer: {
    height: 39,
    width: 164,
    // minWidth: 164,
    // minHeight: 39,
    // paddingTop: 9,
    // paddingBottom: 9,
    // paddingLeft: 18,
    // paddingRight: 18,
    borderRadius: 4,
    textAlign: "center",
    outline: "none",
    border: 0,
    userSelect: "none",
    cursor: "pointer",
    //background:"var(--gradient, linear-gradient(90deg, #A271FE 0%, #F75774 100%))",
    background: "var(--gray-gray-500)",
    boxShadow: "none",
  },

  primaryCtaBorderContainer: {
    color: Theme.colors.TC_BACK,
    borderRadius: 4,
    backgroundColor: "transparent",
  },

  primaryCtaWhiteContainer: {
    height: 40,
    width: 232,
    borderRadius: 4,
    textAlign: "center",
    outline: "none",
    border: 0,
    userSelect: "none",
    cursor: "pointer",

    background: "#fff",
    boxShadow: "none",
  },

  primaryCtaFourthContainer: {
    width: 434,
    height: 24,
    textAlign: "center",
    outline: "none",
    border: 0,
    userSelect: "none",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
  },

  primaryCtaFifthContainer: {
    borderRadius: 4,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 40,
    paddingRight: 40,
    cursor: "pointer",
    background: "var(--gray-gray-500)",
  },
  primaryCtaSixthContainer: {
    borderRadius: 4,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 18,
    paddingRight: 18,
    cursor: "pointer",
    color : "#212529",
    fontSize : 16,
    // background:
    //   "var(--gradient, linear-gradient(90deg, #A271FE 0%, #F75774 100%))",
    background: "var(--gray-gray-500)",
    //boxShadow: "0px 4px 61px 0px rgba(77, 71, 195, 0.40)",
  },

  linkCtaContainer: {
    backgroundColor: "transparent",
    outline: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    border: "none",
    //letterSpacing: "1px",
  },

  underlinedLinkCtaContainer: {
    backgroundColor: "transparent",
    outline: "none",
    cursor: "pointer",
    display: "flex",
    textDecoration: "underline 1px #C6A4FF",
    alignItems: "center",
    userSelect: "none",
    border: "none",
    // letterSpacing: "1px",
  },

  CustomCheckBox: {
    backgroundColor: "transparent",
    height: 20,
    width: 20,
    marginRight: 15,
  },

  customDropdown: {
    //height: 30,
    border: "none",
    borderBottom: "1px solid",
    outline: "1px #ccc",
    fontSize: Theme.dimensions.REGULAR,
    color: Theme.colors.TC_PRIMARY,
    fontFamily: Theme.fonts.REGULAR,
    flexDirection: "column",
  },
  borderedDropdown: {
    height: 54,
    width: 369,
    border: "1px solid",
    borderColor: Theme.colors.FC_SECONDARY,
    borderRadius: 8,
    outline: "none",
    fontSize: Theme.dimensions.REGULAR,
    fontFamily: Theme.fonts.REGULAR,
    color: Theme.colors.TC_PRIMARY,
    paddingLeft: 26,
    paddingTop: 17,
    paddingBottom: 15,
    paddingRight: 26,
    backgroundColor: Theme.colors.BC_PRIMARY,
    cursor: "pointer",
    appearance: "none",
    backgroundImage: "url('./assets/images/dropdownIcon.png') ",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundPositionX: 338,
  },

  invertBorderDropDown: {
    width: 343,
    // height: 44,
    display: "flex",
    backgroundColor: "#FFFFFF",
    // paddingRight: 16,
    // paddingLeft: 16,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 4,
    // marginLeft: 10,
    cursor: "pointer",
    appearance: "none",
    backgroundImage: "url('./assets/images/dropdown_black.png') ",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundPositionX: 305,
    outline: "none",
    // margin: 6,
  },
  dropDownOption: {
    backgroundColor: "#ffffff",
    color: "#333333",
    height: 54,
  },

  // dropdown css

  /* Dropdown.css */
  dropdown: {
    width: 200,
  },

  /* Customize the dropdown appearance */
  select: {
    width: "100%",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 4,
    fontSize: 16,
    outline: "none",
  },

  view_cards_main: {
    // width: 567,
    backgroundColor: "#e6e7e830",
    marginTop: 12,
    padding: "16px 24px",
    borderRadius: 8,
  },
};

export default Style;
