import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "../Loader";
import { CustomText, FallBack, PrimaryCta } from "../../common/core_component";
import { useNavigate } from "react-router-dom";
import Sdk from "../../common/sdk/influencerSdk";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import * as Action from "../../redux/actions";
import Utils from "../../common/utils";
import user from "../../assets/images/user.png";
import Icon from "../../assets/icons";
import Svgs from "../../assets/icons/svgs";
import Footer from "../common/Footer";
import ReactPullToRefresh from "react-pull-to-refresh";
import * as InfluencerInfo from "../../common/data/influencer";
import svgs from "../../assets/icons/svgs";
import LoginOtpScreen from "../common/LoginOtpScreen";

function ProfilePage() {
  const [loading, setLoading] = useState(false);
  const [downRefesh, setDownRefresh] = useState(false);
  const [influencerDetail, setInfluencerDetail] = useState({});
  const [categoriesDetail, setCategoriesDetail] = useState({});
  const [uploadFile, setUploadFile] = useState();
  const [isLoginOtpSheet, setisLoginOtpSheet] = useState(false);
  const openLoginOtp_Sheet  = ()=>{
    setisLoginOtpSheet(true);
  }

  const navigate = useNavigate();

  const reduxData = useSelector((state) => {
    return {
      UserData: state.UserDataReducer,
    };
  });

  // useEffect(() => {
  //   if (uploadFile) {
  //   }
  // }, [uploadFile]);

  const dispatch = useDispatch();

  function updateProgress(status) {
    setLoading(status);
  }

  const { enqueueSnackbar } = useSnackbar();

  const { UserData = {} } = reduxData;
  const { userData = {} } = UserData;
  const { influencer_data = {} } = userData;
  const { platforms_data = {} } = userData;
  const { categorys_data = {} } = userData;

  const profession = influencer_data?.profession;


  // -------  Account Api -----------


  useEffect(() => {
    if (!Utils.isEmpty(influencer_data)) {
      refreshUserData();
    }
  }, []);

  function refreshUserData(){
    const params = {};
    Sdk.getInfluencerDataSelf(
      refreshUserDataSuccess,
      refreshUserDataFailed,
      updateProgress,
      params
    );
  }

  function refreshUserDataSuccess(userData) {
    // setLocalUserData(userData);
    setInfluencerDetail(userData.influencer_deep_data?.influencer_data);
    setCategoriesDetail(userData.influencer_deep_data?.categorys_data);

    if (userData) {
      const { influencer_deep_data } = userData;
      dispatch({
        type: Action.UPDATE_USER_DATA,
        payload: influencer_deep_data,
      });
    }
  }

  function refreshUserDataFailed(error_list) {
    enqueueSnackbar(error_list.map((err) => err.m).join(", "), {
      variant: "error",
    });
  }


  // -------- Render UI --------

  let profileUrl;

  if (influencer_data && influencer_data?.profile_picture) {
    profileUrl = Utils.generateInfluencerDpUrl(
      influencer_data?.influencer_id,
      influencer_data?.profile_picture
    );
  }
  function renderProfileInfo() {
    return (
      <>
        {influencer_data && (
          <>
            <div className="white-card-bg flex m-t-8" style={{ gap: "10px" }}>
              <div
                className="card-icon-campaign relative"
                onClick={() => {
                  enqueueSnackbar("Download Reelax App for DP update", {
                    variant: "info",
                  });
                }}
              >
                <img src={profileUrl ? profileUrl : user} alt="Brand Logo" />
                {/* <div className="camera-icon">
                  <Icon icon={Svgs.icon_camera} width={18} height={18} />
                </div> */}
              </div>

              <div
                className="flex flex-column m-t-8"
                style={{ gap: "7px", textTransform: "capitalize" }}
              >
                <CustomText p text={influencer_data?.name} />
                <CustomText p text={influencer_data?.phone} className= "text-14"/>
              </div>

              <div
                className="m-t-8"
                onClick={() => navigate("/edit-profile")}
                style={{
                  marginLeft: "auto",
                  marginRight: "10px",
                  cursor: "pointer ",
                }}
              >
                <CustomText p text={"Edit"} />
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  function renderSocialChannels() {
    let platformSubTextUI = (
      <CustomText p text={" No Social channel connected"} className="error" />
    );
    if (platforms_data && platforms_data.length > 0) {
      const platformSubText = platforms_data
        .map((platform) => Utils.socialMediaName(platform.platform_id))
        .join(", ");
      platformSubTextUI = (
        <CustomText
          p
          text={platformSubText + " Connected"}
          className="success"
        ></CustomText>
      );
    }
    return (
      <>
        <div
          onClick={() => navigate("/social-channel")}
          className="white-card-bg relative flex flex-column"
          style={{ minHeight: "55px", cursor: "pointer " }}
        >
          <div className="m-t-8">
            <CustomText p text={"Social Channels"} />
          </div>
          <div className="text-12 flex flex-column">
            <CustomText p text={platformSubTextUI} />
          </div>

          <div className="arrow-icon-right">
            <Icon
              icon={Svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }

  //  --------- get Profession Name ---------

  function getProfessionName() {
    let category = Object.keys(categorys_data)[0];

    let profession_data = InfluencerInfo.CATEGORIES["PROFESSIONS"][category];
    let name;
    if (profession_data) {
      profession_data.forEach((cat) => {
        if (cat.id == profession) {
          name = cat.name;
        }
      });
    }
    return name;
  }

  function renderProfession() {
    return (
      <>
        <div
          onClick={() => navigate("/profession")}
          className="white-card-bg flex flex-column  relative   justify-center "
          style={{ minHeight: "55px", gap: "3px", cursor: "pointer " }}
        >
          <div className="">
            <CustomText p text={"Profession"} />
            <div className=" text-12 m-t-2">
              <CustomText p text={getProfessionName()} className="green" />
            </div>
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={Svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }
  function renderPortFolio() {
    return (
      <>
        <div
          onClick={() => navigate("/portfolio")}
          className="white-card-bg flex  relative"
          style={{ minHeight: "55px", cursor: "pointer " }}
        >
          <div className=" ">
            <div className="flex flex-column m-t-8">
              <CustomText p text={"Portfolio"} />
              <div className=" text-12 m-t-2" style={{ color: "black" }}>
                <CustomText p text={"Share your best work with brands"} />
              </div>
              <div className="arrow-icon-right">
                <Icon
                  icon={Svgs.icon_dropdownRight}
                  width={15}
                  height={15}
                  className={"bold"}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderBankDetails() {
    const bankDetailStatus = influencer_data?.is_bank_verified
      ? "Verified"
      : "Not verified";
    const bankDetailClass = influencer_data?.is_bank_verified
      ? "success"
      : "error";

    return (
      <>
        <div
          onClick={() => navigate("/bank-detail")}
          className="white-card-bg flex relative"
          style={{ minHeight: "55px", cursor: "pointer " }}
        >
          <div className=" ">
            <div className="flex flex-column m-t-8">
              <CustomText p text={"Bank Details"} />
              <div className="">
                <CustomText
                  p
                  text={bankDetailStatus}
                  className={bankDetailClass}
                />
              </div>
            </div>
            <div className="arrow-icon-right">
              <Icon
                icon={Svgs.icon_dropdownRight}
                width={15}
                height={15}
                className={"bold"}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  function renderAddress() {
    const addressStatus = influencer_data?.is_address_verified
      ? "Completed"
      : "No Address Available";
    const addressClass = influencer_data?.is_address_verified
      ? "success"
      : "error";

    return (
      <>
        <div
          onClick={() => navigate("/address-page")}
          className="white-card-bg flex relative"
          style={{ minHeight: "55px", cursor: "pointer " }}
        >
          <div className=" ">
            <div className="flex flex-column m-t-8">
              <CustomText p text={"Address"} />

              <CustomText
                p
                text={addressStatus}
                className={`${addressClass}`}
              />
            </div>
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={Svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }
  function renderKycDetails() {
    const kycStatus = influencer_data?.is_kyc_done
      ? "Completed"
      : "Not verified";
    const kycClass = influencer_data?.is_kyc_done ? "text-12 black" : "error";

    return (
      <>
        <div
          onClick={() => navigate("/kyc-detail")}
          className="white-card-bg flex relative "
          style={{ minHeight: "55px", cursor: "pointer " }}
        >
          <div className=" ">
            <div className="flex flex-column m-t-8">
              <CustomText p text={"KYC Documents"} />
              <CustomText p text={kycStatus} className={`${kycClass} m-t-2`} />
            </div>
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={Svgs.icon_dropdownRight}
              width={15}
              height={15}
              className={"bold"}
            />
          </div>
        </div>
      </>
    );
  }
  function renderSetting() {
    return (
      <>
        <div
          onClick={() => navigate("/settings")}
          className="white-card-bg flex flex-column  relative   justify-center "
          style={{ minHeight: "40px", gap: "3px", cursor: "pointer " }}
        >
          <div className=" ">
            <CustomText p text={"Settings"} />
          </div>
          <div className="arrow-icon-right">
            <Icon
              icon={Svgs.icon_dropdownRight}
              width={15}
              height={15}
            />
          </div>
        </div>
      </>
    );
  }

  if (!Utils.influencerLogined_Or_Not(influencer_data.influencer_id)) {
    return (
      <>
       <div className="header-fixed-list">
     <CustomText p text={"My Profile"} className="p-l-15 p-b-15"/>
     </div>
        <div className="bg-app-gray justify-center flex-column"  > 
        <div className="fallback">
            <FallBack
              icon={svgs.reelaxLogo}
              heading="Please Login"
              title="For access Your Profile login to Reelax"
            />
          <div className="container page-center m-b-25 ">
          <PrimaryCta
              className={"custom-button"}
              onClick={() => 
                openLoginOtp_Sheet()
              } 
              text={"Login"}
            />
          </div>
          </div>
          <Footer />
        </div>
        <LoginOtpScreen isModalOpen={isLoginOtpSheet} setModalOpen={setisLoginOtpSheet} />
      </>
    );
  }

  return (
  
      <div className="bg-app-gray">
        {" "}
        <div className="header-fixed">
          <div style={{paddingLeft : "10px"}}>
          <CustomText p text="My Profile " className = "p-l-4" />
          </div>
          {" "}
        </div>{" "}
        {loading ? (
          <div className="loader-mid">
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <>
            {" "}
            <div className="" style={{ paddingBottom: "25px" }}>
              {" "}
              <div
                className="container p-t-60"
                style={{ paddingTop: "58px", paddingBottom: "16px" }}
              >
                {" "}
                {renderProfileInfo()} {renderSocialChannels()}{" "}
                {renderProfession()} {renderPortFolio()} {renderBankDetails()}{" "}
                {renderAddress()} {renderKycDetails()} {renderSetting()}{" "}
              </div>{" "}
            </div>{" "}
            <Footer />{" "}
          </>
        )}{" "}
        
      </div>
  );
}

export default ProfilePage;
